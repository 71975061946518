import { ImportSource } from '@core/domain/models/import-source.model'
import { CitationFileFormat } from '@core/domain/types/import-file-format.type'
import { ImportSourceId } from '@core/domain/types/import-source-id.type'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { Api } from './review-manager-api'

export class ImportSourcesRepository {
  constructor(private _reviewManagerService: Api<any>) {}

  async create(createImportSourceData: {
    type: ImportSourceType
    shortName: string
    fullName: string
    description: string
    url: string
    format: CitationFileFormat
  }): Promise<ImportSource> {
    const { data } =
      await this._reviewManagerService.api.importSourcesControllerCreate(
        createImportSourceData
      )
    return new ImportSource(data as ImportSource)
  }

  async find(): Promise<ImportSource[]> {
    const { data } =
      await this._reviewManagerService.api.importSourcesControllerFind()
    return data.map((p: any) => new ImportSource(p))
  }

  async update(
    importSourceId: ImportSourceId,
    data: {
      shortName?: string
      fullName?: string
      description?: string
      url?: string
    }
  ): Promise<void> {
    await this._reviewManagerService.api.importSourcesControllerUpdate(
      importSourceId,
      data
    )
  }
}
