<template>
  <div class="container flex justify-center mx-auto h-screen items-center">
    <div
      class="bg-white dark:bg-[#1B3F58] shadow-md rounded px-20 pt-10 pb-16 mb-4 lg:w-[600px]"
    >
      <div class="flex flex-col items-center gap-4 mb-14">
        <LogoIcon class="w-14 h-14" />
        <div class="flex flex-col items-center gap-2">
          <p class="text-2xl font-bold">Hello again!</p>
          <p class="text-base /70">
            Please login using your account credentials.
          </p>
        </div>
      </div>
      <div class="mb-8 space-y-3">
        <label for="email"> Email </label>
        <TextInput
          type="email"
          placeholder="Email"
          v-model="email"
          id="email"
          @keyup.enter="login"
          class="rounded-md"
        />
      </div>
      <div class="mb-6 space-y-3">
        <label for="password"> Password </label>
        <TextInput
          type="password"
          placeholder="******************"
          v-model="password"
          id="password"
          @keyup.enter="login"
          class="rounded-md"
        />
      </div>
      <div class="mb-8 flex gap-1">
        <input type="checkbox" id="rememberMe" />
        <label for="rememberMe">Remember Me</label>
      </div>
      <div class="flex items-center">
        <button
          class="bg-blue-cyan hover:bg-blue-dark text-white font-bold py-2 px-4 rounded flex items-center gap-1 w-full justify-center"
          type="button"
          @click="login"
        >
          Sign in
          <ArrowSmallRightIcon class="w-3.5 h-3" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { useRouter } from 'vue-router'
import useAuth from '@app/composables/use-auth'
import { UsersService } from '@core/application/users.service'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import ArrowSmallRightIcon from '@app/components/Icons/ArrowSmallRightIcon.vue'
import LogoIcon from '@app/components/Icons/logoIcon.vue'

const usersService: UsersService = inject('usersService')!
const auth = useAuth()
const email = ref()
const password = ref()
const router = useRouter()
const snackbar = useSnackbar()

async function login() {
  try {
    const token = await usersService.login(email.value, password.value)
    if (!token) return
    usersService.setAuthHeader(token)
    auth.setToken(token)
    const user = await usersService.findCurrentUser()
    auth.setUser(user)
    snackbar.show(SnackbarState.SUCCESS, 'login successful')
    router.options.history.state.back ? router.back() : router.push('/')
  } catch (e) {
    snackbar.show(SnackbarState.ERROR, 'login failed')
    throw e
  }
}
</script>
