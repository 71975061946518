import { HttpException } from '@core/exceptions/http.exception'
import axios, { AxiosInstance } from 'axios'
export class HttpClient {
  client: AxiosInstance
  constructor() {
    this.client = axios.create({
      baseURL: (import.meta.env.VITE_API_URL as string) ?? '',
    })
  }
  async post(url: string, data: unknown) {
    return this.client.post(url, data).catch((e) => {
      throw new HttpException(
        e.response.data.statusCode,
        e.response.data.message,
        e.response.data
      )
    })
  }

  async get(url: string, config?: any) {
    return this.client.get(url, config)
  }

  async patch(url: string, data?: unknown) {
    return this.client.patch(url, data)
  }

  async put(url: string, data?: unknown) {
    return this.client.put(url, data)
  }

  async delete(url: string) {
    return this.client.delete(url)
  }

  setToken(token: string) {
    this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}
