<template>
  <router-link
    v-if="
      userCanAccess(route.name) &&
      (!route.isCuttingEdge || devToolbox.isCuttingEdge)
    "
    :to="route.to"
  >
    <slot :route="route" :isActiveRoute="isActiveRoute">
      <component :is="route.icon" class="w-[24px] h-[24px]" />
    </slot>
  </router-link>
</template>
<script lang="ts" setup>
import useDevToolbox from '@app/composables/use-dev-toolbox'
import {
  userCanAccess,
  navigation,
  RouteName,
  NagivationEntry,
} from '@app/router'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const props = defineProps<{ routeName: RouteName }>()
const devToolbox = useDevToolbox()
const route = computed<NagivationEntry>(
  () => navigation.find((r) => r.name === props.routeName)!
)

const vueRoute = useRoute()

const isActiveRoute = computed(() => vueRoute.name === route.value.name)
</script>
