import { Study } from '@core/domain/models/study.model'
import { DataExtractionFieldType } from '@core/domain/models/data-extraction-field'
import { Search } from '@core/domain/models/search.model'
import { ImportSource } from '@core/domain/models/import-source.model'
import { Project } from '@core/domain/models/project.model'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { MetaData } from '@core/domain/types/meta-data.type'

export class ReviewsService {
  constructor(private _reviewsRepository: ReviewsRepository) {}

  async findById(reviewId: Id) {
    return this._reviewsRepository.findById(reviewId)
  }

  async importCitationFile(
    reviewId: Id,
    personUndertakingSearch: string,
    importSource: ImportSource,
    citationFiles?: File[],
    searchDate?: string,
    searchQuery?: string,
    queryFile?: File,
    url?: string,
    parentStudyId?: Id,
    filter?: string
  ): Promise<void> {
    const search = new Search({
      personUndertakingSearch,
      source: importSource,
      searchDate,
      searchQuery,
      url,
      parentStudyId: parentStudyId,
      filter,
    })

    return this._reviewsRepository.importCitationFile(
      reviewId,
      search,
      citationFiles,
      queryFile
    )
  }

  async findStudies(reviewId: Id) {
    return this._reviewsRepository.findStudies(reviewId)
  }

  async markStudyAsDuplicate(
    reviewId: Id,
    studyId: Id,
    parentStudyId: Id
  ): Promise<Study> {
    return this._reviewsRepository.markStudyAsDuplicate(
      reviewId,
      studyId,
      parentStudyId
    )
  }

  async markStudyAsNotDuplicate(reviewId: Id, studyId: Id): Promise<Study> {
    return this._reviewsRepository.markStudyAsNotDuplicate(reviewId, studyId)
  }

  async setStudyTitleAndAbstractScreening(
    reviewId: Id,
    studyId: Id,
    titleAndAbstractScreening: string
  ) {
    return this._reviewsRepository.setStudyTitleAndAbstractScreening(
      reviewId,
      studyId,
      titleAndAbstractScreening
    )
  }

  async clearStudyTitleAndAbstractScreening(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyTitleAndAbstractScreening(
      reviewId,
      studyId
    )
  }

  async setStudyFullTextScreening(
    reviewId: Id,
    studyId: Id,
    fullTextScreening: string
  ) {
    return this._reviewsRepository.setStudyFullTextScreening(
      reviewId,
      studyId,
      fullTextScreening
    )
  }

  async clearStudyFullTextScreening(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyFullTextScreening(
      reviewId,
      studyId
    )
  }

  async favoriteStudy(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.favoriteStudy(reviewId, studyId)
  }
  async unfavoriteStudy(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.unfavoriteStudy(reviewId, studyId)
  }

  async deleteSearchStudies(reviewId: Id, search: Search) {
    return this._reviewsRepository.deleteSearchStudies(reviewId, search)
  }

  async planScreening(
    reviewId: Id,
    data: {
      stepsCount: 1 | 2
      titleAndAbstractCriteria?: string[]
      fullTextCriteria: string[]
    }
  ) {
    return this._reviewsRepository.planScreening(reviewId, data)
  }

  async addImportSourceToPlan(reviewId: Id, importSourceId: string) {
    return this._reviewsRepository.addImportSourceToPlan(
      reviewId,
      importSourceId
    )
  }

  async removeImportSourceFromPlan(reviewId: Id, importSourceId: string) {
    return this._reviewsRepository.removeImportSourceFromPlan(
      reviewId,
      importSourceId
    )
  }

  lockScreeningPlan(reviewId: Id) {
    return this._reviewsRepository.lockScreeningPlan(reviewId)
  }

  async findSearches(reviewId: Id): Promise<Search[]> {
    return this._reviewsRepository.findSearches(reviewId)
  }

  async delete(selectedReviewsIds: Id[]): Promise<void> {
    return this._reviewsRepository.delete(selectedReviewsIds)
  }

  async findProject(reviewId: Id): Promise<Project> {
    return this._reviewsRepository.findProject(reviewId)
  }

  async updateName(reviewId: Id, name: string): Promise<Review> {
    return this._reviewsRepository.updateName(reviewId, name)
  }

  async uploadStudyPdfFile(
    reviewId: Id,
    studyId: Id,
    file: File
  ): Promise<Study> {
    return this._reviewsRepository.uploadStudyPdfFile(reviewId, studyId, file)
  }

  async getStudyPdfFile(reviewId: Id, studyId: Id): Promise<Blob> {
    return this._reviewsRepository.getStudyPdfFile(reviewId, studyId)
  }

  async downloadSearchCitationFile(reviewId: Id, searchId: Id): Promise<Blob> {
    return this._reviewsRepository.downloadSearchCitationFile(
      reviewId,
      searchId
    )
  }

  async deleteStudyPdfFile(reviewId: Id, studyId: Id): Promise<void> {
    return this._reviewsRepository.deleteStudyPdfFile(reviewId, studyId)
  }

  async downloadPdfZip(reviewId: Id): Promise<Blob> {
    return this._reviewsRepository.downloadPdfZip(reviewId)
  }

  async editStudyAbstract(reviewId: Id, studyId: Id, abstract: string) {
    return this._reviewsRepository.editStudyAbstract(
      reviewId,
      studyId,
      abstract
    )
  }

  async enableStudiesAppraisalImdrfMdce2019(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalImdrfMdce2019(reviewId)
  }

  async enableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId
    )
  }

  async disableStudiesAppraisal(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisal(reviewId)
  }

  async disableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId
    )
  }

  async submitComplaint(
    reviewId: Id,
    studyId: Id,
    data: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    }
  ) {
    return this._reviewsRepository.submitComplaint(reviewId, studyId, data)
  }

  async appraiseStudy(
    reviewId: Id,
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string }
  ) {
    return this._reviewsRepository.appraiseStudy(reviewId, studyId, appraisal)
  }
  async setOxfordLevelOfEvidenceStudy(
    reviewId: Id,
    studyId: Id,
    evidence: OxfordLevelOfEvidenceType
  ) {
    return this._reviewsRepository.setOxfordLevelOfEvidenceStudy(
      reviewId,
      studyId,
      evidence
    )
  }

  async clearStudiesAppraisal(reviewId: Id, studyId: Id, criterionId: string) {
    return this._reviewsRepository.clearStudiesAppraisal(
      reviewId,
      studyId,
      criterionId
    )
  }

  async cleaStudyOxfordLevelOfEvidence(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.cleaStudyOxfordLevelOfEvidence(
      reviewId,
      studyId
    )
  }

  async duplicateReview(reviewId: Id, projectId: Id) {
    return this._reviewsRepository.duplicateReview(reviewId, projectId)
  }

  async updateStudyDataExtraction(
    reviewId: Id,
    studyId: Id,
    fieldId: string,
    value: string
  ) {
    return this._reviewsRepository.updateStudyDataExtraction(
      reviewId,
      studyId,
      fieldId,
      value
    )
  }

  async addStudyDataExtractionField(
    reviewId: Id,
    data: { name: string; type: DataExtractionFieldType }
  ) {
    return this._reviewsRepository.addStudyDataExtractionField(reviewId, data)
  }

  async updateStudy(reviewId: Id, studyId: Id, metaData: MetaData) {
    return this._reviewsRepository.updateStudy(reviewId, studyId, metaData)
  }

  async isCitationFileAlreadyImported(
    reviewId: Id,
    citationFileChecksum: string
  ) {
    return this._reviewsRepository.isCitationFileAlreadyImported(
      reviewId,
      citationFileChecksum
    )
  }
}
