<template>
  <FloatingMenu placement="bottom-start" :arrow="false" ref="floatingMenu">
    <template #activator>
      <button
        @click="openMenu"
        class="bg-blue-cyan text-white font-bold py-4 px-6 rounded-md inline-flex items-center h-11"
      >
        <UserPlusIcon class="w-6 h-6" />
        <span class="pl-3">Create user</span>
      </button>
    </template>
    <template #content>
      <div
        class="px-2 py-2 dark:bg-[#103047] dark:text-white/70 dark:border-white/10 border"
      >
        <div class="mb-1">
          <TextInput
            type="text"
            v-model="createUserModel.email"
            ref="userEmailInput"
            placeholder="email"
            :rules="[(v) => !!v || 'please enter an email']"
          />
        </div>
        <div class="mb-1">
          <TextInput
            type="password"
            v-model="createUserModel.password"
            ref="userPasswordInput"
            placeholder="password"
            :rules="[(v) => !!v || 'please enter a password']"
          />
        </div>
        <div class="mb-1">
          <SelectList
            v-model="createUserModel.role"
            ref="userRoleSelect"
            placeholder="Role"
            :items="[
              { text: 'User', value: Role.USER },
              { text: 'Admin', value: Role.ADMIN },
              { text: 'Read Only', value: Role.READ_ONLY },
            ]"
          />
        </div>
        <div class="flex justify-end">
          <Button class="bg-primary text-white" @click="create">Save</Button>
        </div>
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import Button from '@app/components/Global/Button.vue'
import { Role } from '@core/domain/types/role.type'
import useUsers from '@app/composables/use-users'
import SelectList from '@app/components/Global/Inputs/SelectList.vue'
import { hideAll } from 'tippy.js'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'

interface CreateUserModel {
  email: string
  password: string
  role: Role
}

const userEmailInput = ref<InstanceType<typeof TextInput>>()
const userPasswordInput = ref<InstanceType<typeof TextInput>>()
const userRoleSelect = ref<InstanceType<typeof SelectList>>()
const floatingMenu = ref()
const snackbar = useSnackbar()
const loading = useLoading()
const createUserModel = ref<CreateUserModel>({
  email: '',
  role: Role.USER,
  password: '',
})
const users = useUsers()

async function create() {
  if (!validate()) return
  try {
    loading.start()
    await users.create(createUserModel.value)
    snackbar.show(SnackbarState.SUCCESS, 'user created successfully')
    reset()
    hideAll()
  } catch (e: any) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}
function IsValidEmail(email: string) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  return emailPattern.test(email)
}

function validate() {
  const isEmailValid = IsValidEmail(createUserModel.value.email)
  const isPasswordValid = userPasswordInput.value?.validate()
  const isRoleValid = userRoleSelect.value?.validate()
  return isEmailValid && isPasswordValid && isRoleValid
}

function reset() {
  createUserModel.value = {
    email: '',
    role: Role.USER,
    password: '',
  }
}

async function openMenu() {
  floatingMenu?.value?.show()
  userEmailInput.value?.focus()
}
</script>
