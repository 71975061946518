import { ImportSourcesService } from '@core/application/import-sources.service'
import { ImportSource } from '@core/domain/models/import-source.model'
import { CitationFileFormat } from '@core/domain/types/import-file-format.type'
import { ImportSourceId } from '@core/domain/types/import-source-id.type'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { inject, ref } from 'vue'

const importSources = ref<ImportSource[]>([])
export default () => {
  const importSourcesService: ImportSourcesService = inject(
    'importSourcesService'
  )!

  const create = async (data: {
    type: ImportSourceType
    shortName: string
    fullName: string
    description: string
    url: string
    format: CitationFileFormat
  }) => {
    const importSource = await importSourcesService.create(data)
    importSources.value.push(importSource)
    return importSource
  }

  async function update(
    importSourceId: ImportSourceId,
    data: {
      shortName?: string
      fullName?: string
      description?: string
      url?: string
    }
  ) {
    await importSourcesService.update(importSourceId, data)
    await refresh()
  }

  const refresh = async () => {
    const fetchedImportSources = await importSourcesService.find()
    importSources.value = fetchedImportSources
  }
  return {
    list: importSources,
    create,
    update,
    refresh,
  }
}
