export enum ButtonState {
  disabled,
  inactive,
  red,
  green,
  blue,
}

export enum SnackbarState {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type SelectListItem = {
  value: string
  text: string
  title?: string
}

export enum StudyState {
  DUPLICATE = 'Duplicate',
  UNSCREENED = 'Unscreened',
  INCLUDED = 'Included',
  EXCLUDED = 'Excluded',
  FAVOURITE = 'Favorite',
  QUARANTINED = 'QUARANTINED',
}

export enum ReviewStage {
  SCREENING = 1,
  APPRAISAL = 2,
  DATA_EXTRACTION = 3,
}

export enum SortBy {
  None,
  Title,
  Author,
  Year,
}

export enum SortOrder {
  Ascending,
  Descending,
}
