<template>
  <div class="p-10 space-y-4 flex flex-col h-full justify-between">
    <div class="flex gap-4">
      <input
        v-model="query"
        placeholder="Search PubMed"
        class="evidence-input"
        @keydown.enter="searchArticles"
      />
      <button @click="searchArticles" class="px-4 py-1 bg-primary">
        Search
      </button>
      <div class="self-center">
        <FloatingMenu>
          <template #activator="{ show }">
            <button @click="show">
              <InformationCircle class="w-6 h-6" />
            </button>
          </template>
          <template #content>
            <div class="max-w-4xl p-2 overflow-auto">
              Translation
              <pre>{{ queryTranslation }}</pre>
              Translation sets
              <pre>{{ translationSet }}</pre>
            </div>
          </template>
        </FloatingMenu>
      </div>
    </div>
    <div class="flex justify-center gap-10">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1">
        Previous
      </button>
      {{ currentPage }} / {{ totalPages }}
      <button
        @click="changePage(currentPage + 1)"
        :disabled="currentPage >= totalPages"
      >
        Next
      </button>
    </div>
    <div class="flex-1">
      <div v-if="loading.isLoading.value">Loading</div>
      <div v-else class="space-y-2">
        <div v-for="article in articles" :key="article.pmid" class="p-2 border">
          <a
            :href="`https://pubmed.ncbi.nlm.nih.gov/${article.pmid}`"
            target="_blank"
            rel="noopener"
          >
            <h3>{{ article.title }}</h3>
            <p>{{ article.abstract }}</p>
            <p>Authors: {{ article.authors.join(', ') }}</p>
            <p>PMID: {{ article.pmid }}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-center gap-10">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1">
        Previous
      </button>
      {{ currentPage }} / {{ totalPages }}
      <button
        @click="changePage(currentPage + 1)"
        :disabled="currentPage >= totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import InformationCircle from '@app/components/Icons/InformationCircle.vue'
import useLoading from '@app/composables/use-loading'
import { useStorage } from '@vueuse/core'
import { ref } from 'vue'

interface Article {
  title: string
  abstract: string
  authors: string[]
  pmid: string
}

interface ESearchResult {
  esearchresult: {
    idlist: string[]
    count: number
    querytranslation: string
    translationset: { from: string; to: string }[]
  }
}
const loading = useLoading()
const query = ref('')
const queryTranslation = ref('')
const translationSet = ref<{ from: string; to: string }[]>([])
const articles = useStorage<Article[]>('articles', [])

let lastRequestTime = 0
const rateLimitDelayInMs = 400

const enforceRateLimit = async () => {
  const currentTime = Date.now()
  const timeSinceLastRequest = currentTime - lastRequestTime

  if (timeSinceLastRequest < rateLimitDelayInMs) {
    await new Promise((resolve) =>
      setTimeout(resolve, rateLimitDelayInMs - timeSinceLastRequest)
    )
  }

  lastRequestTime = Date.now()
}

const currentPage = ref(1)
const pageSize = ref(10)
const totalPages = ref(0)

const changePage = (newPage: number) => {
  currentPage.value = newPage
  searchArticles()
}

const searchArticles = async () => {
  loading.start()
  try {
    await enforceRateLimit()
    const start = (currentPage.value - 1) * pageSize.value
    const res = await fetch(
      `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&term=${query.value}&retmode=json&retstart=${start}&retmax=${pageSize.value}`
    )
    const data: ESearchResult = await res.json()
    queryTranslation.value = data.esearchresult.querytranslation
    translationSet.value = data.esearchresult.translationset
    const idList = data.esearchresult.idlist.join(',')
    totalPages.value = Math.ceil(data.esearchresult.count / pageSize.value)

    if (!idList) return

    await enforceRateLimit()
    const fetchDetails = await fetch(
      `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&id=${idList}&retmode=xml`
    )
    const details = await fetchDetails.text()
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(details, 'text/xml')

    const articleNodes = xmlDoc.querySelectorAll('PubmedArticle')
    articles.value = Array.from(articleNodes).map((node) => {
      const title = node.querySelector('ArticleTitle')?.textContent || 'N/A'
      const abstract = node.querySelector('AbstractText')?.textContent || 'N/A'
      const authors =
        Array.from(node.querySelectorAll('Author')).map(
          (author) =>
            `${author.querySelector('LastName')?.textContent || ''}, ${
              author.querySelector('ForeName')?.textContent || ''
            }`
        ) || []
      const pmid = node.querySelector('PMID')?.textContent || 'N/A'
      return { title, abstract, authors, pmid }
    })
  } catch (error) {
    console.error('An error occurred:', error)
  } finally {
    loading.stop()
  }
}
</script>
