import { Api } from './datasource/review-manager-api'

export class CslStyleApi {
  constructor(private _reviewManagerService: Api<any>) {}
  async find(): Promise<string[]> {
    const { data } =
      await this._reviewManagerService.api.cslStylesControllerFindCslStyles()
    return data
  }
}
