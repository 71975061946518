<template>
  <span>
    <span ref="activator">
      <slot name="activator" :show="show" />
    </span>
    <span ref="content">
      <slot name="content" :class="class" :hide="hide" />
    </span>
  </span>
</template>

<script lang="ts" setup>
import tippy, { Instance, Props, Placement } from 'tippy.js'
import { ref, onMounted } from 'vue'

const activator = ref<Element>()
const content = ref<Element>()

const props = withDefaults(
  defineProps<{
    persistent?: boolean
    placement?: Placement
    theme?: string
    class?: string
    arrow?: boolean
    offset?: [number, number]
    trigger?: 'click' | 'hover'
  }>(),
  {
    persistent: false,
    placement: 'right-start',
    theme: 'light-border',
    arrow: true,
    offset: () => [0, 0],
    trigger: 'click',
  }
)

const emit = defineEmits<{
  (e: 'hide'): void
  (e: 'show'): void
}>()

let tippyInstance: Instance<Props> | null = null

onMounted(() => {
  tippyInstance = tippy(
    (activator.value?.firstElementChild as HTMLElement) ??
      (activator.value as HTMLElement),
    {
      content: content.value,
      allowHTML: true,
      trigger: props.trigger === 'click' ? 'manual' : 'mouseenter focus',
      hideOnClick: !props.persistent,
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
          },
        ],
      },
      placement: props.placement,
      theme: props.theme,
      interactive: true,
      animation: 'shift-away',
      arrow: props.arrow,
      maxWidth: 'none',
      offset: props.offset,
      onHide: () => {
        emit('hide')
      },
      onShow: () => {
        emit('show')
      },
      plugins: [
        {
          name: 'hideOnEsc',
          defaultValue: true,
          fn({ hide }) {
            function onKeyDown(event: any) {
              if (event.keyCode === 27) {
                hide()
              }
            }
            return {
              onShow() {
                document.addEventListener('keydown', onKeyDown)
              },
              onHide() {
                document.removeEventListener('keydown', onKeyDown)
              },
            }
          },
        },
      ],
    }
  )
})

function show() {
  tippyInstance?.show()
}

function hide() {
  tippyInstance?.hide()
}

defineExpose({
  hide,
  show,
})
</script>

<style>
.tippy-box[data-theme~='light-no-padding'] .tippy-content {
  padding: 0;
}
.tippy-box[data-theme~='light-no-padding'] {
  color: #26323d;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.tippy-box[data-theme~='light-no-padding'][data-placement^='top']
  > .tippy-arrow:before {
  border-top-color: #fff;
}
.tippy-box[data-theme~='light-no-padding'][data-placement^='bottom']
  > .tippy-arrow:before {
  border-bottom-color: #fff;
}
.tippy-box[data-theme~='light-no-padding'][data-placement^='left']
  > .tippy-arrow:before {
  border-left-color: #fff;
}
.tippy-box[data-theme~='light-no-padding'][data-placement^='right']
  > .tippy-arrow:before {
  border-right-color: #fff;
}
.tippy-box[data-theme~='light-no-padding'] > .tippy-backdrop {
  background-color: #fff;
}
.tippy-box[data-theme~='light-no-padding'] > .tippy-svg-arrow {
  fill: #fff;
}

.tippy-box[data-theme~='light-border'] .tippy-content {
  padding: 0;
}
.tippy-box[data-theme~='light-border'] {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, 0.15);
  color: #333;
  box-shadow: 0 4px 14px -2px rgba(0, 8, 16, 0.08);
}
.tippy-box[data-theme~='light-border'] > .tippy-backdrop {
  background-color: #fff;
}
.tippy-box[data-theme~='light-border'] > .tippy-arrow:after,
.tippy-box[data-theme~='light-border'] > .tippy-svg-arrow:after {
  content: '';
  position: absolute;
  z-index: -1;
}
.tippy-box[data-theme~='light-border'] > .tippy-arrow:after {
  border-color: transparent;
  border-style: solid;
}
.tippy-box[data-theme~='light-border'][data-placement^='top']
  > .tippy-arrow:before {
  border-top-color: #fff;
}
.tippy-box[data-theme~='light-border'][data-placement^='top']
  > .tippy-arrow:after {
  border-top-color: rgba(0, 8, 16, 0.2);
  border-width: 7px 7px 0;
  top: 17px;
  left: 1px;
}
.tippy-box[data-theme~='light-border'][data-placement^='top']
  > .tippy-svg-arrow
  > svg {
  top: 16px;
}
.tippy-box[data-theme~='light-border'][data-placement^='top']
  > .tippy-svg-arrow:after {
  top: 17px;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom']
  > .tippy-arrow:before {
  border-bottom-color: #fff;
  bottom: 16px;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom']
  > .tippy-arrow:after {
  border-bottom-color: rgba(0, 8, 16, 0.2);
  border-width: 0 7px 7px;
  bottom: 17px;
  left: 1px;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom']
  > .tippy-svg-arrow
  > svg {
  bottom: 16px;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom']
  > .tippy-svg-arrow:after {
  bottom: 17px;
}
.tippy-box[data-theme~='light-border'][data-placement^='left']
  > .tippy-arrow:before {
  border-left-color: #fff;
}
.tippy-box[data-theme~='light-border'][data-placement^='left']
  > .tippy-arrow:after {
  border-left-color: rgba(0, 8, 16, 0.2);
  border-width: 7px 0 7px 7px;
  left: 17px;
  top: 1px;
}
.tippy-box[data-theme~='light-border'][data-placement^='left']
  > .tippy-svg-arrow
  > svg {
  left: 11px;
}
.tippy-box[data-theme~='light-border'][data-placement^='left']
  > .tippy-svg-arrow:after {
  left: 12px;
}
.tippy-box[data-theme~='light-border'][data-placement^='right']
  > .tippy-arrow:before {
  border-right-color: #fff;
  right: 16px;
}
.tippy-box[data-theme~='light-border'][data-placement^='right']
  > .tippy-arrow:after {
  border-width: 7px 7px 7px 0;
  right: 17px;
  top: 1px;
  border-right-color: rgba(0, 8, 16, 0.2);
}
.tippy-box[data-theme~='light-border'][data-placement^='right']
  > .tippy-svg-arrow
  > svg {
  right: 11px;
}
.tippy-box[data-theme~='light-border'][data-placement^='right']
  > .tippy-svg-arrow:after {
  right: 12px;
}
.tippy-box[data-theme~='light-border'] > .tippy-svg-arrow {
  fill: #fff;
}
.tippy-box[data-theme~='light-border'] > .tippy-svg-arrow:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);
  background-size: 16px 6px;
  width: 16px;
  height: 6px;
}
</style>
