import { ref } from 'vue'
export type Modal = {
  id: string
  isVisible: boolean
}

const modals = ref<Modal[]>([])

export default () => {
  function addModal(modalId: string) {
    modals.value.push({
      id: modalId,
      isVisible: false,
    })
  }

  function showModal(modalId: string) {
    updateModalVisibility(modalId, true)
  }
  function hideModal(modalId: string) {
    updateModalVisibility(modalId, false)
  }

  function updateModalVisibility(modalId: string, isVisible: boolean) {
    const modalIndex = modals.value.findIndex((m) => m.id === modalId)
    modals.value[modalIndex].isVisible = isVisible
  }

  function isModalVisible(modalId: string) {
    return modals.value.find((m) => m.id === modalId)?.isVisible
  }

  return {
    addModal,
    showModal,
    hideModal,
    isModalVisible,
    modals,
  }
}
