<template>
  <div class="h-full w-full">
    <VueFlow fit-view-on-init @pane-click="log">
      <Background />
      <Controls />
      <MiniMap pannable zoomable />
      <template #node-conditions="{ label }"> <ConditionsNode /> </template>
      <template #node-branch=""
        ><BranchNode @add-branch="addBranch"
      /></template>
    </VueFlow>
  </div>
</template>

<script setup lang="ts">
import { useVueFlow, VueFlow, MarkerType } from '@vue-flow/core'
import { MiniMap } from '@vue-flow/minimap'
import { Controls } from '@vue-flow/controls'
import { Background } from '@vue-flow/background'
import '@vue-flow/controls/dist/style.css'
import ConditionsNode from './ConditionsNode/ConditionsNode.vue'
import BranchNode from './BranchNode/BranchNode.vue'
import { v4 as uuid } from 'uuid'
const { addNodes, addEdges } = useVueFlow({
  snapToGrid: true,
  snapGrid: [5, 5],
  nodes: [
    {
      id: '1',
      label: 'branch',
      position: { x: 205, y: -100 },
      type: 'branch',
    },
    {
      id: '2',
      label: 'something',
      position: { x: 0, y: 0 },
      type: 'conditions',
    },
  ],
  edges: [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      type: 'step',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#FFF',
        height: 20,
        width: 20,
      },
    },
  ],
})

function addBranch() {
  addNodes([
    {
      id: uuid(),
      position: { x: 0, y: 0 },
      label: 'branch',
      type: 'branch',
    },
  ])
}

function log(e: Event) {
  console.log(e)
}
</script>

<style>
/* these are necessary styles for vue flow */
@import '@vue-flow/core/dist/style.css';

/* this contains the default theme, these are optional styles */
@import '@vue-flow/core/dist/theme-default.css';
</style>
