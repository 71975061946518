<template>
  <div class="p-10">
    <div class="container mx-auto gap-6 flex flex-col">
      <div class="text-2xl py-2">changelog</div>

      <div v-for="logEntry in changelog.log">
        <p class="text-lg">{{ format(logEntry.date, 'dd/MM/yyyy') }}</p>
        <p v-for="entry in logEntry.entries">{{ entry }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useChangelog from '@app/composables/use-changelog'
import { format } from 'date-fns'

const changelog = useChangelog()
changelog.acknowledgeUpdate()
</script>
