<template>
  <FloatingMenu placement="bottom-start" :arrow="false" ref="floatingMenu">
    <template #activator>
      <ActionButton
        @click="openMenu"
        class="bg-white border rounded dark:bg-[#275374] dark:text-white dark:border-white"
      >
        Add {{ props.type.toLocaleLowerCase() }}
        <ArrowDownIcon class="w-3 h-3 fill-current" />
      </ActionButton>
    </template>
    <template #content>
      <div
        class="px-2 py-2 dark:bg-[#103047] dark:text-white/70 dark:border-white/10 border"
      >
        <div class="mb-1">
          <p class="py-0.5 px-3 text-black font-bold dark:text-white">
            New {{ props.type.toLocaleLowerCase() }}
          </p>
        </div>
        <div class="mb-1">
          <TextInput
            type="text"
            v-model="createImportSourceModel.shortName"
            ref="importSourceShortNameInput"
            placeholder="short name"
            :rules="[(v) => !!v || 'please provide a short name']"
          />
        </div>
        <div class="mb-1">
          <TextInput
            type="text"
            v-model="createImportSourceModel.fullName"
            ref="importSourceFullNameInput"
            placeholder="full name"
            :rules="[(v) => !!v || 'please provide a full name']"
          />
        </div>

        <div class="mb-1">
          <SelectList
            v-model="createImportSourceModel.format"
            ref="importSourceFormatSelect"
            placeholder="Import file format"
            :items="[
              { text: 'Ris', value: CitationFileFormat.RIS },
              { text: 'Pubmed', value: CitationFileFormat.PUBMED },
              {
                text: 'Harvard citation',
                value: CitationFileFormat.HARVARD_CITATION,
              },
            ]"
            :rules="[(v) => !!v || 'please select a format']"
          />
        </div>
        <div class="mb-1">
          <TextInput
            type="text"
            v-model="createImportSourceModel.description"
            ref="importSourceDescriptionInput"
            placeholder="description"
            multiline
          />
        </div>
        <div class="mb-1">
          <TextInput
            type="text"
            v-model="createImportSourceModel.url"
            ref="importSourceUrlInput"
            placeholder="url"
          />
        </div>
        <div class="flex justify-end">
          <Button class="bg-primary text-white" @click="create">Save</Button>
        </div>
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import Button from '@app/components/Global/Button.vue'
import useImportSources from '@app/composables/use-import-sources'
import SelectList from '@app/components/Global/Inputs/SelectList.vue'
import { hideAll } from 'tippy.js'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { CitationFileFormat } from '@core/domain/types/import-file-format.type'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'
import ActionButton from '@app/components/Global/ActionButton.vue'
import ArrowDownIcon from '@app/components/Icons/ArrowDownIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'

const snackbar = useSnackbar()
const loading = useLoading()
const props = defineProps<{
  type: ImportSourceType
}>()

interface CreateImportSourceModel {
  type: ImportSourceType
  shortName: string
  fullName: string
  description: string
  url: string
  format: CitationFileFormat
}

const importSourceShortNameInput = ref<InstanceType<typeof TextInput>>()
const importSourceFullNameInput = ref<InstanceType<typeof TextInput>>()
const importSourceDescriptionInput = ref<InstanceType<typeof TextInput>>()
const importSourceUrlInput = ref<InstanceType<typeof TextInput>>()
const floatingMenu = ref()

const createImportSourceModel = ref<CreateImportSourceModel>({
  type: props.type,
  shortName: '',
  fullName: '',
  description: '',
  url: '',
  format: CitationFileFormat.RIS,
})
const importSources = useImportSources()

async function create() {
  if (!validate()) return
  try {
    loading.start()
    snackbar.show(SnackbarState.SUCCESS, 'import source created successfully')
    await importSources.create(createImportSourceModel.value)
    reset()
    hideAll()
  } catch (e: any) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function validate() {
  const isValidShortName = importSourceShortNameInput.value?.validate()
  const isValidFullName = importSourceFullNameInput.value?.validate()
  const isValidDescription = importSourceDescriptionInput.value?.validate()
  const isValidUrl = importSourceUrlInput.value?.validate()
  return isValidShortName && isValidFullName && isValidDescription && isValidUrl
}

function reset() {
  createImportSourceModel.value = {
    type: props.type,
    shortName: '',
    fullName: '',
    description: '',
    url: '',
    format: CitationFileFormat.RIS,
  }
}

async function openMenu() {
  floatingMenu?.value?.show()
  importSourceShortNameInput.value?.focus()
}
</script>
