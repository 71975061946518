<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5036 19.9509C32.2475 19.5479 31.9025 19.2096 31.4954 18.9623C31.0884 18.7149 30.6302 18.5652 30.1563 18.5247H29.8792C29.2392 18.5563 28.6227 18.7768 28.1067 19.1588C27.5907 19.5407 27.1979 20.0671 26.9777 20.6722C26.4306 22.2015 25.5378 23.5821 24.3696 24.705C23.1159 25.9212 21.5846 26.8093 19.91 27.2915C18.2354 27.7737 16.4688 27.8352 14.765 27.4706C13.0613 27.1061 11.4725 26.3266 10.1382 25.2006C8.80383 24.0746 7.76465 22.6365 7.11179 21.0124C6.45893 19.3883 6.21236 17.6278 6.39372 15.8855C6.57507 14.1433 7.17877 12.4724 8.15191 11.0195C9.12505 9.56657 10.4379 8.37603 11.9752 7.55234C13.5125 6.72865 15.2273 6.29696 16.9691 6.29516C17.6251 6.29861 18.2796 6.35897 18.9252 6.47552C19.4799 6.55832 20.0066 6.7744 20.4606 7.10543C20.9146 7.43646 21.2826 7.8727 21.5333 8.37716C21.8267 9.31159 21.0606 10.3443 20.3434 11.0657L19.1046 12.328C18.4376 12.0094 17.7075 11.8469 16.9691 11.8526C15.8716 11.8491 14.8025 12.2028 13.9213 12.8608C13.0401 13.5188 12.3942 14.4459 12.0801 15.5035C11.766 16.5611 11.8005 17.6925 12.1785 18.7288C12.5565 19.765 13.2576 20.6505 14.1772 21.2529C15.0969 21.8554 16.1856 22.1425 17.2808 22.0714C18.3761 22.0003 19.4191 21.5748 20.254 20.8584C21.089 20.142 21.671 19.1732 21.9134 18.0966C22.1557 17.0201 22.0453 15.8936 21.5985 14.8854L28.1187 8.32798C28.6132 7.73954 28.8637 6.98231 28.8181 6.21322C28.7726 5.44412 28.4346 4.72209 27.8742 4.1968C24.8447 1.51374 20.9407 0.043575 16.904 0.0656753C14.1592 0.0560099 11.4532 0.718065 9.01957 1.99474C6.58592 3.27141 4.49764 5.12436 2.93489 7.39375C1.37213 9.66313 0.381818 12.2808 0.0493685 15.0209C-0.283081 17.7611 0.0523435 20.5415 1.02666 23.1222C2.00098 25.7029 3.58492 28.0065 5.64192 29.8343C7.69892 31.662 10.1672 32.959 12.8338 33.6133C15.5004 34.2677 18.2852 34.2598 20.9481 33.5903C23.611 32.9209 26.072 31.61 28.1187 29.7706L28.836 29.0984C30.6082 27.3153 31.9664 25.16 32.8132 22.787C32.9848 22.3227 33.0461 21.8245 32.9924 21.3321C32.9386 20.8397 32.7712 20.3667 32.5036 19.9509Z"
      fill="#2E84C6"
    />
  </svg>
</template>
