<template>
  <FloatingMenu placement="bottom-start" :arrow="false" ref="floatingMenu">
    <template #activator>
      <button
        class="font-bold border p-5 text-gray-900 rounded-full bg-white w-5 h-5 text-sm uppercase flex items-center justify-center dark:bg-[#103047] dark:text-white"
        @click="openMenu"
      >
        {{ auth.user.value?.email.charAt(0) }}
      </button>
    </template>
    <template #content>
      <div
        class="w-32 divide-gray-900 divide-y-2 dark:bg-[#103047] dark:text-white/70"
      >
        <button
          class="align-text-bottom uppercase flex justify-center w-full py-1 px-2 hover:bg-zinc-300 dark:hover:bg-[#344F62]"
          @click="logout"
        >
          logout
        </button>

        <Modal dismissible>
          <template #activator="{ show }">
            <button
              class="align-text-bottom uppercase flex justify-center w-full py-1 px-2 hover:bg-zinc-300 dark:hover:bg-[#344F62]"
              @click="show"
            >
              settings
            </button>
          </template>
          <template #content>
            <div
              class="py-1 px-2 w-80 dark:bg-[#103047] dark:text-white/70 dark:border-white/10 border"
            >
              <div
                class="text-center uppercase w-full block text-grey-darker tracking-wide font-bold"
              >
                <p>settings</p>
              </div>
              <div class="py-2">
                <SelectList
                  :items="[
                    {
                      text: 'DD/MM/YYYY',
                      value: 'dd/MM/yyyy',
                      title: format(new Date(), 'dd/MM/yyyy'),
                    },
                    {
                      text: 'MM/DD/yyyy',
                      value: 'MM/dd/yyyy',
                      title: format(new Date(), 'MM/dd/yyyy'),
                    },
                  ]"
                  :model-value="settings.dateFormat.value"
                  @change="
                    snackbar.show(
                      SnackbarState.SUCCESS,
                      'date Format changed successfully'
                    )
                  "
                  @update:model-value="onDateFormatChange"
                  placeholder="Date Format"
                  label="Date Format"
                />
              </div>
            </div>
          </template>
        </Modal>
        <Modal dismissible>
          <template #activator="{ show }">
            <button
              class="align-text-bottom uppercase flex justify-center w-full py-1 px-2 hover:bg-zinc-300 dark:hover:bg-[#344F62]"
              @click="show"
            >
              profile
            </button>
          </template>
          <template #content="{ hide }">
            <div
              class="py-1 px-2 w-80 dark:bg-[#103047] dark:text-white/70 dark:border-white/10 border"
            >
              <p
                class="text-center uppercase w-full block text-grey-darker tracking-wide font-bold px-1 py-2"
              >
                profile
              </p>

              <div class="py-2">
                <TextInput
                  placeholder="Email"
                  v-model="auth.user.value!.email "
                  label="Email"
                  :disabled="true"
                  class="px-1 py-2"
                />

                <TextInput
                  placeholder="First name"
                  v-model="userFullName.firstName"
                  label="First name"
                  class="px-1 py-2"
                />
                <TextInput
                  placeholder="Last name"
                  v-model="userFullName.lastName"
                  label="Last name"
                  class="px-1 py-2"
                />
                <div class="text-center">
                  <button
                    @click="updateCurrentUserFullName(userFullName, hide)"
                    class="bg-green-600 text-white py-1 px-2 mt-2 w-24 hover:opacity-90"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </template>
        </Modal>
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { inject, ref } from 'vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import useAuth from '@app/composables/use-auth'
import { useRouter } from 'vue-router'
import { UsersService } from '@core/application/users.service'
import { useSettings } from '@app/composables/use-settings'
import { format } from 'date-fns'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import SelectList from '@app/components/Global/Inputs/SelectList.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useUsers from '@app/composables/use-users'
import useLoading from '@app/composables/use-loading'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
const snackbar = useSnackbar()
const auth = useAuth()
const users = useUsers()
const settings = useSettings()
const loading = useLoading()

const router = useRouter()
const usersService: UsersService = inject('usersService')!

const floatingMenu = ref()
function onDateFormatChange(format: string) {
  settings.dateFormat.value = format
}
const userFullName = ref({
  firstName: auth.user.value?.firstName ?? '',
  lastName: auth.user.value?.lastName ?? '',
})

async function updateCurrentUserFullName(
  fullName: {
    firstName: string
    lastName: string
  },
  callback: Function
) {
  try {
    loading.start()
    await users.updateCurrentUserFullName(fullName)
    snackbar.show(SnackbarState.SUCCESS, 'Profile updated successfully')
    callback()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
  const user = await usersService.findCurrentUser().catch(() => null)
  if (user) auth.setUser(user)
}

async function openMenu() {
  floatingMenu?.value?.show()
}
async function logout() {
  auth.logout()
  usersService.saveAuthToken('')
  usersService.setAuthHeader('')
  router.push('/login')
}
</script>
