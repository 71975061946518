import { ReviewsService } from '@core/application/reviews.service'
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'

export type StudyFile = {
  studyId: number
  title: string
  reviewId: number
  reviewName: string
  fileName: string
  filePath: string
  studyReference: string
}
const studiesPdfFiles = ref<StudyFile[]>([])
const isVisible = ref(false)
const activePdfIndex = ref<number>(-1)
const openedPdfFiles = ref<{ [studyId: number]: Blob }>({})
export default () => {
  const reviewsService: ReviewsService = inject('reviewsService')!

  function addStudyPdfFile(studyFile: StudyFile) {
    if (!studyFile.filePath)
      throw new Error('study does not contain a pdf file')
    const alreadyExists = studiesPdfFiles.value.some(
      (f) => f.studyId === studyFile.studyId
    )
    if (!alreadyExists) studiesPdfFiles.value.push(studyFile)
    openPdf(studyFile)
  }

  function removeStudyPdfFile(studyId: number) {
    const studyFileIndex = studiesPdfFiles.value.findIndex(
      (f) => f.studyId === studyId
    )
    studiesPdfFiles.value.splice(studyFileIndex, 1)
    if (activePdfIndex.value >= studyFileIndex) {
      activePdfIndex.value =
        activePdfIndex.value > 0 ? activePdfIndex.value - 1 : 0
    }
    if (studiesPdfFiles.value.length <= 0) hide()
  }

  function show() {
    isVisible.value = true
  }

  function hide() {
    isVisible.value = false
  }

  async function openPdf(studyFile: StudyFile) {
    const isPdfAlreadyLoaded = Object.keys(openedPdfFiles.value).some(
      (sId) => parseInt(sId) === studyFile.studyId
    )
    if (!isPdfAlreadyLoaded) {
      const pdfFile = await reviewsService.getStudyPdfFile(
        studyFile.reviewId,
        studyFile.studyId
      )
      openedPdfFiles.value[studyFile.studyId] = pdfFile
    }
    activePdfIndex.value = studiesPdfFiles.value.findIndex(
      (f) => f.studyId === studyFile.studyId
    )
    show()
  }

  const studiesCount = computed(
    () => Object.keys(studiesPdfFiles.value)?.length ?? 0
  )

  function dismissOnEsc(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      hide()
    }
  }
  onMounted(() => {
    document.addEventListener('keydown', dismissOnEsc)
  })

  onBeforeUnmount(() => document.removeEventListener('keydown', dismissOnEsc))

  return {
    studiesPdfFiles,
    isVisible,
    activePdfIndex,
    studiesCount,
    openedPdfFiles,
    addStudyPdfFile,
    removeStudyPdfFile,
    show,
    hide,
    openPdf,
  }
}
