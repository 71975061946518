<template>
  <div id="modal" />
  <div
    id="app-wrapper"
    class="bg-white dark:bg-[#103047] dark:text-white text-primary"
  >
    <header
      class="sticky top-0 left-0 dark:bg-[#133954]"
      v-if="auth.user.value"
      style="z-index: 999999999"
    >
      <div
        class="flex px-4 w-full relative justify-between h-16 border-b-2 dark:border-[#36576d] items-center"
      >
        <div class="flex items-center gap-14 justify-self-start">
          <button
            @click="$router.push('/')"
            class="flex gap-1.5 text-xl font-bold px-2 py-1 dark:text-white"
          >
            <LogoIcon />evidence
          </button>
        </div>
        <div class="flex gap-4">
          <button @click="switchTheme">
            <SunIcon class="w-6 h-6" v-if="isDarkMode" />
            <MoonIcon class="w-6 h-6" v-else />
          </button>
          <UserSettingsFloatingMenu />
        </div>
        <div
          v-if="loading.isLoading.value"
          class="overflow-hidden absolute bottom-0 left-0 w-full h-1"
        >
          <div class="line absolute bg-blue-600/20 h-1"></div>
          <div class="absolute bg-blue-600 h-1 inc"></div>
          <div class="absolute bg-blue-600 h-1 dec"></div>
        </div>
      </div>
      <div
        class="w-full bg-yellow-700 px-10 py-4 flex justify-between text-white"
        v-if="changelog.isNewVersionAvailable.value"
      >
        <RouterLink class="underline" to="/changelog"
          >New version available, click here for more details</RouterLink
        >
        <div class="flex gap-8">
          <button @click="changelog.acknowledgeUpdate">close</button>
          <button @click="changelog.disableUpdatesNotification">
            disable notification
          </button>
        </div>
      </div>
    </header>

    <div id="toolbar" class="dark:bg-[#1B3F58]" />

    <main
      id="main"
      :class="[
        modalManager.modals.value.some((m) => m.isVisible)
          ? 'overflow-hidden'
          : 'overflow-auto',
      ]"
    >
      <router-view :key="routeKey" />
      <Snackbar />
    </main>
    <aside
      class="border-r-2 dark:border-[#36576d] flex flex-col pt-4 px-3 justify-between"
      v-if="auth.user.value"
    >
      <div class="flex items-center flex-col gap-4">
        <RevManRouterLink
          v-for="routeName in [
            RouteName.PROJECTS,
            RouteName.USERS,
            RouteName.IMPORT_SOURCES,
          ]"
          :route-name="routeName"
          v-slot="{ route, isActiveRoute }"
        >
          <div
            class="hover:bg-[#F7F7F7] dark:hover:bg-[#36576D] flex gap-2 py-2.5 px-4"
            :class="{
              'bg-[#E8F5FF] dark:bg-[#36576D]': isActiveRoute,
              'w-60': !isSidebarCollapsed,
            }"
          >
            <component :is="route.icon" class="w-[25px] h-auto" />
            <div v-if="!isSidebarCollapsed">{{ route.text }}</div>
          </div>
        </RevManRouterLink>
        <hr class="h-1 w-full" />
        <StudiesPdfsViewer key="global-pdf-viewer">
          <template #activator="{ show, studiesCount }">
            <button
              class="px-2 py-1 rounded text-primary dark:text-white flex"
              @click="studiesCount > 0 && show()"
              :class="{
                'text-gray-400 dark:text-gray-400 cursor-auto hover:brightness-100':
                  studiesCount <= 0,
                'w-52 !justify-start ': !isSidebarCollapsed,
              }"
            >
              <div class="flex relative gap-2">
                <PdfIcon class="w-6 h-6" />
                <div v-if="!isSidebarCollapsed">PDF viewer</div>
                <div
                  class="absolute -top-2 -right-6 rounded-full bg-red-600 w-4 h-4 flex items-center justify-center text-white text-xs"
                  :class="{ 'text-white bg-gray-400': studiesCount <= 0 }"
                >
                  {{ studiesCount }}
                </div>
              </div>
            </button>
          </template>
        </StudiesPdfsViewer>
      </div>
      <div class="flex flex-col p-4 gap-6 items-center">
        <div class="flex items-center flex-col gap-4">
          <RevManRouterLink
            v-for="routeName in [RouteName.CHANGELOG]"
            :route-name="routeName"
            v-slot="{ route, isActiveRoute }"
          >
            <div
              class="hover:bg-[#F7F7F7] dark:hover:bg-[#36576D] flex gap-2 py-2.5 px-4"
              :class="{
                'bg-[#E8F5FF] dark:bg-[#36576D]': isActiveRoute,
                'w-60': !isSidebarCollapsed,
              }"
            >
              <component :is="route.icon" class="w-[25px] h-auto" />
              <div v-if="!isSidebarCollapsed">{{ route.text }}</div>
            </div>
          </RevManRouterLink>
        </div>

        <button
          class="h-6 flex items-center justify-start"
          @click="isSidebarCollapsed = !isSidebarCollapsed"
          :class="{ 'w-60': !isSidebarCollapsed }"
        >
          <ChevronDoubleRightIcon v-if="isSidebarCollapsed" class="h-5 w-5" />
          <div class="flex items-center" v-else>
            <ChevronDoubleLeftIcon class="h-5 w-5" />
            Collapse
          </div>
        </button>
      </div>
    </aside>
  </div>
  <div
    id="off-screen"
    class="overflow-hidden absolute top-0 left-0 -z-50"
  ></div>
</template>
<script lang="ts" setup>
import useAuth from './composables/use-auth'
import Snackbar from './views/Snackbar.vue'
import useLoading from './composables/use-loading'
import RevManRouterLink from './components/Global/RevManRouterLink.vue'
import { RouteName } from '@app/router'
import UserSettingsFloatingMenu from '@app/views/Users/UserSettingsFloatingMenu.vue'
import { useRoute } from 'vue-router'
import { ref, watch, onMounted } from 'vue'
import LogoIcon from './components/Icons/logoIcon.vue'
import ChevronDoubleRightIcon from './components/Icons/ChevronDoubleRightIcon.vue'
import ChevronDoubleLeftIcon from './components/Icons/ChevronDoubleLeftIcon.vue'
import useModalManager from './components/Global/Modal/modal-manager'
import SunIcon from './components/Icons/SunIcon.vue'
import MoonIcon from './components/Icons/MoonIcon.vue'
import useChangelog from './composables/use-changelog'
import PdfIcon from './components/Icons/PdfIcon.vue'
import StudiesPdfsViewer from './components/Global/StudiesPdfsViewer/StudiesPdfsViewer.vue'

const isSidebarCollapsed = ref(true)
const modalManager = useModalManager()
const auth = useAuth()
const loading = useLoading()
const route = useRoute()
const routeKey = ref(route.fullPath)
watch(
  () => route.fullPath,
  () => {
    routeKey.value = route.fullPath
  }
)

const isDarkMode = ref(false)

onMounted(() => {
  const activeTheme = localStorage.getItem('theme')
  if (!activeTheme || activeTheme === 'light') {
    document.body.classList.remove('dark')
    isDarkMode.value = false
  } else {
    document.body.classList.add('dark')
    isDarkMode.value = true
  }
})

function switchTheme() {
  if (isDarkMode.value) {
    document.body.classList.remove('dark')
  } else {
    document.body.classList.add('dark')
  }
  isDarkMode.value = !isDarkMode.value
  localStorage.setItem('theme', isDarkMode.value ? 'dark' : 'light')
}

const changelog = useChangelog()
</script>
<style>
.line {
  width: 150%;
}

.inc {
  animation: increase 2s infinite;
}

.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }

  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }

  to {
    left: 110%;
    width: 10%;
  }
}

#app-wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    'header header'
    'aside toolbar'
    'aside main'
    'footer footer';
}

#app-wrapper > header {
  grid-area: header;
}

#app-wrapper > main {
  grid-area: main;
}

#app-wrapper > #toolbar {
  grid-area: toolbar;
}

#app-wrapper > aside {
  grid-area: aside;
}

#app-wrapper > footer {
  grid-area: footer;
}
* {
  font-weight: 500;
}
</style>
