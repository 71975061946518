<template>
  <div
    class="px-4 py-8"
    v-for="(source, sourceType) in groupedImportSourcesByType"
  >
    <div
      class="w-full flex px-2 py-3 justify-between items-center bg-blue-cyan rounded-t-md border dark:border-[#345C7C] border-cyan dark:bg-[#1B3F58] dark:text-primary"
    >
      <p class="text-white font-bold">{{ sourceType }}S</p>
      <CreateImportSourcesFloatingMenu :type="sourceType" />
    </div>

    <Table
      class="w-full table-auto border dark:border-[#345C7C] border-t-0"
      :headers="['shortName', 'fullName', 'format', 'description', 'url']"
      :items="source"
    >
      <template #item="{ item: importSource }">
        <td class="py-4 px-2 text-left align-top w-[10%]">
          <InlineTextInput
            placeholder="please add a short name"
            ref="editReferenceInlineTextInput"
            :model-value="importSource.shortName"
            @save="updateImportSourceShortName(importSource.id, $event)"
          />
        </td>
        <td class="py-4 px-2 text-left align-top w-[18%]">
          <InlineTextInput
            placeholder="please add a full name"
            ref="editReferenceInlineTextInput"
            :model-value="importSource.fullName"
            @save="updateImportSourceFullName(importSource.id, $event)"
          />
        </td>
        <td class="py-4 px-2 text-left align-top w-[9%]">
          {{ importSource.format }}
        </td>
        <td class="py-4 px-2 text-left align-top w-[40%]">
          <InlineTextInput
            placeholder="no description"
            ref="editReferenceInlineTextInput"
            :model-value="importSource.description"
            multiline
            @save="updateImportSourceDescription(importSource.id, $event)"
          />
        </td>
        <td class="py-4 px-2 text-left align-top w-[23%]">
          <div class="flex items-center gap-2">
            <InlineTextInput
              placeholder="no url"
              ref="editReferenceInlineTextInput"
              :model-value="importSource.url"
              @save="updateImportSourceUrl(importSource.id, $event)"
            />
            <a
              v-if="importSource.url"
              class="underline hover:text-zinc-300"
              :href="importSource.url"
              target="_blank"
            >
              <ExternalLinkIcon class="w-5 h-5" />
            </a>
          </div>
        </td>
      </template>
    </Table>
  </div>
</template>

<script lang="ts" setup>
import Table from '@app/components/Global/Table.vue'
import CreateImportSourcesFloatingMenu from '@app/views/ImportSources/CreateImportSourcesFloatingMenu.vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import ExternalLinkIcon from '@app/components/Icons/ExternalLinkIcon.vue'
import useImportSources from '@app/composables/use-import-sources'
import { ImportSourceId } from '@core/domain/types/import-source-id.type'
import { computed, ref } from 'vue'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ImportSource } from '@core/domain/models/import-source.model'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'

const loading = useLoading()
const isLoading = ref(false)
const snackbar = useSnackbar()
type GroupedImportSourcesByType = {
  [ImportSourceType.DATABASE]: ImportSource[]
  [ImportSourceType.REGISTER]: ImportSource[]
  [ImportSourceType.WEBSITE]: ImportSource[]
  [ImportSourceType.ORGANISATION]: ImportSource[]
}

const importSources = useImportSources()

importSources.refresh()

function startLoading() {
  loading.start()
  isLoading.value = true
}

function stopLoading() {
  loading.stop()
  isLoading.value = false
}

async function updateImportSourceShortName(
  id: ImportSourceId,
  shortName: string
) {
  try {
    startLoading()
    await importSources.update(id, { shortName })
    snackbar.show(
      SnackbarState.SUCCESS,
      'import source short name updated successfully'
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

async function updateImportSourceFullName(
  id: ImportSourceId,
  fullName: string
) {
  try {
    startLoading()
    await importSources.update(id, { fullName })
    snackbar.show(
      SnackbarState.SUCCESS,
      'import source full name updated successfully'
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

async function updateImportSourceDescription(
  id: ImportSourceId,
  description: string
) {
  try {
    startLoading()
    await importSources.update(id, { description })
    snackbar.show(
      SnackbarState.SUCCESS,
      'import source description updated successfully'
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}
async function updateImportSourceUrl(id: ImportSourceId, url: string) {
  try {
    startLoading()
    await importSources.update(id, { url })
    snackbar.show(
      SnackbarState.SUCCESS,
      'import source url updated successfully'
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

const groupedImportSourcesByType = computed<GroupedImportSourcesByType>(() => {
  const value: GroupedImportSourcesByType = {
    [ImportSourceType.DATABASE]: [],
    [ImportSourceType.REGISTER]: [],
    [ImportSourceType.WEBSITE]: [],
    [ImportSourceType.ORGANISATION]: [],
  }
  importSources.list.value.forEach((importSource) => {
    if (
      importSource.type === ImportSourceType.HAND_SEARCH ||
      importSource.type === ImportSourceType.CITATION_SEARCH
    ) {
      return
    }
    value[importSource.type].push(importSource)
  })
  return value
})
</script>
