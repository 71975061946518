<template>
  <div>
    <label
      v-if="label"
      class="block text-sm font-bold mb-1 text-primary dark:text-white"
      :for="id"
    >
      {{ label }}
    </label>
    <label
      v-if="labelnormal"
      class="block text-sm mb-1 text-primary dark:text-white"
      :for="id"
    >
      {{ labelnormal }}
    </label>
    <div class="relative">
      <input
        v-if="!multiline"
        :type="inputType"
        class="appearance-none text-primary w-full py-2 px-4 text-grey-darker border-gray-400 border rounded-md focus:outline-none hover:brightness-95 dark:bg-[#4E6875] dark:text-white placeholder-[#045170] placeholder-opacity-70 dark:placeholder-gray-400"
        @input="validate"
        v-model="value"
        :id="id"
        :placeholder="placeholder"
        ref="input"
        :disabled="disabled"
        :class="[
          {
            'placeholder-red-600/50 dark:placeholder-red-300/50 border-red-600 dark:border-red-300 text-red-600 dark:text-red-300':
              computedError,
          },
          inputClasses,
        ]"
        :tabindex="tabindex"
      />
      <textarea
        v-else
        class="appearance-none text-primary w-full py-2 px-4 text-grey-darker border-gray-400 border rounded-md focus:outline-none hover:brightness-95 dark:bg-[#4E6875] dark:text-white placeholder-[#045170] placeholder-opacity-70 dark:placeholder-gray-400"
        @input="validate"
        v-model="value"
        :id="id"
        :placeholder="placeholder"
        ref="input"
        :disabled="disabled"
        :tabindex="tabindex"
        :class="[inputClasses]"
      />

      <div
        class="absolute h-full flex top-1/2 -translate-y-1/2 right-2 text-primary"
      >
        <button v-if="clearable && value" @click="clear()">
          <XCircleIcon class="w-5 h-5" />
        </button>
        <button v-if="type === 'password'" @click="togglePassword()">
          <EyeIcon class="w-5 h-5 dark:fill-white/70" />
        </button>
        <button v-if="type === 'email'">
          <EnvelopeIcon class="w-5 h-5 dark:fill-white/70" />
        </button>
        <label :for="id" class="flex items-center">
          <slot name="suffix" />
        </label>
      </div>
    </div>
    <p
      class="text-red-600 dark:text-red-300 text-xs mt-0.5 h-4 overflow-hidden"
    >
      {{ computedError }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import EyeIcon from '@app/components/Icons/EyeIcon.vue'
import XCircleIcon from '../../Icons/XCircleIcon.vue'
import EnvelopeIcon from '@app/components/Icons/EnvelopeIcon.vue'

const input = ref<HTMLInputElement | HTMLTextAreaElement>()
const id = uuidv4()

const props = withDefaults(
  defineProps<{
    type?: 'text' | 'password' | 'email'
    modelValue?: string
    label?: string
    labelnormal?: string
    clearable?: boolean
    placeholder?: string
    rules?: ((v: string) => boolean | string)[]
    disabled?: boolean
    multiline?: boolean
    error?: string
    tabindex?: number
    inputClasses?: string
  }>(),
  {
    type: 'text',
    clearable: false,
    modelValue: '',
    rules: () => [],

    disabled: false,
    multiline: false,
    error: '',
    tabindex: 0,
    inputClasses: '',
  }
)
const emit = defineEmits<(e: 'update:modelValue', value: string) => void>()

const value = computed<string>({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  },
})

const isPasswordShown = ref(false)
const inputType = computed(() =>
  props.type === 'text' || props.type === 'email' || isPasswordShown.value
    ? 'text'
    : 'password'
)

function togglePassword() {
  isPasswordShown.value = !isPasswordShown.value
}

function clear() {
  emit('update:modelValue', '')
}

const internalError = ref<string>()

const computedError = computed(() =>
  props.error ? props.error : internalError.value
)

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  internalError.value = errors.join(' ')
  return errors.length <= 0
}

function focus() {
  input.value?.focus({
    preventScroll: true,
  })
}
function select() {
  input.value?.select()
}

defineExpose({
  validate,
  focus,
  select,
})
</script>
