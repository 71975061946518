import { Id } from '@core/domain/types/id.type'
import { computed, ref } from 'vue'

import { getItem, setItem } from 'localforage'

export default () => {
  const expandedProjectsIdsInternalValue = ref<number[]>([])
  getItem<string>('expandedProjects').then((v) => {
    expandedProjectsIdsInternalValue.value = JSON.parse(v ?? '')
  })

  const expandedProjectsId = computed(
    () => expandedProjectsIdsInternalValue.value
  )
  function toggleProjectExpansion(projectId: Id) {
    const index = expandedProjectsIdsInternalValue.value.findIndex(
      (pId) => pId === projectId
    )
    if (index > -1) expandedProjectsIdsInternalValue.value.splice(index, 1)
    else expandedProjectsIdsInternalValue.value.push(projectId)
    setItem(
      'expandedProjects',
      JSON.stringify(expandedProjectsIdsInternalValue.value)
    )
  }
  return { expandedProjectsId, toggleProjectExpansion }
}
