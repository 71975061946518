<template>
  <FloatingMenu
    placement="bottom"
    ref="floatingMenu"
    :offset="[0, -10]"
    @hide="reset"
  >
    <template #activator>
      <div
        @click="openMenu"
        v-tooltip="'Add external member'"
        class="pb-4 pt-1 px-1 flex gap-1 items-center hover:cursor-pointer hover:brightness-110 text-primary"
      >
        <UserPlusIcon class="fill-primary w-5 h-5" />
        <p
          class="font-medium text-base leading-4 tracking-tight whitespace-nowrap"
        >
          External member
        </p>
        <ChevronDownIcon
          class="w-4 h-4 stroke-[3] transition-all"
          :class="{
            'rotate-180': isShown,
          }"
        />
      </div>
    </template>
    <template #content>
      <div
        class="w-[620px] p-2 absolute bg-white space-y-2 border bored border-[#D9E4EA] dark:bg-[#103047]"
      >
        <TextInput
          class="w-full text-sm font-normal"
          labelnormal="Add external member"
          placeholder="Type external member name"
          type="text"
          v-model="newExternalMemberName"
          @keydown.enter="addNewExternalMember()"
        />
      </div>
    </template>
  </FloatingMenu>
</template>

<script setup lang="ts">
import projectPlan from '../projectPlan'

import { ref } from 'vue'

import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import { Id } from '@core/domain/types/id.type'

const props = defineProps<{
  projectId: Id
}>()
const floatingMenu = ref()
const newExternalMemberName = ref('')
const isShown = ref(false)

async function openMenu() {
  floatingMenu?.value?.show()
  isShown.value = true
}
const { addExternalMember } = projectPlan()
function addNewExternalMember() {
  addExternalMember(newExternalMemberName.value, props.projectId)
  newExternalMemberName.value = ''
  floatingMenu.value?.hide()
  isShown.value = false
}
function reset() {
  isShown.value = false
}
</script>
