<template>
  <div
    class="fixed bottom-0 flex justify-center w-full"
    style="z-index: 999999999999999"
  >
    <transition name="slide-fade">
      <div
        class="px-4 py-2 text-white"
        :class="[
          {
            'bg-green-600': snackbar.state.value === SnackbarState.SUCCESS,
            'bg-red-600': snackbar.state.value === SnackbarState.ERROR,
          },
        ]"
        v-if="snackbar.isShown.value"
      >
        {{ snackbar.content.value }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'

const snackbar = useSnackbar()
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transform: translateY(100%);
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
  transform: translateY(100%);
}

.slide-fade-enter-to {
  transform: translateY(0);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
