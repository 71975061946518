import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { Id } from '@core/domain/types/id.type'
import { HttpClient } from '@infrastructure/datasource/http-client'
import { Api } from './review-manager-api'

export class ProjectsRepository {
  constructor(
    private _httpClient: HttpClient,
    private _reviewManagerService: Api<any>
  ) {}
  async create(project: Project): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerCreate(project)
    return new Project(data as Partial<Project>)
  }

  async find(): Promise<Project[]> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerFind()
    return data.map((p: any) => new Project(p))
  }

  async createReview(projectId: Id, name: string): Promise<Project> {
    const { data } = await this._httpClient.post(
      `/api/projects/${projectId}/review`,
      {
        name,
      }
    )
    return new Project(data)
  }

  async delete(projectId: Id): Promise<void> {
    await this._httpClient.delete(`/api/projects/${projectId}`)
  }

  async exportRis(projectId: Id, selectedReviewsIds: Id[]) {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/projects/${projectId}/ris/${selectedReviewsIds}`,
      {
        responseType: 'blob',
      }
    )
    return data as unknown as Blob
  }

  async exportCitationsDocx(projectId: Id, selectedReviewsIds: Id[]) {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/projects/${projectId}/citations/${selectedReviewsIds}`,
      {
        responseType: 'blob',
        params: { sclStyle: 'apa' },
      }
    )

    return data as unknown as Blob
  }

  async downloadPdfZip(projectId: Id, selectedReviewsIds: Id[]) {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/projects/${projectId}/pdf/${selectedReviewsIds}/studies/zip`,
      {
        responseType: 'blob',
      }
    )

    return data as unknown as Blob
  }

  async updateProjectName(projectId: Id, name: string): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerUpdateName(
        projectId,
        { name }
      )
    return new Project(data as Partial<Project>)
  }

  async updateContactForComplaint(
    projectId: Id,
    email: string
  ): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerUpdateContactForComplaintEmail(
        projectId,
        { email }
      )
    return new Project(data as Partial<Project>)
  }
  async findProjectUsers(projectId: Id): Promise<User[]> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerFindProjectUsers(
        projectId
      )
    return data.map((u: any) => new User(u))
  }

  async addUserToProject(projectId: Id, userId: Id): Promise<void> {
    await this._reviewManagerService.api.projectsControllerAddUserToProject(
      projectId,
      userId
    )
  }

  async updateCslStyle(projectId: Id, cslStyle: string): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerUpdateCslStyle(
        projectId,
        { style: cslStyle }
      )
    return new Project(data as Partial<Project>)
  }
}
