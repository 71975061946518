import { Id } from '../types/id.type'
import { Review } from './review.model'
import { User } from './user.model'

export class Project {
  id?: Id
  name: string
  contactForComplaintEmail?: string
  reviews?: Review[]
  cslStyle?: string
  isFavorite?: boolean
  users?: User[]
  constructor(data: Partial<Project>) {
    this.name = data.name ?? ''
    this.id = data.id
    this.isFavorite = data.isFavorite ?? false
    this.contactForComplaintEmail = data.contactForComplaintEmail ?? ''
    this.reviews = data.reviews
    this.cslStyle = data.cslStyle
    this.users = data.users ?? []
  }

  static create(data: { name: string }) {
    const project = new Project(data)
    if (!project.name) throw Error('name is required')
    return project
  }
}
