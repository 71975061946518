/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateProjectDto {
  name: string
}

export interface DisplayImportSourceDto {
  id: string
  shortName: string
  fullName: string
  description?: string
  type:
    | 'REGISTER'
    | 'DATABASE'
    | 'WEBSITE'
    | 'ORGANISATION'
    | 'HAND_SEARCH'
    | 'CITATION_SEARCH'
  format: 'ris' | 'pubmed' | 'harvard-citation' | 'clinical-trials-gov-json'
  url?: string
}

export interface DisplayFileDto {
  name: string
  path: string
}

export interface DisplayStudyDto {
  pdfContent?: string
  pdfFile?: DisplayFileDto
  search?: DisplaySearchDto
  id: number
  parentStudyId: number
  citationFileId: string
  rawData: string
  title?: string
  abstract?: string
  authors?: string[]
  doi?: string
  url?: string
  publishYear?: string
  journal?: string
  isDuplicate?: boolean
  volumeNumber?: string
  issueNumber?: string
  pagesNumber?: string
  isFavorite?: boolean

  /** @format date-time */
  complaintDate?: string
  titleAndAbstractScreening?: string
  fullTextScreening?: string
  reference?: string
  oxfordLevelOfEvidence?: string
}

export interface DisplaySearchDto {
  id: number
  personUndertakingSearch: string
  source: DisplayImportSourceDto
  searchDate?: object
  studies?: DisplayStudyDto[]
  citationFiles?: DisplayFileDto[]
  searchQuery?: string
  url?: string
  parentStudyId: number
}

export interface DisplayScreeningPlanDto {
  stepsCount: object
  titleAndAbstractCriteria?: string[]
  fullTextCriteria: string[]
  isLocked?: boolean
  searches?: DisplaySearchDto[]
}

export interface DisplayImportPlanDto {
  importSources: DisplayImportSourceDto[]
}

export interface DisplayAppraisalPlanDto {
  isImdrfMdce2019Applicable: boolean
  isOxfordLevelOfEvidenceApplicable: boolean
}

export interface DisplayDataExtractionField {
  id: string
  name: string
  type: 'TEXT'
}

export interface DisplayStudyDataExtractionPlan {
  fields: DisplayDataExtractionField[]
}

export interface DisplayLiteratureReviewPlanDto {
  screeningPlan?: DisplayScreeningPlanDto
  importPlan?: DisplayImportPlanDto
  appraisalPlan?: DisplayAppraisalPlanDto
  studyDataExtractionPlan?: DisplayStudyDataExtractionPlan
}

export interface DisplayUserDto {
  id: number
  email: string
  name?: string
  isDisabled: boolean
}

export interface DisplayProjectDto {
  id: number
  name: string
  contactForComplaintEmail?: string
  reviews?: DisplayReviewDto[]
  users?: DisplayUserDto[]
}

export interface DisplayReviewDto {
  id: number
  name: string
  plan?: DisplayLiteratureReviewPlanDto
  searches?: DisplaySearchDto[]
  project?: DisplayProjectDto
}

export interface CreateReviewDto {
  name: string
}

export interface UpdateContactForComplaintEmailDto {
  email: string
}

export interface UpdateProjectNameDto {
  name: string
}

export interface UpdateProjectCslStyleDto {
  style: string
}

export interface CreateSearchDto {
  personUndertakingSearch: string
  checksum: string
  source: string
  searchDate?: string
  searchQuery?: string
  url?: string
  parentStudyId?: number
  filter?: string
}

export interface ImportStudiesDto {
  citationFiles?: File[]

  /** @format binary */
  queryFile?: File
  search: CreateSearchDto
}

export type Study = object

export type Date = object

export interface PlanScreeningDto {
  /**
   * @min 1
   * @max 2
   */
  stepsCount: number
  titleAndAbstractCriteria?: string[]
  fullTextCriteria: string[]
}

export type ScreeningPlan = object

export interface UpdateReviewNameDto {
  name: string
}

export interface UploadFileDto {
  /** @format binary */
  file: File
}

export interface EditStudyAbstractDto {
  abstract: string
}

export interface AddImportSourceToPlanDto {
  importSourceId: string
}

export interface CreateAppraisalEntryDto {
  criterionId: string
  gradeId: string
}

export interface CreateOxfordLevelOfEvidenceEntryDto {
  oxfordLevelOfEvidence: 'I' | 'II' | 'III' | 'IV' | 'V'
}

export interface CreateStudyDataExtractionFieldDto {
  name: string
  type: 'TEXT'
}

export interface AddStudyDataExtractionFieldDto {
  field: CreateStudyDataExtractionFieldDto
}

export type Map = object

export interface UpdateStudyDataExtractionDto {
  data: Map
}

export interface DuplicateReviewDto {
  projectId: number
}

export interface UpdateStudyDto {
  title: string
  abstract: string
  reference: string
  doi: string
  publishYear: string
  journal: string
  volumeNumber: string
  issueNumber: string
  pagesNumber: string
  authors: string[]
}

export interface CreateUserDto {
  email: string
  firstName: string
  lastName: string
  password: string
  role: 'ADMIN' | 'USER' | 'READ_ONLY'
}

export interface LoginDto {
  email: string
  password: string
}

export interface UpdateUserFullNameDto {
  firstName: string
  lastName: string
}

export type User = object

export interface UpdateUserStateDto {
  userId: number
}

export interface CreateImportSourceDto {
  shortName: string
  fullName: string
  type:
    | 'REGISTER'
    | 'DATABASE'
    | 'WEBSITE'
    | 'ORGANISATION'
    | 'HAND_SEARCH'
    | 'CITATION_SEARCH'
  format: 'ris' | 'pubmed' | 'harvard-citation' | 'clinical-trials-gov-json'
  description?: string
  url?: string
}

export interface UpdateImportSourceDto {
  shortName?: string
  fullName?: string
  url?: string
  description?: string
}

export interface AnswerDisplayDto {
  id: string
  title?: string
}

export interface AppraisalCriterionDisplayDto {
  id: string
  title?: string
  question?: string
  answers?: AnswerDisplayDto[]
}

export interface AppraisalCriteriaDisplayDto {
  criterion?: AppraisalCriterionDisplayDto
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      } as any,
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      requestParams.headers!.common = { Accept: '*/*' }
      requestParams.headers!.post = {}
      requestParams.headers!.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Systematic review Service
 * @version 1.0.0
 * @contact
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerCreate
     * @request POST:/api/projects
     * @secure
     */
    projectsControllerCreate: (
      data: CreateProjectDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerFind
     * @request GET:/api/projects
     * @secure
     */
    projectsControllerFind: (params: RequestParams = {}) =>
      this.request<DisplayProjectDto[], any>({
        path: `/api/projects`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerCreateReview
     * @request POST:/api/projects/{id}/review
     * @secure
     */
    projectsControllerCreateReview: (
      id: number,
      data: CreateReviewDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects/${id}/review`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerDelete
     * @request DELETE:/api/projects/{projectId}
     * @secure
     */
    projectsControllerDelete: (projectId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerExportRis
     * @request GET:/api/projects/{projectId}/ris/{selectedReviewsIds}
     * @secure
     */
    projectsControllerExportRis: (
      projectId: number,
      selectedReviewsIds: string[],
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/ris/${selectedReviewsIds}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerExportCitationsDocx
     * @request GET:/api/projects/{projectId}/citations/{selectedReviewsIds}
     * @secure
     */
    projectsControllerExportCitationsDocx: (
      projectId: number,
      selectedReviewsIds: string[],
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/citations/${selectedReviewsIds}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerExportStudiesZip
     * @request GET:/api/projects/{projectId}/pdf/{selectedReviewsIds}/studies/zip
     * @secure
     */
    projectsControllerExportStudiesZip: (
      projectId: number,
      selectedReviewsIds: string[],
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/pdf/${selectedReviewsIds}/studies/zip`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateContactForComplaintEmail
     * @request PATCH:/api/projects/{projectId}/contact-for-complaint-email
     * @secure
     */
    projectsControllerUpdateContactForComplaintEmail: (
      projectId: number,
      data: UpdateContactForComplaintEmailDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects/${projectId}/contact-for-complaint-email`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateName
     * @request PATCH:/api/projects/{projectId}/name
     * @secure
     */
    projectsControllerUpdateName: (
      projectId: number,
      data: UpdateProjectNameDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects/${projectId}/name`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerAddUserToProject
     * @request POST:/api/projects/{projectId}/users/{userId}
     * @secure
     */
    projectsControllerAddUserToProject: (
      projectId: number,
      userId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/projects/${projectId}/users/${userId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerFindProjectUsers
     * @request GET:/api/projects/{projectId}/users
     * @secure
     */
    projectsControllerFindProjectUsers: (
      projectId: number,
      params: RequestParams = {}
    ) =>
      this.request<DisplayUserDto[], any>({
        path: `/api/projects/${projectId}/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateCslStyle
     * @request PATCH:/api/projects/{projectId}/csl-style
     * @secure
     */
    projectsControllerUpdateCslStyle: (
      projectId: number,
      data: UpdateProjectCslStyleDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/projects/${projectId}/csl-style`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindById
     * @request GET:/api/Reviews/{reviewId}
     * @secure
     */
    reviewsControllerFindById: (reviewId: number, params: RequestParams = {}) =>
      this.request<DisplayReviewDto, any>({
        path: `/api/Reviews/${reviewId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindSearches
     * @request GET:/api/Reviews/{reviewId}/searches
     * @secure
     */
    reviewsControllerFindSearches: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<DisplaySearchDto[], any>({
        path: `/api/Reviews/${reviewId}/searches`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindStudies
     * @request GET:/api/Reviews/{reviewId}/studies
     * @secure
     */
    reviewsControllerFindStudies: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto[], any>({
        path: `/api/Reviews/${reviewId}/studies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDelete
     * @request DELETE:/api/Reviews/{selectedReviewsIds}
     * @secure
     */
    reviewsControllerDelete: (
      selectedReviewsIds: string[],
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${selectedReviewsIds}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerImportCitationFile
     * @request POST:/api/Reviews/{reviewId}/studies/import
     * @secure
     */
    reviewsControllerImportCitationFile: (
      reviewId: number,
      data: ImportStudiesDto,
      params: RequestParams = {}
    ) =>
      this.request<Study[], any>({
        path: `/api/Reviews/${reviewId}/studies/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSubmitComplaint
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/submit-complaint
     * @secure
     */
    reviewsControllerSubmitComplaint: (
      reviewId: number,
      studyId: number,
      data: Study,
      params: RequestParams = {}
    ) =>
      this.request<Date, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/submit-complaint`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerMarkStudyAsDuplicate
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/mark-as-duplicate
     * @secure
     */
    reviewsControllerMarkStudyAsDuplicate: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Study, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/mark-as-duplicate`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerMarkStudyAsNotDuplicate
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/mark-as-not-duplicate
     * @secure
     */
    reviewsControllerMarkStudyAsNotDuplicate: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Study, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/mark-as-not-duplicate`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetStudyTitleAndAbstractScreening
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/title-and-abstract-screening
     * @secure
     */
    reviewsControllerSetStudyTitleAndAbstractScreening: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Study, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/title-and-abstract-screening`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyTitleAndAbstractScreening
     * @request DELETE:/api/Reviews/{reviewId}/studies/{studyId}/title-and-abstract-screening
     * @secure
     */
    reviewsControllerClearStudyTitleAndAbstractScreening: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Study, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/title-and-abstract-screening`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetStudyFullTextScreening
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/full-text-screening
     * @secure
     */
    reviewsControllerSetStudyFullTextScreening: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Study, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/full-text-screening`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyFullTextScreening
     * @request DELETE:/api/Reviews/{reviewId}/studies/{studyId}/full-text-screening
     * @secure
     */
    reviewsControllerClearStudyFullTextScreening: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Study, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/full-text-screening`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteSearchStudies
     * @request DELETE:/api/Reviews/{reviewId}/search/{searchId}
     * @secure
     */
    reviewsControllerDeleteSearchStudies: (
      reviewId: number,
      searchId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/search/${searchId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerPlanScreening
     * @request PUT:/api/Reviews/{reviewId}/plan-screening
     * @secure
     */
    reviewsControllerPlanScreening: (
      reviewId: number,
      data: PlanScreeningDto,
      params: RequestParams = {}
    ) =>
      this.request<ScreeningPlan, any>({
        path: `/api/Reviews/${reviewId}/plan-screening`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerLockScreeningPlan
     * @request PATCH:/api/Reviews/{reviewId}/lock-screening-plan
     * @secure
     */
    reviewsControllerLockScreeningPlan: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/lock-screening-plan`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFindProject
     * @request GET:/api/Reviews/{reviewId}/project
     * @secure
     */
    reviewsControllerFindProject: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<DisplayProjectDto, any>({
        path: `/api/Reviews/${reviewId}/project`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerFavoriteStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/favorite
     * @secure
     */
    reviewsControllerFavoriteStudy: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/favorite`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUnfavoriteStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/unfavorite
     * @secure
     */
    reviewsControllerUnfavoriteStudy: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/unfavorite`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateName
     * @request PATCH:/api/Reviews/{reviewId}/name
     * @secure
     */
    reviewsControllerUpdateName: (
      reviewId: number,
      data: UpdateReviewNameDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayReviewDto, any>({
        path: `/api/Reviews/${reviewId}/name`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUploadStudyPdfFile
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/upload-pdf
     * @secure
     */
    reviewsControllerUploadStudyPdfFile: (
      reviewId: number,
      studyId: number,
      data: UploadFileDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/upload-pdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerGetStudyPdfFile
     * @request GET:/api/Reviews/{reviewId}/studies/{studyId}/pdf
     * @secure
     */
    reviewsControllerGetStudyPdfFile: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/pdf`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDownloadCitationFile
     * @request GET:/api/Reviews/{reviewId}/searches/{searchId}/citation-file
     * @secure
     */
    reviewsControllerDownloadCitationFile: (
      reviewId: number,
      searchId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/searches/${searchId}/citation-file`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerExportStudiesZip
     * @request GET:/api/Reviews/{reviewId}/studies/zip
     * @secure
     */
    reviewsControllerExportStudiesZip: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/zip`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDeleteStudiesPdfFile
     * @request DELETE:/api/Reviews/{reviewId}/studies/{studyId}/pdf-file
     * @secure
     */
    reviewsControllerDeleteStudiesPdfFile: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/pdf-file`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEditStudyAbstract
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/abstract
     * @secure
     */
    reviewsControllerEditStudyAbstract: (
      reviewId: number,
      studyId: number,
      data: EditStudyAbstractDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/abstract`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddImportSourceToPlan
     * @request PUT:/api/Reviews/{reviewId}/import-plan/import-source
     * @secure
     */
    reviewsControllerAddImportSourceToPlan: (
      reviewId: number,
      data: AddImportSourceToPlanDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/import-plan/import-source`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerRemoveImportSourceFromPlan
     * @request DELETE:/api/Reviews/{reviewId}/import-plan/import-source
     * @secure
     */
    reviewsControllerRemoveImportSourceFromPlan: (
      reviewId: number,
      data: AddImportSourceToPlanDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/import-plan/import-source`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnableStudiesAppraisalImdrfMdce2019
     * @request PATCH:/api/Reviews/{reviewId}/enable-studies-appraisal
     * @secure
     */
    reviewsControllerEnableStudiesAppraisalImdrfMdce2019: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enable-studies-appraisal`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisableStudiesAppraisal
     * @request PATCH:/api/Reviews/{reviewId}/disable-studies-appraisal
     * @secure
     */
    reviewsControllerDisableStudiesAppraisal: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disable-studies-appraisal`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerEnableStudiesAppraisalOxfordLevelOfEvidence
     * @request PATCH:/api/Reviews/{reviewId}/enable-studies-appraisal-oxford-level-of-evidence
     * @secure
     */
    reviewsControllerEnableStudiesAppraisalOxfordLevelOfEvidence: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/enable-studies-appraisal-oxford-level-of-evidence`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDisableStudiesAppraisalOxfordLevelOfEvidence
     * @request PATCH:/api/Reviews/{reviewId}/disable-studies-appraisal-oxford-level-of-evidence
     * @secure
     */
    reviewsControllerDisableStudiesAppraisalOxfordLevelOfEvidence: (
      reviewId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/disable-studies-appraisal-oxford-level-of-evidence`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAppraiseStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/appraise
     * @secure
     */
    reviewsControllerAppraiseStudy: (
      reviewId: number,
      studyId: number,
      data: CreateAppraisalEntryDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/appraise`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerSetOxfordLevelOfEvidenceStudy
     * @request POST:/api/Reviews/{reviewId}/studies/{studyId}/setOxfordLevelOfEvidence
     * @secure
     */
    reviewsControllerSetOxfordLevelOfEvidenceStudy: (
      reviewId: number,
      studyId: number,
      data: CreateOxfordLevelOfEvidenceEntryDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/setOxfordLevelOfEvidence`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyOxfordLevelOfEvidence
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/resetOxfordLevelOfEvidence
     * @secure
     */
    reviewsControllerClearStudyOxfordLevelOfEvidence: (
      reviewId: number,
      studyId: number,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/resetOxfordLevelOfEvidence`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerClearStudyAppraisal
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/clearAppraisal/{criterionId}
     * @secure
     */
    reviewsControllerClearStudyAppraisal: (
      reviewId: number,
      studyId: number,
      criterionId: string,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/clearAppraisal/${criterionId}`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerAddStudyDataExtractionField
     * @request POST:/api/Reviews/{reviewId}/study-data-extraction-field
     * @secure
     */
    reviewsControllerAddStudyDataExtractionField: (
      reviewId: number,
      data: AddStudyDataExtractionFieldDto,
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/api/Reviews/${reviewId}/study-data-extraction-field`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateStudyDataExtraction
     * @request PATCH:/api/Reviews/{reviewId}/{studyId}/study-data-extraction-field
     * @secure
     */
    reviewsControllerUpdateStudyDataExtraction: (
      reviewId: number,
      studyId: number,
      data: UpdateStudyDataExtractionDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/${studyId}/study-data-extraction-field`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerDuplicateReview
     * @request POST:/api/Reviews/{reviewId}/duplicate
     * @secure
     */
    reviewsControllerDuplicateReview: (
      reviewId: number,
      data: DuplicateReviewDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/Reviews/${reviewId}/duplicate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerUpdateStudy
     * @request PATCH:/api/Reviews/{reviewId}/studies/{studyId}/update
     * @secure
     */
    reviewsControllerUpdateStudy: (
      reviewId: number,
      studyId: number,
      data: UpdateStudyDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayStudyDto, any>({
        path: `/api/Reviews/${reviewId}/studies/${studyId}/update`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name ReviewsControllerIsCitationFileAlreadyImported
     * @request GET:/api/Reviews/{reviewId}/is-citation-file-already-imported/{checksum}
     * @secure
     */
    reviewsControllerIsCitationFileAlreadyImported: (
      reviewId: number,
      checksum: string,
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/api/Reviews/${reviewId}/is-citation-file-already-imported/${checksum}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerCreate
     * @request POST:/api/users
     * @secure
     */
    usersControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<DisplayUserDto, any>({
        path: `/api/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerFind
     * @request GET:/api/users
     * @secure
     */
    usersControllerFind: (params: RequestParams = {}) =>
      this.request<DisplayUserDto[], any>({
        path: `/api/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerFindCurrentUser
     * @request GET:/api/users/me
     * @secure
     */
    usersControllerFindCurrentUser: (params: RequestParams = {}) =>
      this.request<DisplayUserDto, any>({
        path: `/api/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerLogin
     * @request POST:/api/users/login
     */
    usersControllerLogin: (data: LoginDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/users/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdateCurrentUserFullName
     * @request PATCH:/api/users/updateFullName
     * @secure
     */
    usersControllerUpdateCurrentUserFullName: (
      data: UpdateUserFullNameDto,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/api/users/updateFullName`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerEnableUser
     * @request PATCH:/api/users/enableUser
     * @secure
     */
    usersControllerEnableUser: (
      data: UpdateUserStateDto,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/api/users/enableUser`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerDisableUser
     * @request PATCH:/api/users/disableUser
     * @secure
     */
    usersControllerDisableUser: (
      data: UpdateUserStateDto,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/api/users/disableUser`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CslStyles
     * @name CslStylesControllerFindCslStyles
     * @request GET:/api/csl-styles
     * @secure
     */
    cslStylesControllerFindCslStyles: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/csl-styles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportSources
     * @name ImportSourcesControllerFind
     * @request GET:/api/import-sources
     * @secure
     */
    importSourcesControllerFind: (params: RequestParams = {}) =>
      this.request<DisplayImportSourceDto[], any>({
        path: `/api/import-sources`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportSources
     * @name ImportSourcesControllerCreate
     * @request POST:/api/import-sources
     * @secure
     */
    importSourcesControllerCreate: (
      data: CreateImportSourceDto,
      params: RequestParams = {}
    ) =>
      this.request<DisplayImportSourceDto, any>({
        path: `/api/import-sources`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportSources
     * @name ImportSourcesControllerUpdate
     * @request PATCH:/api/import-sources/{id}
     * @secure
     */
    importSourcesControllerUpdate: (
      id: string,
      data: UpdateImportSourceDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/import-sources/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppraisalCriteria
     * @name AppraisalCriteriaControllerFind
     * @request GET:/api/appraisal-criteria
     * @secure
     */
    appraisalCriteriaControllerFind: (params: RequestParams = {}) =>
      this.request<AppraisalCriteriaDisplayDto[], any>({
        path: `/api/appraisal-criteria`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
