import { CitationFileFormat } from '../types/import-file-format.type'
import { ImportSourceType } from '../types/import-source-type.type'

export class ImportSource {
  id: string
  type: ImportSourceType
  shortName: string
  fullName: string
  format: CitationFileFormat
  description?: string
  url?: string

  constructor(data: ImportSource) {
    this.id = data.id
    this.type = data.type
    this.shortName = data.shortName
    this.fullName = data.fullName
    this.description = data.description
    this.url = data.url
    this.format = data.format
  }
}
