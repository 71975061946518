import { Id } from '@core/domain/types/id.type'
import { getItem, setItem } from 'localforage'
import { computed, ref } from 'vue'

const isStudiesAutoScrollEnabledInternalValue = ref<boolean>()
const isStudiesSmoothScrollEnabledInternalValue = ref<boolean>()
const dateFormatValue = ref<string>('dd/MM/yyyy')
const favoriteProjectsValue = ref<Id[]>()

export function useSettings() {
  const isStudiesAutoScrollEnabled = computed({
    get: () => isStudiesAutoScrollEnabledInternalValue.value,
    set: async (v) => {
      await setItem('isStudiesAutoScrollEnabled', v)
      isStudiesAutoScrollEnabledInternalValue.value = v
    },
  })
  const favoriteProjects = computed({
    get: () => favoriteProjectsValue.value,
    set: async (v) => {
      await setItem('favoriteProjects', v)
      favoriteProjectsValue.value = v
    },
  })

  const isStudiesSmoothScrollEnabled = computed({
    get: () => isStudiesSmoothScrollEnabledInternalValue.value,
    set: async (v) => {
      await setItem('isStudiesSmoothScrollEnabled', v)
      isStudiesSmoothScrollEnabledInternalValue.value = v
    },
  })
  const dateFormat = computed({
    get: () => dateFormatValue.value,
    set: async (v) => {
      await setItem('dateFormat', v)
      dateFormatValue.value = v
    },
  })
  function addTofavoriteProjects(projectId: Id) {
    favoriteProjects.value = favoriteProjects.value?.concat(projectId)
  }

  function removeFromfavoriteProjects(projectId: Id) {
    favoriteProjects.value = favoriteProjects.value?.filter(
      (p) => p != projectId
    )
  }
  async function init() {
    isStudiesAutoScrollEnabled.value =
      (await getItem<boolean>('isStudiesAutoScrollEnabled')) ?? true
    isStudiesSmoothScrollEnabled.value =
      (await getItem<boolean>('isStudiesSmoothScrollEnabled')) ?? true
    dateFormat.value =
      (await getItem<string>('dateFormat')) ?? dateFormatValue.value
    favoriteProjectsValue.value =
      (await getItem<number[]>('favoriteProjects')) ?? []
  }
  return {
    addProjectTofavorites: addTofavoriteProjects,
    removeProjectFromfavorites: removeFromfavoriteProjects,
    favoriteProjects,
    isStudiesAutoScrollEnabled,
    isStudiesSmoothScrollEnabled,
    dateFormat,
    init,
  }
}
