import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from './views/Login.vue'
import useAuth from './composables/use-auth'
import { Role } from '@core/domain/types/role.type'
import ProjectsVue from './views/Projects/Projects.vue'
import HomeIconVue from './components/Icons/HomeIcon.vue'
import UsersVue from './views/Users.vue'
import ImportSourcesVue from './views/ImportSources.vue'
import Changelog from './views/Changelog.vue'
import UsersIconVue from './components/Icons/UsersIcon.vue'
import DatabaseIconVue from './components/Icons/DatabaseIcon.vue'
import FolderIconVue from './components/Icons/FolderIcon.vue'
import DocumentTextIcon from './components/Icons/DocumentTextIcon.vue'
import Prototype from './views/Prototype/Prototype.vue'
import ConditionalLogicBuilder from './views/ConditionalLogicBuilder/ConditionalLogicBuilder.vue'

export enum RouteName {
  HOME = 'home',
  PROJECTS = 'projects',
  USERS = 'users',
  IMPORT_SOURCES = 'import-sources',
  FIELDS = 'fields',
  CHANGELOG = 'changelog',
}

export interface NagivationEntry {
  to: string
  roles: Role[]
  name: RouteName
  component: any
  icon: any
  text: string
  isCuttingEdge?: boolean
}
export const navigation: NagivationEntry[] = [
  {
    to: '/',
    component: ProjectsVue,
    roles: [],
    name: RouteName.HOME,
    icon: HomeIconVue,
    text: 'Home',
  },
  {
    to: '/projects',
    component: ProjectsVue,
    roles: [],
    name: RouteName.PROJECTS,
    icon: FolderIconVue,
    text: 'Projects',
  },
  {
    to: '/users',
    component: UsersVue,
    roles: [Role.ADMIN],
    name: RouteName.USERS,
    icon: UsersIconVue,
    text: 'Users',
  },
  {
    to: '/import-sources',
    component: ImportSourcesVue,
    roles: [Role.ADMIN],
    name: RouteName.IMPORT_SOURCES,
    icon: DatabaseIconVue,
    text: 'Sources',
  },

  {
    to: '/changelog',
    component: Changelog,
    roles: [],
    name: RouteName.CHANGELOG,
    icon: DocumentTextIcon,
    text: 'Changelog',
  },
]

const auth = useAuth()

export function userCanAccess(routeName: string): boolean {
  const route = navigation.find((r) => r.name === routeName)
  if (!route) throw new Error('incorrect route name')
  if (route.roles.length <= 0) return true
  if (!auth.user.value) return false
  return route.roles.includes(auth.user.value.role)
}

const Review = () => import('./views/AsyncReview.vue')
const routes: RouteRecordRaw[] = [
  {
    path: '/reviews/:id',
    component: Review,
    name: 'review',
  },
  {
    path: '/proto',
    component: Prototype,
    name: 'prototype',
  },
  {
    path: '/logic-builder',
    component: ConditionalLogicBuilder,
    name: 'conditionalLogic',
  },
  { path: '/login', component: Login, name: 'login' },
  ...navigation.map((n) => ({
    path: n.to,
    component: n.component,
    name: n.name,
  })),
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (auth.user.value && to.path === '/login') return next('/')
  if (to.path === '/login') return next()
  if (!auth.user.value) return next('/login')
  const route = navigation.find((r) => r.name === to.name)
  if (!route) return next()
  if (route.roles.length > 0 && !route.roles.includes(auth.user.value.role))
    return next('/403')
  return next()
})
