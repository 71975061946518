<template>
  <FloatingMenu
    placement="bottom"
    ref="floatingMenu"
    :offset="[0, -10]"
    @hide="reset"
  >
    <template #activator>
      <div
        @click="openMenu"
        v-tooltip="'Add project member'"
        class="pt-1 pb-4 px-1 flex gap-1 items-center hover:cursor-pointer hover:brightness-110 text-primary"
      >
        <UsersIcon class="fill-primary w-5 h-5" />
        <p
          class="font-medium text-base leading-4 tracking-tight whitespace-nowrap"
        >
          Manage Users
        </p>
        <ChevronDownIcon
          class="w-4 h-4 stroke-[3] transition-all"
          :class="{
            'rotate-180': isShown,
          }"
        />
      </div>
    </template>
    <template #content>
      <div
        class="w-[620px] p-2 absolute bg-white space-y-2 border bored border-[#D9E4EA] dark:bg-[#103047]"
      >
        <GenericAutoComplete
          :items="remainingUsers"
          :item-options="{
            displayProperty: 'email',
            filterProperties: ['email', 'firstName', 'lastName'],
            valueProperty: 'id',
          }"
          v-model="selected"
          placeholder="Select a user to add as member"
          @update:model-value="addUser"
        >
          <template #item="{ firstName, lastName, email }">
            {{ email }}
            {{ firstName && lastName ? `(${firstName} ${lastName})` : '' }}
          </template>
        </GenericAutoComplete>

        <div class="space-y-2">
          <Scrollbar class="w-full max-h-[110px] pr-3">
            <div
              v-if="projectMembers.length > 0"
              class="flex justify-between items-center py-2 pl-1.5 pr-3 rounded-lg text-primary hover:bg-[#ECEDEE] dark:text-white"
              v-for="(user, index) in projectMembers"
              :key="user.id"
            >
              <div class="flex items-center gap-1.5">
                <div
                  class="bg-gray-300 w-6 h-6 rounded-full text-primary text-sm items-center flex justify-center"
                >
                  {{ (user.fullName[0] || user.email[0]).toUpperCase() }}
                </div>
                <span class="font-medium text-sm">{{
                  user.fullName || user.email
                }}</span>
              </div>
              <div @click.stop="" class="flex items-center gap-1.5">
                <span class="font-medium text-sm">Manager</span>
                <Switch
                  :model-value="user.isManager"
                  size=""
                  @update:model-value="(v: boolean) => toggleManager(user.id, v)"
                  @click.stop=""
                />
              </div>
            </div>
            <div v-else class="py-2 pl-1.5 pr-3 text-gray-500 text-sm">
              No items found.
            </div>
          </Scrollbar>
        </div>
      </div>
    </template>
  </FloatingMenu>
</template>

<script setup lang="ts">
import useUsers from '@app/composables/use-users'
import projectPlan from '../projectPlan'

import { computed, ref } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import Switch from '@app/components/Global/Switch.vue'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import UsersIcon from '@app/components/Icons/UsersIcon.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import { Id } from '@core/domain/types/id.type'

const props = defineProps<{
  projectId: Id
}>()

const users = useUsers()
const floatingMenu = ref()
const isShown = ref(false)

async function openMenu() {
  isShown.value = true

  floatingMenu?.value?.show()
}
const {
  memberProjectReviewRolesAssignment,
  addUserToProject,
  promoteToManager,
  demoteFromManager,
} = projectPlan()

const projectMembers = computed(() => {
  return (
    memberProjectReviewRolesAssignment.value?.find(
      (p) => p.projectId === props.projectId
    )?.memberAssignment.members ?? []
  )
})

const selected = ref(0)

function reset() {
  isShown.value = false
}

function addUser(userId: number) {
  selected.value = 0
  const user = users.users.value.find((u) => u.id === userId)
  if (user) {
    const isUserAlreadyInProject = projectMembers?.value?.some(
      (u) => u.id === user.id
    )
    if (!isUserAlreadyInProject) addUserToProject(user, props.projectId)
  }
}

const remainingUsers = computed(() =>
  users.users.value.filter(
    (u) => !projectMembers?.value?.some((m) => m.id === u.id)
  )
)

function toggleManager(userId: number, isManager: boolean) {
  if (isManager) promoteToManager(userId, props.projectId)
  else demoteFromManager(userId, props.projectId)
}
</script>
