<template>
  <div class="h-full w-full flex overflow-hidden">
    <Scrollbar class="w-full px-8 py-4">
      <div class="p-12 space-y-6">
        <div class="p-6 space-y-4 bg-[#F2F6F8] w-[857px] rounded-2xl">
          <p class="text-blue-cyan font-semibold text-lg leading-7">
            Project details
          </p>
          <NewAsyncTextInput
            label="Project name"
            :value="internalProjectName"
            @update="updateProjectName(project.id!, $event)"
            type="text"
            ref="projectNameInput"
          />

          <NewAsyncTextInput
            label="Contact for complaint"
            :value="internalContactForComplaint"
            @update="updateSupevisorEmail(project.id!, $event)"
            type="text"
            ref="contactForComplaintInput"
          />

          <div class="bg-white border rounded-lg border-primary/40">
            <div class="px-5 py-4">
              <label
                class="block text-sm font-bold mb-1 text-primary dark:text-white"
              >
                Citation style
              </label>
              <GenericAutoComplete
                :items="cslStyleListItems"
                :item-options="{
                  displayProperty: 'text',
                  filterProperties: ['text'],
                  valueProperty: 'value',
                }"
                v-model="selectedCslStyle"
                placeholder="Select Citation style"
                @update:model-value="updateCslStyle"
              >
                <template #item="{ text }">
                  {{ text }}
                </template>
              </GenericAutoComplete>
            </div>
          </div>
        </div>

        <div class="p-6 space-y-4 bg-[#F2F6F8] w-[857px] rounded-2xl">
          <div class="flex justify-between">
            <div class="flex items-center gap-2">
              <TrashIcon class="w-5 h-5 text-red-500" />
              <p class="text-primary font-semibold text-lg leading-none">
                Delete project
              </p>
            </div>
            <button
              :class="{
                'opacity-40 cursor-not-allowed':
                  deleteProjectConfirmation === CheckboxState.uncheked,
              }"
              v-tooltip="
                deleteProjectConfirmation === CheckboxState.uncheked
                  ? 'Please check the box to confirm project deletion'
                  : ''
              "
              @click.stop="deleteProject(project.id!)"
              class="bg-red-500 text-white px-4 py-2 hover:brightness-95 rounded-lg"
            >
              Delete Project
            </button>
          </div>
          <div class="bg-[#E1EAEE] p-4 rounded-lg">
            <div class="flex gap-2">
              <ExclamationCircleIcon class="w-5 h-5 text-red-500" />
              <div>
                <p class="text-primary font-bold text-sm leading-[150%]">
                  You are deleting this project
                </p>
                <span class="text-primary/80 font-normal text-xs leading-[150%]"
                  >This action deletes the project {{ project.name }} and
                  everything it contains. There is no going back.</span
                >
              </div>
            </div>
          </div>
          <div class="flex gap-2 items-center">
            <button @click.stop="toggleDeleteProjectConfirmation">
              <Checkbox
                class="w-5 h-5"
                v-model="deleteProjectConfirmation"
              ></Checkbox>
            </button>
            <p class="text-primary">I confirm project deletion</p>
          </div>
        </div>
      </div>
    </Scrollbar>
  </div>
</template>

<script setup lang="ts">
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useCslStyles from '@app/composables/use-cslStyles'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SelectListItem, SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { HttpException } from '@core/exceptions/http.exception'
import { hideAll } from 'tippy.js'
import { computed, ref } from 'vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import ExclamationCircleIcon from '@app/components/Icons/ExclamationCircleIcon.vue'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import NewAsyncTextInput from '../../NewInputs/NewAsyncTextInput.vue'

const props = defineProps<{
  project: Project
}>()

const deleteProjectConfirmation = ref<CheckboxState>(CheckboxState.uncheked)

const projectNameInput = ref<InstanceType<typeof NewAsyncTextInput>>()
const contactForComplaintInput = ref<InstanceType<typeof NewAsyncTextInput>>()

const cslStyles = useCslStyles()

const cslStyleListItems = computed<SelectListItem[]>(() =>
  cslStyles.list.value.map((s) => {
    const text = s.charAt(0).toUpperCase() + s.replaceAll('-', ' ').slice(1)
    return { text: text.replaceAll('-', ' '), value: s }
  })
)

const internalProjectName = ref(props.project.name ?? '')
const internalContactForComplaint = ref(
  props.project.contactForComplaintEmail ?? ''
)

const selectedCslStyle = ref(props.project.cslStyle)

const loading = useLoading()
const snackbar = useSnackbar()
const projects = useProjects()

async function updateProjectName(projectId: Id, name: string): Promise<void> {
  try {
    projectNameInput.value?.startLoading()
    await projects.updateName(projectId, name)
    internalProjectName.value = name
    projectNameInput.value?.displaySuccess()
  } catch (e) {
    const error = e as HttpException
  } finally {
    projectNameInput.value?.stopLoading()
  }
}

async function updateSupevisorEmail(
  projectId: Id,
  email: string
): Promise<void> {
  try {
    contactForComplaintInput.value?.startLoading()
    await projects.updateSupevisorEmail(projectId, email)
    contactForComplaintInput.value?.displaySuccess()
    internalContactForComplaint.value = email
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    contactForComplaintInput.value?.stopLoading()
  }
}

async function updateCslStyle(style: string) {
  try {
    loading.start()
    selectedCslStyle.value = style
    await projects.updateCslStyle(props.project.id!, style)
    snackbar.show(SnackbarState.SUCCESS, 'Citation style updated')
  } catch (e) {
    const error = e as HttpException
    selectedCslStyle.value = props.project.cslStyle
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function deleteProject(projectId: Id) {
  if (deleteProjectConfirmation.value === CheckboxState.checked) {
    loading.start()
    await projects.deleteProject(projectId)
    loading.stop()
    hideAll()
    deleteProjectConfirmation.value = CheckboxState.uncheked
  }
}

function toggleDeleteProjectConfirmation() {
  if (deleteProjectConfirmation.value === CheckboxState.checked)
    deleteProjectConfirmation.value = CheckboxState.uncheked
  else deleteProjectConfirmation.value = CheckboxState.checked
}
</script>
