<template>
  <Modal dismissible rounded="rounded-2xl">
    <template
      #activator="{ show }"
      v-if="auth.user.value?.role !== Role.READ_ONLY"
    >
      <button
        @click="show"
        class="bg-blue-cyan text-white font-bold py-3 px-6 rounded-lg inline-flex gap-2 items-center h-11"
      >
        <CreateProjectIcon class="w-6 h-6" />

        <span>Create project</span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[548px] h-[310px] mt-6">
        <div class="flex justify-end items-end px-6">
          <button @click="hide" class="">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="space-y-6">
          <div class="flex flex-col justify-center items-center gap-2">
            <CreateProjectIcon class="w-10 h-10 text-blue-cyan" />
            <p
              class="text-primary text-2xl font-bold leading-6 tracking[-0.4px]"
            >
              Create project
            </p>
          </div>
          <div class="px-16 space-y-3">
            <TextInput
              label="Project name"
              placeholder="Enter project name"
              type="text"
              v-model="projectName"
            />

            <div class="flex justify-between">
              <button
                @click="hide"
                class="w-[191.55px] bg-white text-primary border border-primary rounded-lg text-base font-medium py-3 px-10 hover:brightness-110"
              >
                Cancel
              </button>
              <button
                @click="createProject(hide)"
                class="w-[191.55px] bg-blue-cyan text-white rounded-lg text-base font-medium py-3 px-10 hover:brightness-110"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import useProjects from '@app/views/Projects/use-projects'
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { HttpException } from '@core/exceptions/http.exception'
import useAuth from '@app/composables/use-auth'
import { Role } from '@core/domain/types/role.type'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import CreateProjectIcon from '@app/components/Icons/CreateProjectIcon.vue'

const auth = useAuth()
const projects = useProjects()
const snackbar = useSnackbar()
const loading = useLoading()
const projectName = ref('')

async function createProject(cb: () => void = () => {}) {
  if (!projectName.value) return

  try {
    loading.start()
    await projects.create({ name: projectName.value })

    snackbar.show(SnackbarState.SUCCESS, 'Project createed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    projectName.value = ''
    loading.stop()
    cb()
  }
}
</script>
