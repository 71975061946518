<template>
  <div class="flex border-b-2 col-auto">
    <button
      @click="activeStep = step.number"
      class="px-12 py-6 flex items-center gap-2 border-t-2 border-r-2 border-gray-100 dark:border-white/5 dark:text-white text-blue-cyan"
      :class="{
        'is-active-tab': step.number === activeStep,
      }"
      v-for="step in steps"
    >
      <div
        v-if="showStepNumber"
        class="w-12 h-12 flex items-center justify-center rounded-full border-2 border-blue-cyan text-blue-cyan dark:border-white dark:text-white text-lg"
      >
        {{ step.number }}
      </div>
      <div class="flex items-start flex-col">
        <div class="text-blue-cyan dark:text-white">{{ step.title }}</div>
        <div class="text-gray-500">{{ step.subtitle }}</div>
      </div>
    </button>
  </div>

  <template v-for="step in steps">
    <slot v-if="internalValue === step.number" :name="`step${step.number}`" />
  </template>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'

const props = withDefaults(
  defineProps<{
    steps: { number: number; title?: string; subtitle?: string }[]
    modelValue?: number
    showStepNumber?: boolean
  }>(),
  {
    modelValue: 1,
    showStepNumber: true,
  }
)

const internalValue = ref<number>(props.modelValue)

watchEffect(() => {
  internalValue.value = props.modelValue
})

const emit = defineEmits<(e: 'update:modelValue', v: number) => void>()

const activeStep = computed({
  get: () => internalValue.value,
  set: (v) => {
    internalValue.value = v
    emit('update:modelValue', v)
  },
})
</script>
<style>
.is-active-tab {
  position: relative;
  border-bottom: 0;
}
.is-active-tab::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: currentColor;
}
</style>
