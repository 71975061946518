<template>
  <div
    @click=""
    class="border rounded-sm w-4 h-4 border-primary hover:cursor-pointer flex items-center justify-center stroke-[4px]"
    :class="{
      'bg-blue-500':
        modelValue === CheckboxState.checked ||
        modelValue === CheckboxState.undetermined,
    }"
  >
    <CheckIcon
      class="w-4 h-4 stroke-white"
      v-if="modelValue === CheckboxState.checked"
    />

    <div
      class="w-3 h-0.5 bg-white"
      v-if="modelValue === CheckboxState.undetermined"
    />
  </div>
</template>

<script lang="ts">
import CheckIcon from '@app/components/Icons/CheckIcon.vue'
export enum CheckboxState {
  checked,
  uncheked,
  undetermined,
}
</script>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue: CheckboxState
  }>(),
  {
    modelValue: CheckboxState.uncheked,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', v: CheckboxState): void
}>()
</script>
