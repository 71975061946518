const isCuttingEdge =
  window.location.host === 'localhost:4000' ||
  window.location.host === 'evidence.beirdo.dev'
if (isCuttingEdge) {
  console.log('cutting edge')
}
export default () => {
  return {
    isCuttingEdge,
  }
}
