<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="flex px-8 border-b flex-col gap-6 w-full sticky top-0 z-50">
      <div class="dark:bg-[#103047] bg-white z-50 space-y-6 py-4">
        <h2 class="text-lg font-bold text-primary dark:text-white">
          Role Assignment for Team Members
        </h2>
        <div class="flex flex-col gap-4 bg-white dark:bg-[#103047]">
          <div class="flex items-center gap-4">
            <ProjectMembers :project-id="project.id!" />

            <div class="w-full">
              <Scrollbar class="pb-3.5">
                <div class="w-full flex gap-4">
                  <template v-for="(user, index) in projectMembers?.members">
                    <Draggable
                      :id="user.id.toString()"
                      @update:is-dragged="
                        updateDraggedItem(
                          user.id.toString(),
                          DraggedItemType.member,
                          $event
                        )
                      "
                      v-slot="{ isDragged }"
                    >
                      <div
                        class="rounded-full dark:text-white text-primary text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                        :class="{
                          'border-white text-white': isDragged,
                          'border-transparent': !isDragged,
                        }"
                        :style="{  'background-color' : `${usersColors[user.id!]}40`}"
                      >
                        <div
                          class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                          :style="{ backgroundColor: usersColors[user.id!] }"
                        >
                          <p class="h-3 w-3 font-medium leading-3">
                            {{
                              (user.fullName[0] || user.email[0]).toUpperCase()
                            }}
                          </p>
                        </div>
                        <div class="leading-none">
                          {{ user.fullName || user.email }}
                        </div>
                        <div class="w-5 h-5 flex items-center justify-center">
                          <button
                            :class="{
                              'opacity-50 cursor-not-allowed': !canDeleteMember(
                                user.id
                              ),
                            }"
                            v-tooltip="
                              !canDeleteMember(user.id)
                                ? 'Cannot remove member. Assigned in some reviews.'
                                : ''
                            "
                            class="hover:bg-transparent"
                            @click.stop="removeMember(user.id, project.id!)"
                          >
                            <XMarkIcon class="h-[16.5px]" />
                          </button>
                        </div>
                      </div>
                    </Draggable>
                  </template>
                </div>
              </Scrollbar>
            </div>
          </div>

          <div class="flex items-center gap-4">
            <ProjectExternalMembers :project-id="project.id!" />
            <div class="w-full">
              <Scrollbar class="pb-3.5">
                <div class="flex gap-4">
                  <template
                    v-for="externalMember in projectMembers?.externalMembers"
                  >
                    <Draggable
                      :id="externalMember.id"
                      @update:is-dragged="
                        updateDraggedItem(
                          externalMember.id,
                          DraggedItemType.externalMember,
                          $event
                        )
                      "
                      v-slot="{ isDragged }"
                    >
                      <div
                        class="bg-[#D8DCDE] rounded-full text-primary text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                        :class="{
                          'border-white text-white': isDragged,
                          'border-transparent': !isDragged,
                        }"
                      >
                        <div
                          class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center bg-[#04517066]"
                        >
                          <p class="h-3 w-3 font-medium leading-3 text-white">
                            {{ externalMember.name[0].toUpperCase() }}
                          </p>
                        </div>

                        <div class="leading-none">
                          {{ externalMember.name }}
                        </div>
                        <div class="w-5 h-5 flex items-center justify-center">
                          <button
                            class="hover:bg-transparent"
                            @click.stop="
                              removeExternalMember(
                                externalMember.id,
                                project.id!
                              )
                            "
                            :class="{
                              'opacity-50 cursor-not-allowed':
                                !canDeleteExternalMember(externalMember.id),
                            }"
                            v-tooltip="
                              !canDeleteExternalMember(externalMember.id)
                                ? 'Cannot remove external Member. This member is assigned as an external approver in some reviews.'
                                : ''
                            "
                          >
                            <XMarkIcon class="h-[16.5px]" />
                          </button>
                        </div>
                      </div>
                    </Draggable>
                  </template>
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-y-hidden">
      <div class="h-full w-full flex">
        <Scrollbar class="w-full px-8 py-4 h-full">
          <h2 class="text-lg pb-4 font-bold text-blue-cyan dark:text-white">
            Assign members to reviews
          </h2>
          <div class="flex flex-wrap gap-8 pb-8">
            <div
              class="flex flex-col gap-4 p-4 bg-[#F2F6F8] dark:bg-faded/20 w-[284px] border dark:border-0 rounded border-[#D9E4EA]"
              v-for="review in project.reviews"
            >
              <div class="truncate text-primary dark:text-white">
                {{ review.name }}
              </div>
              <div class="space-y-4">
                <div>
                  <MemberAddAssignMenu
                    :project-id="props.project.id!"
                    :review-id="review.id"
                    :role="ReviewRoles.AUTHOR"
                  >
                    <template #activator="{ show }">
                      <div
                        class="flex flex-col justify-stretch border px-2 py-4 rounded relative bg-white dark:bg-faded/20 dark:border-0 border-[#CCCCCC] text-primary dark:text-white"
                        :class="{ 'border-transparent': draggedItem.id }"
                        @click="show"
                      >
                        <div class="flex justify-start gap-1 items-center mb-4">
                          <MiniUserIcon class="w-5 h-5 fill-[#82A8B8]" />
                          <p class="font-medium text-sm">Authors</p>
                        </div>

                        <div class="flex flex-wrap justify-start gap-2">
                          <div
                            v-for="(user, index) in reviewsWithAssignedUsers?.[
                              review.id
                            ]?.authors ?? []"
                            v-tooltip="user.email"
                          >
                            <div
                              class="max-w-fit rounded-full text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                              :style="{  'background-color' : `${usersColors[user.id!]}40`}"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                                :style="{ backgroundColor: usersColors[user.id!] }"
                              >
                                <p class="h-3 w-3 font-medium leading-3">
                                  {{ user.email[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[48px]">
                                {{ user.email }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeAuthor(
                                      review.id,
                                      user.id!,
                                      props.project.id!
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <template v-if="draggedItem.id">
                          <div
                            v-if="
                        isMemberAlreadyAssignedToAuthors(
                         parseInt(draggedItem.id),
                          review.id,props.project.id!
                        ) || draggedItem.type !== DraggedItemType.member
                      "
                            class="absolute top-0 left-0 w-full h-full rounded bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />
                          <DropZone
                            @dropped="
                              addAuthorToReview(
                                review.id,
                                parseInt($event),
                                props.project.id!
                              )
                            "
                            v-slot="{ isDraggedOver }"
                            v-else
                          >
                            <div
                              class="absolute rounded top-0 left-0 w-full h-full flex items-start justify-end px-2 py-4"
                              :class="{
                                'bg-[#45A91E]/70 ': isDraggedOver,
                                'border border-dashed border-[#45A91E]':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                class="flex justify-start gap-1 items-center text-white pointer-events-none"
                                v-if="isDraggedOver"
                              >
                                <UserPlusIcon class="w-4 h-4 fill-white" />
                                <p
                                  class="font-medium text-sm text-center text-white"
                                >
                                  Assign Author
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                      </div></template
                    >
                  </MemberAddAssignMenu>
                </div>
                <div>
                  <MemberAddAssignMenu
                    :project-id="props.project.id!"
                    :review-id="review.id"
                    :role="ReviewRoles.REVIEWER"
                  >
                    <template #activator="{ show }">
                      <div
                        class="flex flex-col justify-stretch border px-2 py-4 relative bg-white border-[#CCCCCC] dark:bg-faded/20 dark:border-0 text-primary dark:text-white"
                        :class="{ 'border-transparent': draggedItem.id }"
                        @click="show"
                      >
                        <div class="flex justify-start gap-1 items-center mb-4">
                          <MiniEyeIcon class="w-5 h-5 fill-[#82A8B8]" />
                          <p class="font-medium text-sm">Reviewer</p>
                        </div>

                        <div class="flex flex-wrap justify-start gap-2">
                          <div
                            v-for="user in reviewsWithAssignedUsers?.[review.id]
                              ?.reviewers ?? []"
                            v-tooltip="user.email"
                          >
                            <div
                              class="max-w-fit rounded-full text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                              :style="{  'background-color' : `${usersColors[user.id!]}40`}"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                                :style="{ backgroundColor: usersColors[user.id!] }"
                              >
                                <p class="h-3 w-3 font-medium leading-3">
                                  {{ user.email[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[50px]">
                                {{ user.email }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeReviewer(
                                      review.id,
                                      user.id!,
                                      props.project.id!
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <template v-if="draggedItem.id">
                          <div
                            v-if="
                        isMemberAlreadyAssignedToReviewers(
                          parseInt(draggedItem.id),
                          review.id,
                          props.project.id!
                        ) || draggedItem.type !== DraggedItemType.member
                      "
                            class="absolute top-0 left-0 w-full h-full rounded bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />
                          <DropZone
                            @dropped="
                              addReviewerToReview(
                                review.id,
                                parseInt($event),
                                props.project.id!
                              )
                            "
                            v-slot="{ isDraggedOver }"
                            v-else
                          >
                            <div
                              class="absolute rounded top-0 left-0 w-full h-full flex items-start justify-end px-2 py-4"
                              :class="{
                                'bg-[#45A91E]/70 ': isDraggedOver,
                                'border border-dashed border-[#45A91E]':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                class="flex gap-1 items-center text-white pointer-events-none"
                                v-if="isDraggedOver"
                              >
                                <EyePlusIcon class="w-5 h-5 fill-white" />
                                <p class="font-medium text-sm">
                                  Assign Reviewer
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                      </div></template
                    >
                  </MemberAddAssignMenu>
                </div>
                <div>
                  <MemberAddAssignMenu
                    :project-id="props.project.id!"
                    :review-id="review.id"
                    :role="ReviewRoles.APPROVER"
                  >
                    <template #activator="{ show }">
                      <div
                        class="flex flex-col justify-stretch border px-2 py-4 relative bg-white border-[#CCCCCC] dark:bg-faded/20 text-primary dark:text-white dark:border-0"
                        :class="{ 'border-transparent': draggedItem.id }"
                        @click="show"
                      >
                        <div class="flex justify-start gap-1 items-center mb-4">
                          <MiniHandThumbUpIcon class="w-5 h-5 fill-[#82A8B8]" />
                          <p class="font-medium text-sm">Approvers</p>
                        </div>

                        <div class="flex flex-wrap justify-start gap-2">
                          <div
                            v-for="user in reviewsWithAssignedUsers?.[review.id]
                              ?.approvers ?? []"
                            v-tooltip="user?.email"
                          >
                            <div
                              class="max-w-fit rounded-full text-primary text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                              :style="{  'background-color' : `${usersColors[user.id!]}40`}"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                                :style="{ backgroundColor: usersColors[user.id!] }"
                              >
                                <p class="h-3 w-3 font-medium leading-3">
                                  {{ user.email[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[50px]">
                                {{ user.email }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click.stop="
                                    removeApprover(
                                      review.id,
                                      user.id!,
                                      props.project.id!
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            v-for="externalApprover in reviewsWithAssignedUsers?.[
                              review.id
                            ]?.externalApprovers ?? []"
                            v-tooltip="externalApprover?.name"
                          >
                            <div
                              class="bg-[#D8DCDE] max-w-fit rounded-full text-primary text-center border-2 border-transparent py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                            >
                              <div
                                class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center bg-primary/40"
                              >
                                <p
                                  class="h-3 w-3 font-medium leading-3 text-white"
                                >
                                  {{ externalApprover.name[0].toUpperCase() }}
                                </p>
                              </div>
                              <div class="leading-none truncate max-w-[50px]">
                                {{ externalApprover.name }}
                              </div>
                              <div
                                class="w-2.5 h-2.5 flex items-center justify-center"
                              >
                                <button
                                  class="hover:bg-transparent"
                                  @click="
                                    removeExternalApprover(
                                      review.id,
                                      externalApprover.id,
                                      props.project.id!
                                    )
                                  "
                                >
                                  <XMarkIcon class="h-[16.5px]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <template
                          v-if="
                            draggedItem.id &&
                            draggedItem.type === DraggedItemType.member
                          "
                        >
                          <div
                            v-if="
                        isMemberAlreadyAssignedToApprovers(
                          parseInt(draggedItem.id),
                          review.id,props.project.id!
                        )
                      "
                            class="absolute top-0 left-0 w-full h-full rounded bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />

                          <DropZone
                            @dropped="
                              addApproverToReview(
                                review.id,
                                parseInt($event),
                                props.project.id!
                              )
                            "
                            v-slot="{ isDraggedOver }"
                            v-else
                          >
                            <div
                              class="absolute rounded top-0 left-0 w-full h-full flex items-start justify-end px-2 py-4"
                              :class="{
                                'bg-[#45A91E]/70 ': isDraggedOver,
                                'border border-dashed border-[#45A91E]':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                class="flex justify-start gap-1 items-center text-white pointer-events-none"
                                v-if="isDraggedOver"
                              >
                                <MiniHandThumbUpPlusIcon
                                  class="w-5 h-5 fill-white"
                                />
                                <p class="font-medium text-sm">
                                  Assign Approver
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                        <template
                          v-else-if="
                            draggedItem.id &&
                            draggedItem.type === DraggedItemType.externalMember
                          "
                        >
                          <div
                            v-if="
                        isExternalMemberAlreadyAssignedToApprovers(
                          draggedItem.id,
                          review.id,  props.project.id!
                        )
                      "
                            class="absolute top-0 left-0 w-full h-full rounded bg-white bg-opacity-40 border border-solid border-[#D9E4EA]"
                          />

                          <DropZone
                            @dropped="
                        (externalMemberId) =>
                          addExternalApproverToReview(
                            review.id,
                            externalMemberId,  props.project.id!
                          )
                      "
                            v-slot="{ isDraggedOver }"
                            v-else
                          >
                            <div
                              class="absolute rounded top-0 left-0 w-full h-full flex items-start justify-end px-2 py-4"
                              :class="{
                                'bg-[#45A91E]/70 ': isDraggedOver,
                                'border border-dashed border-[#45A91E]':
                                  !isDraggedOver,
                              }"
                            >
                              <div
                                class="flex justify-start gap-1 items-center text-white"
                                v-if="isDraggedOver"
                              >
                                <MiniHandThumbUpPlusIcon
                                  class="w-5 h-5 fill-white"
                                />
                                <p class="font-medium text-sm">
                                  Assign Approver
                                </p>
                              </div>
                            </div>
                          </DropZone>
                        </template>
                      </div></template
                    >
                  </MemberAddAssignMenu>
                </div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import useUsers from '@app/composables/use-users'
import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { computed, ref } from 'vue'
import Draggable from './Draggable.vue'
import DropZone from './DropZone.vue'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import projectPlan, { ProjectExternalMember, ReviewRoles } from './projectPlan'
import MiniUserIcon from '@app/components/Icons/MiniUserIcon.vue'
import MiniEyeIcon from '@app/components/Icons/MiniEyeIcon.vue'
import MiniHandThumbUpIcon from '@app/components/Icons/MiniHandThumbUpIcon.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import ProjectExternalMembers from './ProjectMembers/ProjectExternalMembers.vue'
import ProjectMembers from './ProjectMembers/ProjectMembers.vue'
import EyePlusIcon from '@app/components/Icons/EyePlusIcon.vue'
import MiniHandThumbUpPlusIcon from '@app/components/Icons/MiniHandThumbUpPlusIcon.vue'
import MemberAddAssignMenu from './ProjectMembers/MemberAddAssignMenu.vue'

const props = defineProps<{
  project: Project
}>()

const users = useUsers()

const {
  memberProjectReviewRolesAssignment,

  addExternalApproverToReview,
  isExternalMemberAlreadyAssignedToApprovers,
  addApproverToReview,
  isMemberAlreadyAssignedToApprovers,
  removeExternalApprover,
  removeApprover,
  removeReviewer,
  addAuthorToReview,
  isMemberAlreadyAssignedToAuthors,
  removeAuthor,
  isMemberAlreadyAssignedToReviewers,
  addReviewerToReview,
  removeMember,
  removeExternalMember,
} = projectPlan()

const projectMembers = computed(() => {
  return memberProjectReviewRolesAssignment.value?.find(
    (p) => p.projectId === props.project.id
  )?.memberAssignment
})
const reviewsWithAssignedUsers = computed<{
  [reviewId: number]: {
    authors: User[]
    reviewers: User[]
    approvers: User[]
    externalApprovers: ProjectExternalMember[]
  }
}>(
  () =>
    props.project.reviews?.reduce((a, r) => {
      const projectAssignment = memberProjectReviewRolesAssignment?.value?.find(
        (p) => p.projectId === props.project.id
      )

      const authors =
        projectAssignment?.reviewRolesAssignment?.[r.id]?.authors?.map((uId) =>
          users.users.value.find((u) => u.id === uId)
        ) ?? []

      const reviewers =
        projectAssignment?.reviewRolesAssignment?.[r.id]?.reviewers?.map(
          (uId) => users.users.value.find((u) => u.id === uId)
        ) ?? []

      const approvers =
        projectAssignment?.reviewRolesAssignment?.[r.id]?.approvers?.map(
          (uId) => users.users.value.find((u) => u.id === uId)
        ) ?? []

      const externalApprovers =
        projectAssignment?.reviewRolesAssignment?.[
          r.id
        ]?.externalApprovers?.map((mId) =>
          projectMembers?.value?.externalMembers.find((u) => u.id === mId)
        ) ?? []

      return {
        ...a,
        [r.id]: {
          authors,
          reviewers,
          approvers,
          externalApprovers,
        },
      }
    }, {}) ?? {}
)

enum DraggedItemType {
  member,
  externalMember,
}
const draggedItem = ref<{ id: string; type: DraggedItemType | undefined }>({
  id: '',
  type: undefined,
})

function updateDraggedItem(
  itemId: string,
  type: DraggedItemType,
  state: boolean
) {
  if (state) {
    draggedItem.value.id = itemId
    draggedItem.value.type = type
  } else {
    draggedItem.value.id = ''
    draggedItem.value.type = undefined
  }
}

const availableColors = [
  '#A1CAE9',
  '#AFB5F1',
  '#C8AFF1',
  '#F6BBFB',
  '#F8AFD2',
  '#F8AFAF',
  '#F8C9AF',
  '#F8DFAF',
  '#EBE28F',
  '#C2F8AF',
  '#AFF8C3',
  '#AFF8EB',
  '#83D6E9',
  '#C8BFFF',
  '#FFBFDA',
  '#FFB6BA',
  '#FFC19E',
  '#D5DF9A',
  '#B8DF9A',
  '#9ADFAD',
  '#B1F1FF',
  '#84B5FF',
  '#8491FF',
  '#AA8CFF',
  '#FFB8FC',
  '#FF9595',
]

const usersColors = computed<{ [userId: number]: string }>(
  () =>
    projectMembers?.value?.members?.reduce(
      (a, u, i) => ({ ...a, [u.id]: availableColors[i] }),
      {}
    ) ?? {}
)

const canDeleteMember = (userId: number) => {
  const userIndex = projectMembers?.value?.members.findIndex(
    (u) => u.id === userId
  )

  if (userIndex !== -1) {
    const reviewersAssignment = memberProjectReviewRolesAssignment.value?.find(
      (p) => p.projectId === props.project.id
    )?.reviewRolesAssignment

    if (reviewersAssignment) {
      const isAssignedToReviews = Object.values(reviewersAssignment).some(
        (assignment) => {
          const isAssignedAsApprover = assignment.approvers.includes(userId)
          const isAssignedAsAuthor = assignment.authors.includes(userId)
          const isAssignedAsReviewer = assignment.reviewers.includes(userId)

          return (
            isAssignedAsApprover || isAssignedAsAuthor || isAssignedAsReviewer
          )
        }
      )

      return !isAssignedToReviews
    }
  }

  return true
}

const canDeleteExternalMember = (externalMemberId: string) => {
  const externalMemberIndex = projectMembers?.value?.externalMembers.findIndex(
    (u) => u.id === externalMemberId
  )

  if (externalMemberIndex !== -1) {
    const reviewersAssignment = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === props.project.id
    )?.reviewRolesAssignment

    if (reviewersAssignment) {
      const isAssignedAsExternalApprover = Object.values(
        reviewersAssignment
      ).some((assignment) =>
        assignment.externalApprovers.includes(externalMemberId)
      )

      return !isAssignedAsExternalApprover
    }
  }

  return true
}
</script>
