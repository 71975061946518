<template>
  <div :id="`project-${project.id}`">
    <div
      v-if="!shouldRender"
      class="flex justify-between gap-2 px-6 py-1 items-center w-full h-14 bg-azureish-white dark:bg-[#344F62] hover:bg-opacity-80 rounded-md"
    >
      <div class="w-full gap-2 min-w-0 flex">
        <div class="w-full">
          <h1 class="text-md truncate">Loading</h1>
          <p class="text-gray-500">please wait</p>
        </div>
      </div>
    </div>
    <div v-else>
      <ExpansionPanel v-model="isShown">
        <template #activator="{ toggle, isShown }">
          <div
            @click="toggle"
            class="pl-2 flex justify-between items-center h-14 cursor-pointer transition-all duration-200"
            :class="[
              isShown
                ? 'bg-blue-cyan rounded-t-lg dark:bg-[#104065]'
                : 'bg-azureish-white dark:bg-[#344F62] hover:bg-opacity-80 rounded-lg',
            ]"
          >
            <div class="flex gap-2 items-center">
              <button
                @click.stop="toggleFavorite()"
                class="px-2 text-white hover:bg-transparent"
              >
                <StarIcon
                  class="w-5 h-5 hover:stroke-yellow-500"
                  :class="[
                    project.isFavorite
                      ? 'fill-yellow-500 stroke-yellow-500'
                      : isShown
                      ? 'stroke-white fill-transparent'
                      : 'stroke-primary fill-transparent dark:stroke-white',
                  ]"
                />
              </button>
              <p
                class="font-bold text-lg"
                :class="[isShown ? 'text-white' : '']"
              >
                {{ project.name }}
              </p>
            </div>
            <div class="flex gap-4">
              <Modal dismissible>
                <template #activator="{ show }">
                  <button
                    @click.stop="show"
                    class="bg-white dark:bg-[#275374] dark:border-white inline-flex items-center gap-1 py-1.5 border text-sm border-primary rounded-md px-2 font-normal hover:brightness-110"
                  >
                    <ClipboardDocumentListIcon class="w-5 h-5" />
                    <span>Plan</span>
                  </button>
                </template>
                <template #content>
                  <div
                    class="w-[calc(100vw-120px)] h-[calc(100vh-120px)] dark:bg-[#103047]"
                  >
                    <ProjectPlan :project="project" />
                  </div>
                </template>
              </Modal>

              <CreateProjectReviewFloatingMenu
                :project-id="project.id!"
                v-if="auth.user.value?.role !== Role.READ_ONLY"
              />
              <ActionButton @click.stop="toggle">
                <ChevronUpIcon v-if="isShown" class="w-6 h-6 text-white" />
                <ChevronDownIcon v-else class="w-6 h-6 hover:brightness-125" />
              </ActionButton>
            </div>
          </div>
        </template>
        <template #default>
          <div
            class="flex justify-start bg-[#E9EAEA] h-10 items-center gap-2 p-2 dark:bg-[#40596C]"
          >
            <button
              @click.stop="toggleAllProjectReviewsSelection()"
              class="inline-flex items-center text-sm"
            >
              <Checkbox
                v-model="bulkSelectState"
                class="dark:border-white dark:bg-white dark:bg-opacity-25"
              />
            </button>
            <FloatingMenu placement="bottom" :offset="[0, 10]">
              <template #activator="{ show }">
                <ActionButton
                  @click="selectedReviewsIds.length > 0 && show()"
                  class="border border-primary dark:border-white"
                  :class="{
                    'opacity-50 cursor-auto': selectedReviewsIds.length <= 0,
                  }"
                >
                  <DocumentArrowDownIcon class="w-4 h-4" />
                  Export
                  <ArrowDownIcon class="w-3 h-3 fill-primary dark:fill-white" />
                </ActionButton>
              </template>
              <template #content>
                <div class="w-24">
                  <FloatingMenu :offset="[0, 5]" trigger="hover">
                    <template #activator="{ show }">
                      <button
                        @click="show"
                        class="flex items-center justify-between w-full py-1 px-2 hover:brightness-125 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                      >
                        references
                      </button>
                    </template>
                    <template #content>
                      <div
                        class="w-24 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                      >
                        <button
                          @click="exportRis(project, selectedReviewsIds)"
                          class="flex items-center justify-between w-full py-1 px-2 hover:bg-[#EBF7FF] dark:hover:bg-[#344F62]"
                        >
                          RIS
                        </button>
                        <button
                          @click="
                            exportCitationsDocx(project, selectedReviewsIds)
                          "
                          class="flex items-center justify-between w-full py-1 px-2 hover:bg-[#EBF7FF] dark:hover:bg-[#344F62]"
                        >
                          Docx
                        </button>
                        <button
                          @click="downloadPdfZip(project, selectedReviewsIds)"
                          class="flex items-center justify-between w-full py-1 px-2 hover:bg-[#EBF7FF] dark:hover:bg-[#344F62]"
                        >
                          PDFs
                        </button>
                      </div>
                    </template>
                  </FloatingMenu>
                </div>
              </template>
            </FloatingMenu>
            <FloatingMenu
              placement="bottom"
              :offset="[0, 10]"
              v-if="auth.user.value?.role !== Role.READ_ONLY"
            >
              <template #activator="{ show }">
                <ActionButton
                  class="border border-primary dark:border-white"
                  @click="selectedReviewsIds.length > 0 && show()"
                  :class="{
                    'opacity-50 cursor-auto': selectedReviewsIds.length <= 0,
                  }"
                >
                  <DocumentDuplicateIcon class="w-4 h-4 dark:stroke-white" />
                  Duplicate
                  <ArrowDownIcon class="w-3 h-3 fill-primary dark:fill-white" />
                </ActionButton>
              </template>

              <template #content>
                <div
                  class="w-96 text-silent-night dark:bg-[#103047] dark:text-white/70 dark:border-white/10 border"
                >
                  <div class="px-2 py-2">
                    <label class="font-bold">Project</label>
                    <SelectList
                      placeholder="select project"
                      :items="projectsListItems"
                      v-model="selectedProjectId"
                    />
                  </div>

                  <div
                    class="flex justify-end gap-2 w-full py-2 px-2 sticky bottom-0 right-0"
                  >
                    <button
                      class="text-white px-2 py-1 rounded-sm hover:bg-slate-700 bg-slate-600"
                      @click="
                        duplicateReview(
                          parseInt(selectedProjectId),
                          selectedReviewsIds
                        )
                      "
                    >
                      Duplicate
                    </button>
                  </div>
                </div>
              </template>
            </FloatingMenu>
            <FloatingMenu
              placement="bottom"
              :offset="[0, 10]"
              v-if="auth.user.value?.role !== Role.READ_ONLY"
            >
              <template #activator="{ show }">
                <ActionButton
                  @click="selectedReviewsIds.length > 0 && show()"
                  class="bg-red-600 text-white rounded"
                  :class="{
                    'opacity-50  cursor-auto': selectedReviewsIds.length <= 0,
                  }"
                >
                  <TrashIcon class="w-4 h-4" />
                  Delete
                  <ArrowDownIcon class="w-3 h-3 fill-white" />
                </ActionButton>
              </template>
              <template #content="{ hide }">
                <div
                  class="p-4 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                >
                  <p class="mb-2">
                    all selected reviews will be deleted, would you like to
                    proceed?
                  </p>
                  <div>
                    <button
                      @click="hide"
                      class="bg-neutral-500 text-white py-1 px-2 mr-1 hover:brightness-110"
                    >
                      cancel
                    </button>
                    <button
                      @click="
                        deleteSelectedReviews(project.id!, selectedReviewsIds)
                      "
                      class="bg-red-600 text-white py-1 px-2 hover:brightness-125"
                    >
                      delete
                    </button>
                  </div>
                </div>
              </template>
            </FloatingMenu>
          </div>
          <div
            class="divide-y divide-faded dark:border-[#17598C] dark:divide-[#17598C] border border-faded rounded-b-lg"
          >
            <div
              v-for="(review, index) in project.reviews"
              class="px-2 flex justify-between items-center h-12 hover:bg-[#EBF7FF] dark:hover:bg-[#40596C]"
            >
              <input
                type="checkbox"
                :id="review.name"
                :value="review.id"
                v-model="selectedReviewsIds"
                class="w-4 h-4 mr-1 hover:cursor-pointer"
              />
              <RouterLink
                :to="`/reviews/${review.id}`"
                class="py-1 px-2 flex h-full w-full items-center"
              >
                <label
                  class="hover:cursor-pointer text-base font-medium leading-5"
                  :for="review.name"
                >
                  {{ review.name }}
                </label>
              </RouterLink>
              <FloatingMenu
                :offset="[-10, 14]"
                v-if="auth.user.value?.role !== Role.READ_ONLY"
              >
                <template #activator="{ show }">
                  <ActionButton @click.stop="show()" class="px-2 h-full">
                    <EllipsisHorizontalIcon class="w-6 h-6" />
                  </ActionButton>
                </template>
                <template #content="{ hide }">
                  <div
                    class="flex flex-col text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                    @click.stop=""
                  >
                    <FloatingTextInput
                      :value="review.name"
                      @save="updateReviewName(project.id!, review.id, $event)"
                    >
                      <template #activator="{ show }">
                        <button
                          @click.stop="show()"
                          class="py-2 px-2 h-full flex items-center w-full hover:bg-[#EBF7FF] dark:hover:bg-[#40596C]"
                        >
                          Edit
                        </button></template
                      >
                    </FloatingTextInput>
                    <FloatingMenu :offset="[0, 7]">
                      <template #activator="{ show }">
                        <button
                          @click="show"
                          class="py-2 px-2 h-full flex items-center w-full hover:bg-[#EBF7FF] dark:hover:bg-[#40596C]"
                        >
                          Delete
                        </button>
                      </template>
                      <template #content="{ hide }">
                        <div
                          class="p-4 w-96 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                        >
                          <p class="mb-2">
                            Review data will be lost, would you like to proceed?
                          </p>
                          <div>
                            <button
                              @click="hide"
                              class="bg-neutral-500 text-white py-1 px-2 mr-1 hover:brightness-110"
                            >
                              cancel
                            </button>
                            <button
                              @click="
                                deleteSelectedReviews(project.id!, [review.id])
                              "
                              class="bg-red-600 text-white py-1 px-2 hover:brightness-125"
                            >
                              delete
                            </button>
                          </div>
                        </div>
                      </template>
                    </FloatingMenu>
                    <FloatingMenu :offset="[0, 7]">
                      <template #activator="{ show }">
                        <button
                          @click="show"
                          class="py-2 px-2 h-full flex items-center w-full hover:bg-[#EBF7FF] dark:hover:bg-[#40596C]"
                        >
                          Duplicate
                        </button>
                      </template>
                      <template #content>
                        <div
                          class="w-96 text-silent-night dark:bg-[#103047] dark:text-white/70 dark:border-white/10 border"
                        >
                          <div class="px-2 py-1">
                            <label class="font-bold">Project</label>
                            <SelectList
                              placeholder="select project"
                              :items="projectsListItems"
                              v-model="selectedProjectId"
                            />
                          </div>

                          <div
                            class="flex justify-end gap-2 w-full py-2 px-2 sticky bottom-0 right-0"
                          >
                            <button
                              class="text-white px-2 py-1 rounded-sm hover:bg-slate-700 bg-slate-600"
                              @click="
                                duplicateReview(parseInt(selectedProjectId), [
                                  review.id,
                                ])
                              "
                            >
                              Duplicate
                            </button>
                          </div>
                        </div>
                      </template>
                    </FloatingMenu>
                  </div>
                </template>
              </FloatingMenu>
            </div>
          </div>
        </template>
      </ExpansionPanel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import StarIcon from '@app/components/Icons/StarIcon.vue'
import CreateProjectReviewFloatingMenu from '@app/views/Projects/CreateProjectReviewFloatingMenu.vue'
import DocumentDuplicateIcon from '@app/components/Icons/DocumentDuplicateIcon.vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import Button from '@app/components/Global/Button.vue'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import SelectList from '@app/components/Global/Inputs/SelectList.vue'
import { SelectListItem, SnackbarState } from '@app/types'
import { useRouter } from 'vue-router'
import { ProjectsService } from '@core/application/projects.service'
import { Project } from '@core/domain/models/project.model'
import { format } from 'date-fns'
import useSnackbar from '@app/composables/use-snackbar'
import { computed, inject, onMounted, ref, nextTick } from 'vue'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import useLoading from '@app/composables/use-loading'
import useProjects from '@app/views/Projects/use-projects'
import { User } from '@core/domain/models/user.model'
import ActionButton from '@app/components/Global/ActionButton.vue'
import ArrowDownIcon from '@app/components/Icons/ArrowDownIcon.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import ChevronUpIcon from '@app/components/Icons/ChevronUpIcon.vue'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import EllipsisHorizontalIcon from '@app/components/Icons/EllipsisHorizontalIcon.vue'
import DocumentArrowDownIcon from '@app/components/Icons/DocumentArrowDownIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import useAuth from '@app/composables/use-auth'
import { Role } from '@core/domain/types/role.type'
import ProjectPlan from './ProjectPlan/ProjectPlan.vue'
import useDevToolbox from '@app/composables/use-dev-toolbox'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { useStorage } from '@vueuse/core'
import ClipboardDocumentListIcon from '@app/components/Icons/ClipboardDocumentListIcon.vue'

const devToolbox = useDevToolbox()

const props = defineProps<{
  project: Project
  users: User[]
}>()

const isShown = useStorage(`isShown-${props.project.id}`, false)

const router = useRouter()
const snackbar = useSnackbar()
const loading = useLoading()
const projects = useProjects()
const selectedProjectId = ref(props.project.id!.toString())
const auth = useAuth()
const projectsListItems = computed<SelectListItem[]>(() =>
  projects.projects.value.map((p) => {
    return { text: p.name, value: p.id!.toString() }
  })
)
const selectedReviewsIds = ref<Id[]>([])

const projectsService: ProjectsService = inject('projectsService')!

async function downloadPdfZip(project: Project, selectedReviewsIds: Id[]) {
  try {
    loading.start()
    if (selectedReviewsIds.length === 0)
      snackbar.show(SnackbarState.ERROR, 'there is no reviews selected')
    else {
      const buffer = await projectsService.downloadPdfZip(
        project.id!,
        selectedReviewsIds
      )
      const downloadElement = document.createElement('a')
      downloadElement.style.display = 'none'
      downloadElement.href = window.URL.createObjectURL(new Blob([buffer]))
      downloadElement.download =
        project.name + format(new Date(), '-MM-dd-yyyy') + '.zip'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      window.URL.revokeObjectURL(downloadElement.href)
      downloadElement.remove()
      hideAll()
      resetSelectedReviewsIds()
    }
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function exportRis(
  project: Project,
  selectedReviewsIds: Id[]
): Promise<void> {
  try {
    loading.start()
    if (selectedReviewsIds.length === 0)
      snackbar.show(SnackbarState.ERROR, 'there is no reviews selected')
    else {
      const file = await projectsService.exportRis(
        project.id!,
        selectedReviewsIds
      )
      const downloadElement = document.createElement('a')
      downloadElement.style.display = 'none'
      downloadElement.href = window.URL.createObjectURL(file)
      downloadElement.download =
        project.name + format(new Date(), '-MM-dd-yyyy') + '.ris'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      window.URL.revokeObjectURL(downloadElement.href)
      downloadElement.remove()
      hideAll()
      resetSelectedReviewsIds()
    }
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

function toggleFavorite() {
  projects.toggleFavorite(props.project.id!)
  if (props.project.isFavorite) {
    snackbar.show(SnackbarState.SUCCESS, 'set project as favorite')
  } else {
    snackbar.show(SnackbarState.SUCCESS, 'set project as unfavorite')
  }
}

async function exportCitationsDocx(
  project: Project,
  selectedReviewsIds: Id[]
): Promise<void> {
  try {
    loading.start()
    if (selectedReviewsIds.length === 0)
      snackbar.show(SnackbarState.ERROR, 'there is no reviews selected')
    else {
      const downloadElement = document.createElement('a')
      const file = await projectsService.exportCitationsDocx(
        project.id!,
        selectedReviewsIds
      )
      downloadElement.style.display = 'none'
      downloadElement.href = window.URL.createObjectURL(file)
      downloadElement.download =
        project.name + format(new Date(), '-MM-dd-yyyy') + '.docx'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      window.URL.revokeObjectURL(downloadElement.href)
      downloadElement.remove()
      hideAll()
      resetSelectedReviewsIds()
    }
  } catch (e) {
    const error = e as HttpException
    console.log(error)
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function updateReviewName(
  projectId: Id,
  reviewId: Id,
  name: string
): Promise<void> {
  try {
    loading.start()
    await projects.updateReviewName(projectId, reviewId, name)
    snackbar.show(SnackbarState.SUCCESS, 'review name updated')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

async function duplicateReview(
  selectedProjectId: Id,
  selectedReviewsIds: Id[]
) {
  try {
    loading.start()
    if (selectedReviewsIds.length === 0)
      snackbar.show(SnackbarState.ERROR, 'there is no reviews selected')
    else {
      await Promise.all(
        selectedReviewsIds.map(async (selectedReview) => {
          await projects.duplicateReview(selectedReview, selectedProjectId)
        })
      )
      projects.refresh()
      snackbar.show(SnackbarState.SUCCESS, 'reviews duplicated successfully')
      hideAll()
    }
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function deleteSelectedReviews(projectId: Id, selectedReviewsIds: Id[]) {
  try {
    loading.start()
    if (selectedReviewsIds.length === 0)
      snackbar.show(SnackbarState.ERROR, 'there is no reviews selected')
    else {
      await projects.deleteSelectedReviews(projectId, selectedReviewsIds)
      resetSelectedReviewsIds()
      snackbar.show(SnackbarState.SUCCESS, 'reviews deleted successfully')
      hideAll()
    }
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const bulkSelectState = computed<CheckboxState>(() => {
  if (selectedReviewsIds.value.length === props.project.reviews?.length)
    return CheckboxState.checked
  if (selectedReviewsIds.value.length > 0) return CheckboxState.undetermined
  return CheckboxState.uncheked
})

function toggleAllProjectReviewsSelection() {
  selectedReviewsIds.value = bulkSelectState.value
    ? props.project.reviews?.map((r) => r.id) ?? []
    : []
}

function resetSelectedReviewsIds() {
  selectedReviewsIds.value = []
}

const shouldRender = ref(false)

onMounted(() => {
  nextTick(() => {
    const element = document.querySelector(`#project-${props.project.id}`)!
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!entries[0].isIntersecting) return
      observer.unobserve(element)
      shouldRender.value = true
    }
    const observer = new IntersectionObserver(callback)
    observer.observe(element)
  })
})
</script>
