import { User } from '@core/domain/models/user.model'
import { readonly, ref } from 'vue'

const user = ref<User>()
const authToken = ref<string>()

export default function useAuth() {
  const setToken = (token: string) => {
    authToken.value = token
  }

  const setUser = (value: User) => {
    user.value = value
  }

  const logout = () => {
    user.value = undefined
    authToken.value = undefined
  }
  return {
    token: readonly(authToken),
    setToken,
    setUser,
    user: readonly(user),
    logout,
  }
}
