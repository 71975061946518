import { UsersService } from '@core/application/users.service'
import { User } from '@core/domain/models/user.model'
import { Id } from '@core/domain/types/id.type'
import { Role } from '@core/domain/types/role.type'
import { inject, ref } from 'vue'

const users = ref<User[]>([])
export default () => {
  const usersService: UsersService = inject('usersService')!

  const create = async (data: {
    email: string
    role: Role
    password: string
  }) => {
    const user = await usersService.create(data)
    users.value.push(user)
    return user
  }

  const refresh = async () => {
    const fetchedUsers = await usersService.find()
    users.value = fetchedUsers
  }

  async function updateCurrentUserFullName(data: {
    firstName: string
    lastName: string
  }) {
    await usersService.updateCurrentUserFullName(data)
  }

  async function enableUser(userId: Id): Promise<User> {
    const updatedUser = await usersService.enableUser(userId)
    const updatedUserIndex = users.value.findIndex(
      (u) => u.id === updatedUser.id
    )
    users.value[updatedUserIndex] = updatedUser
    return updatedUser
  }

  async function disableUser(userId: Id): Promise<User> {
    const updatedUser = await usersService.disableUser(userId)
    const updatedUserIndex = users.value.findIndex(
      (u) => u.id === updatedUser.id
    )
    users.value[updatedUserIndex] = updatedUser
    return updatedUser
  }

  return {
    users: users,
    create,
    refresh,
    updateCurrentUserFullName,
    enableUser,
    disableUser,
  }
}
