<template>
  <button @click="model = !model" class="flex gap-2 items-center">
    <div
      class="block rounded-full relative"
      :class="[
        model ? 'bg-green-600' : ' bg-gray-300',
        {
          'w-8 h-5': size === '',
          'w-10 h-7': size === 'lg',
          'bg-white': disabled,
        },
      ]"
    >
      <div
        class="absolute top-1 w-3 h-3 rounded-full transition drop-shadow"
        :class="[
          model ? ' bg-white ' : 'bg-white-smoke',
          model && size === 'lg' ? 'translate-x-3/4' : 'left-1',
          model && size === '' ? 'translate-x-full' : '',
          {
            'w-3 h-3 left-1': size === '',
            'w-5 h-5': size === 'lg',
          },
        ]"
      />
    </div>
    <slot />
  </button>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    size?: 'lg' | ''
    disabled?: boolean
  }>(),
  {
    modelValue: false,
    size: '',
  }
)

const model = computed<boolean>({
  get: () => props.modelValue,
  set: (v: boolean) => emit('update:modelValue', v),
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()
</script>
