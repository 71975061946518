<template>
  <div class="h-full flex flex-col">
    <div class="bg-white w-full inline-flex justify-between p-4 z-50">
      <div class="flex gap-2">
        <button @click="zoomOut" class="px-2 py-0.5 hover:bg-gray-100">
          <ZoomOutIcon class="w-6 h-6" />
        </button>
        <button @click="resetZoom" class="px-2 py-0.5 hover:bg-gray-100">
          <ArrowPathIcon class="w-6 h-6" />
        </button>
        <button @click="zoomIn" class="px-2 py-0.5 hover:bg-gray-100">
          <ZoomInIcon class="w-6 h-6" />
        </button>
      </div>
      <div class="flex gap-4">
        <div class="flex">
          <input
            class="border px-2 py-1"
            type="text"
            placeholder="Search in pdf"
            v-model="internalFindQuery"
            @keydown.enter.exact.prevent="nextMatch"
            @keydown.enter.shift.exact.prevent="previousMatch"
            @input="findInPdf"
          />
          <div
            class="h-full flex items-center pl-2 py-1 cursor-help"
            v-tooltip="
              'When the search field is focused, you can navigate through the matches using enter for next match and shift+enter for previous match'
            "
          >
            <InformationCircle class="w-5 h-5" />
          </div>
        </div>
        <div class="flex gap-4">
          <button @click="previousMatch">previous</button>
          <button @click="nextMatch">next</button>
        </div>
      </div>
    </div>

    <div class="relative grow">
      <div
        class="absolute top-0 left-0 w-full overflow-auto h-full"
        ref="pdfContainerElement"
        id="pdfContainer"
        @wheel.ctrl="onWheel"
        @mousedown.middle="handleMouseDown"
        @mouseup.middle="handleMouseUp"
        @mousemove="handleMouseMove"
        :class="{ 'select-none': isDragging }"
      >
        <div ref="pdfViewerElement" id="pdfViewer" class="w-max m-auto" />
      </div>
    </div>

    <div
      class="bg-white w-full inline-flex justify-between items-center p-4 z-50"
    >
      <div class="flex gap-4">
        <button @click="goToPreviousPage">Previous Page</button>
        <button @click="goToNextPage">Next Page</button>
      </div>
      <div>
        <button
          @click="downloadPdf"
          class="flex items-center gap-2 hover:text-primary/70 text-primary border border-primary leading-none p-1 hover:border-primary/70"
        >
          <DocumentDownloadIcon class="w-5 h-5" /> Download
        </button>
      </div>

      <div class="flex gap-2 items-center">
        Page
        <select
          v-model.number="currentPageNumber"
          class="border w-10"
          @change="
            goToPage(parseInt(($event.target as HTMLSelectElement).value))
          "
        >
          <option
            v-for="(_, index) in Array(pageCount).fill(0)"
            :value="index + 1"
          >
            {{ index + 1 }}
          </option>
        </select>
        <div>of {{ pageCount }}</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import 'pdfjs-dist/web/pdf_viewer.css'
import { ref, computed, onBeforeMount } from 'vue'
import { getDocument, PDFDocumentProxy } from 'pdfjs-dist'
import {
  EventBus,
  PDFLinkService,
  NullL10n,
  PDFFindController,
  PDFViewer,
} from 'pdfjs-dist/web/pdf_viewer'
import ArrowPathIcon from '../Icons/ArrowPathIcon.vue'
import InformationCircle from '../Icons/InformationCircle.vue'
import ZoomOutIcon from '../Icons/ZoomOutIcon.vue'
import ZoomInIcon from '../Icons/ZoomInIcon.vue'
import DocumentDownloadIcon from '../Icons/DocumentDownloadIcon.vue'

const props = defineProps<{
  file: Blob
  fileName: string
  findQuery?: string
}>()

const pdfContainerElement = ref<HTMLDivElement>()
const pdfViewerElement = ref<HTMLDivElement>()

let pdfDocument: PDFDocumentProxy

let pdfViewer: PDFViewer

const pageCount = ref(0)
const currentPageNumberInternalValue = ref(1)
const currentPageNumber = computed<number>({
  get: () => currentPageNumberInternalValue.value,
  set: (v) => {
    if (v > 0 && v <= pageCount.value) currentPageNumberInternalValue.value = v
  },
})

const internalFindQuery = ref(props.findQuery ?? '')

onBeforeMount(async () => {
  const pdfjs = await import('pdfjs-dist')
  const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry')
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
  const articlePdf = new Blob([props.file])
  const dataUrl = URL.createObjectURL(articlePdf)
  pdfDocument = await getDocument(dataUrl).promise

  const eventBus = new EventBus()

  const linkService = new PDFLinkService({
    eventBus,
  })
  const findController = new PDFFindController({
    eventBus,
    linkService,
    updateMatchesCountOnProgress: true,
  })
  pdfViewer = new PDFViewer({
    container: pdfContainerElement.value!,
    eventBus,
    l10n: NullL10n,
    linkService,
    viewer: pdfViewerElement.value!,
    findController,
  })

  pdfViewer.setDocument(pdfDocument)
  linkService.setDocument(pdfDocument)
  linkService.setViewer(pdfViewer)
  findController.setDocument(pdfDocument)
  pageCount.value = pdfDocument.numPages
  currentPageNumber.value = 1
  pdfViewer.eventBus.on('pagechanging', (v: { pageNumber: number }) => {
    currentPageNumber.value = v.pageNumber
  })

  if (internalFindQuery.value) {
    pdfViewer.eventBus.on('pagesloaded', () => {
      findInPdf()
    })
  }
})

function goToNextPage() {
  currentPageNumber.value += 1
  goToPage(currentPageNumber.value)
}

function goToPreviousPage() {
  currentPageNumber.value -= 1
  goToPage(currentPageNumber.value)
}

function goToPage(pageNumber: number) {
  if (pdfViewer) pdfViewer.currentPageNumber = pageNumber
}

function findInPdf() {
  pdfViewer.eventBus.dispatch('find', {
    highlightAll: true,
    query: internalFindQuery.value,
    phraseSearch: true,
  })
}

function nextMatch() {
  pdfViewer.eventBus.dispatch('find', {
    type: 'again',
    highlightAll: true,
    query: internalFindQuery.value,
    phraseSearch: true,
  })
}

function previousMatch() {
  pdfViewer.eventBus.dispatch('find', {
    type: 'again',
    highlightAll: true,
    findPrevious: true,
    query: internalFindQuery.value,
    phraseSearch: true,
  })
}

const zoomLevels = [
  { index: 1, value: 0.5 },
  { index: 2, value: 0.75 },
  { index: 3, value: 1 },
  { index: 4, value: 1.25 },
  { index: 5, value: 1.5 },
  { index: 6, value: 2 },
  { index: 7, value: 3 },
  { index: 8, value: 4 },
]

let currentZoomLevel = { index: 3, value: 1 }

function zoomIn() {
  const newZoomLevel = zoomLevels.find(
    (z) => z.index === currentZoomLevel.index + 1
  )
  if (newZoomLevel) {
    currentZoomLevel = newZoomLevel
    pdfViewer.currentScale = currentZoomLevel.value
  }
}
function zoomOut() {
  const newZoomLevel = zoomLevels.find(
    (z) => z.index === currentZoomLevel.index - 1
  )
  if (newZoomLevel) {
    currentZoomLevel = newZoomLevel
    pdfViewer.currentScale = currentZoomLevel.value
  }
}

function resetZoom() {
  currentZoomLevel = { index: 3, value: 1 }
  pdfViewer.currentScale = currentZoomLevel.value
}

function onWheel(event: WheelEvent) {
  event.preventDefault()
  if (event.deltaY < 0) {
    zoomIn()
  } else if (event.deltaY > 0) {
    zoomOut()
  }
}

let isDragging = false
let previousMousePosition: { x: number; y: number } | null = null

const handleMouseDown = (event: MouseEvent) => {
  isDragging = true
  previousMousePosition = { x: event.clientX, y: event.clientY }
}

const handleMouseUp = () => {
  isDragging = false
}

const handleMouseMove = (event: MouseEvent) => {
  if (isDragging && previousMousePosition && pdfContainerElement.value) {
    const delta = {
      x: event.clientX - previousMousePosition.x,
      y: event.clientY - previousMousePosition.y,
    }
    pdfContainerElement.value.scrollBy(-delta.x, -delta.y)
    previousMousePosition = { x: event.clientX, y: event.clientY }
  }
}

async function downloadPdf() {
  const downloadElement = document.createElement('a')
  let fileName = props.fileName

  downloadElement.style.display = 'none'
  downloadElement.href = window.URL.createObjectURL(props.file)
  downloadElement.download = fileName
  document.body.appendChild(downloadElement)
  downloadElement.click()
  window.URL.revokeObjectURL(downloadElement.href)
  downloadElement.remove()
}
</script>

<style>
#pdfContainer .page {
  position: relative;
  box-shadow: 0px 0px 23px #c6c2c2;
  margin-bottom: 10px;
}
#pdfContainer {
  padding: 20px;
  background: #f5f5f5;
}
</style>
