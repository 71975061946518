import { User } from '@core/domain/models/user.model'
import { Id } from '@core/domain/types/id.type'
import { Role } from '@core/domain/types/role.type'
import { Api } from '@infrastructure/datasource/review-manager-api'
import { UsersRepository } from '@infrastructure/datasource/users.repository'
import { getItem, setItem } from 'localforage'

export class UsersService {
  constructor(
    private _usersRepository: UsersRepository,
    private _reviewManagerService: Api<any>
  ) {}

  async create(data: {
    email: string
    role: Role
    password: string
  }): Promise<User> {
    const user = User.create(data)
    return this._usersRepository.create(user, data.password)
  }
  async find(): Promise<User[]> {
    return this._usersRepository.find()
  }
  async login(email: string, password: string): Promise<string> {
    const token = await this._usersRepository.login(email, password)
    this._usersRepository.setAuthHeader(token)
    await this.saveAuthToken(token)
    return token
  }

  async findCurrentUser(): Promise<User> {
    return this._usersRepository.findCurrentUser()
  }

  setAuthHeader(token: string): void {
    this._usersRepository.setAuthHeader(token)
    this._reviewManagerService.instance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`
  }

  async saveAuthToken(token: string): Promise<void> {
    await setItem('authToken', token)
  }

  async getAuthToken(): Promise<string> {
    const token: string = (await getItem('authToken')) ?? ''
    return token
  }
  async updateCurrentUserFullName(fullName: {
    firstName: string
    lastName: string
  }): Promise<void> {
    return this._usersRepository.updateCurrentUserFullName(fullName)
  }

  async enableUser(userId: Id): Promise<User> {
    return this._usersRepository.enableUser(userId)
  }

  async disableUser(userId: Id): Promise<User> {
    return this._usersRepository.disableUser(userId)
  }
}
