<template>
  <teleport to="#toolbar">
    <div class="w-full px-10 py-2">
      <div class="inline-flex text-xl font-medium leading-5 py-7 pr-6">
        Users
      </div>
      <CreateUsersFloatingMenu />
    </div>
  </teleport>
  <div class="max-w-5xl container pl-10 py-8">
    <Table
      class="w-full"
      :items="users.users.value"
      :headers="['email', 'role', 'status']"
    >
      <template #item="{ item }">
        <td class="py-4 px-3">{{ item.email }}</td>
        <td class="py-4 px-3">
          {{ item.role === Role.ADMIN ? 'Admin' : 'User' }}
        </td>
        <td class="py-4 px-2">
          <button
            @click="toggleUserState(item.id)"
            class="flex items-center justify-between text-silent-night dark:text-white/70"
          >
            <label class="w-[80px] pr-1 hover:cursor-pointer">
              {{ item.isDisabled ? 'Disabled' : 'Enabled' }}
            </label>
            <Switch
              :model-value="!item.isDisabled"
              class="hover:brightness-105 hover:/80"
            >
            </Switch>
          </button>
        </td>
      </template>
    </Table>
  </div>
</template>
<script lang="ts" setup>
import CreateUsersFloatingMenu from '@app/views/Users/CreateUsersFloatingMenu.vue'
import useUsers from '@app/composables/use-users'
import Table from '@app/components/Global/Table.vue'
import { Role } from '@core/domain/types/role.type'
import Switch from '@app/components/Global/Switch.vue'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { Id } from '@core/domain/types/id.type'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'

const snackbar = useSnackbar()
const loading = useLoading()
const users = useUsers()
users.refresh()

async function toggleUserState(userId: Id) {
  const user = users.users.value.find((u) => u.id === userId)
  try {
    loading.start()
    if (!user?.isDisabled) {
      await users.disableUser(userId)
      snackbar.show(
        SnackbarState.SUCCESS,
        'user marked as disabled successfully'
      )
    } else {
      await users.enableUser(userId)
      snackbar.show(
        SnackbarState.SUCCESS,
        'user marked as enabled successfully'
      )
    }
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}
</script>
