import { User } from '@core/domain/models/user.model'
import { useStorage } from '@vueuse/core'
import { readonly } from 'vue'
import { v4 as uuid } from 'uuid'
import { Id } from '@core/domain/types/id.type'

export type ProjectMember = {
  id: number
  fullName: string
  email: string
  isManager: boolean
  resume?: File
}
export enum ReviewRoles {
  AUTHOR = 'AUTHOR',
  REVIEWER = 'REVIEWER',
  APPROVER = 'APPROVER',
}

export type ReviewRoleAssignments = {
  [reviewId: string]: {
    authors: number[]
    reviewers: number[]
    approvers: number[]
    externalApprovers: string[]
  }
}

export type ProjectExternalMember = {
  id: string
  name: string
}

export type ProjectMembersAssignment = {
  members: ProjectMember[]
  externalMembers: ProjectExternalMember[]
}

export type MemberProjectReviewRolesAssignment = {
  projectId: Id
  memberAssignment: ProjectMembersAssignment
  reviewRolesAssignment: ReviewRoleAssignments
}

const memberProjectReviewRolesAssignment = useStorage<
  MemberProjectReviewRolesAssignment[]
>('memberProjectReviewRolesAssignment', [])

export default () => {
  function addUserToProject(user: User, projectId: Id) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      project.memberAssignment.members.push({
        fullName: `${user.firstName} ${user.lastName}`.trim(),
        id: user.id!,
        isManager: false,
        email: user.email,
      })
    } else {
      memberProjectReviewRolesAssignment.value.push({
        projectId: projectId,
        memberAssignment: {
          externalMembers: [],
          members: [
            {
              fullName: `${user.firstName} ${user.lastName}`.trim(),
              id: user.id!,
              isManager: false,
              email: user.email,
            },
          ],
        },
        reviewRolesAssignment: {},
      })
    }
  }

  function addExternalMember(name: string, projectId: Id) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      const memberNameAlreadyUsed =
        project.memberAssignment.externalMembers.find((u) => u.name === name)
      if (!memberNameAlreadyUsed)
        project.memberAssignment.externalMembers.push({
          id: uuid(),
          name,
        })
      else throw new Error('Member name is already in use')
    } else {
      memberProjectReviewRolesAssignment.value.push({
        projectId: projectId,
        memberAssignment: {
          externalMembers: [{ id: uuid(), name }],
          members: [],
        },
        reviewRolesAssignment: {},
      })
    }
  }

  function promoteToManager(userId: number, projectId: Id) {
    const projectIndex = memberProjectReviewRolesAssignment.value.findIndex(
      (p) => p.projectId === projectId
    )
    if (projectIndex !== -1) {
      const userIndex = memberProjectReviewRolesAssignment.value[
        projectIndex
      ].memberAssignment.members.findIndex((u) => u.id === userId)
      if (userIndex !== -1)
        memberProjectReviewRolesAssignment.value[
          projectIndex
        ].memberAssignment.members[userIndex].isManager = true
    }
  }

  function demoteFromManager(userId: number, projectId: Id) {
    const projectIndex = memberProjectReviewRolesAssignment.value.findIndex(
      (p) => p.projectId === projectId
    )
    if (projectIndex !== -1) {
      const userIndex = memberProjectReviewRolesAssignment.value[
        projectIndex
      ].memberAssignment.members.findIndex((u) => u.id === userId)
      if (userIndex !== -1)
        memberProjectReviewRolesAssignment.value[
          projectIndex
        ].memberAssignment.members[userIndex].isManager = false
    }
  }

  function removeMember(userId: number, projectId: Id) {
    const projectIndex = memberProjectReviewRolesAssignment.value.findIndex(
      (p) => p.projectId === projectId
    )
    if (projectIndex !== -1) {
      const project = memberProjectReviewRolesAssignment.value[projectIndex]
      const isAssignedToReviews = Object.values(
        project.reviewRolesAssignment
      ).some((assignments) => {
        const isAssignedAsApprover = assignments.approvers.includes(userId)
        const isAssignedAsAuthor = assignments.authors.includes(userId)
        const isAssignedAsReviewer = assignments.reviewers.includes(userId)

        return (
          isAssignedAsApprover || isAssignedAsAuthor || isAssignedAsReviewer
        )
      })

      if (!isAssignedToReviews) {
        const userIndex = project.memberAssignment.members.findIndex(
          (u) => u.id === userId
        )
        if (userIndex !== -1) {
          project.memberAssignment.members.splice(userIndex, 1)
        } else {
          throw new Error('User not found in project members')
        }
      } else {
        throw new Error('Cannot remove member. Assigned in some reviews.')
      }
    } else {
      throw new Error('Project not found')
    }
  }

  function removeExternalMember(externalMemberId: string, projectId: Id) {
    const projectIndex = memberProjectReviewRolesAssignment.value.findIndex(
      (p) => p.projectId === projectId
    )

    if (projectIndex !== -1) {
      const memberIndex = memberProjectReviewRolesAssignment.value[
        projectIndex
      ].memberAssignment.externalMembers.findIndex(
        (u) => u.id === externalMemberId
      )
      if (memberIndex !== -1) {
        const isAssignedAsExternalApprover = Object.values(
          memberProjectReviewRolesAssignment.value[projectIndex]
            .reviewRolesAssignment.reviewExternalApprovers.externalApprovers
        ).some((externalApprovers) =>
          externalApprovers.includes(externalMemberId)
        )
        if (!isAssignedAsExternalApprover)
          memberProjectReviewRolesAssignment.value[
            projectIndex
          ].memberAssignment.externalMembers.splice(memberIndex, 1)
        else
          throw new Error(
            'Cannot remove external Member. This member is assigned as an external approver in some reviews.'
          )
      }
    }
  }

  ///////////////////////////////////

  function addAuthorToReview(reviewId: number, userId: number, projectId: Id) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (
        project.reviewRolesAssignment[reviewId]?.authors?.some(
          (u) => u === userId
        )
      )
        return

      if (!project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId] = {
          authors: [userId],
          reviewers: [],
          approvers: [],
          externalApprovers: [],
        }
      } else {
        project.reviewRolesAssignment[reviewId].authors.push(userId)
      }
    }
  }

  function addReviewerToReview(
    reviewId: number,
    userId: number,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (
        project.reviewRolesAssignment[reviewId]?.reviewers?.some(
          (u) => u === userId
        )
      )
        return

      if (!project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId] = {
          authors: [],
          reviewers: [userId],
          approvers: [],
          externalApprovers: [],
        }
      } else {
        project.reviewRolesAssignment[reviewId].reviewers.push(userId)
      }
    }
  }

  function addApproverToReview(
    reviewId: number,
    userId: number,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (
        project.reviewRolesAssignment[reviewId]?.approvers?.some(
          (u) => u === userId
        )
      )
        return

      if (!project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId] = {
          authors: [],
          reviewers: [],
          approvers: [userId],
          externalApprovers: [],
        }
      } else {
        project.reviewRolesAssignment[reviewId].approvers.push(userId)
      }
    }
  }

  function removeAuthor(reviewId: number, userId: number, projectId: Id) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId].authors =
          project.reviewRolesAssignment[reviewId].authors.filter(
            (uId) => uId !== userId
          )
      }
    }
  }

  function removeReviewer(reviewId: number, userId: number, projectId: Id) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId].reviewers =
          project.reviewRolesAssignment[reviewId].reviewers.filter(
            (uId) => uId !== userId
          )
      }
    }
  }

  function removeApprover(reviewId: number, userId: number, projectId: Id) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId].approvers =
          project.reviewRolesAssignment[reviewId].approvers.filter(
            (uId) => uId !== userId
          )
      }
    }
  }

  function isMemberAlreadyAssignedToAuthors(
    userId: number,
    reviewId: number,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    return project?.reviewRolesAssignment[reviewId]?.authors?.includes(userId)
  }

  function isMemberAlreadyAssignedToReviewers(
    userId: number,
    reviewId: number,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    return project?.reviewRolesAssignment[reviewId]?.reviewers?.includes(userId)
  }

  function isMemberAlreadyAssignedToApprovers(
    userId: number,
    reviewId: number,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    return project?.reviewRolesAssignment[reviewId]?.approvers?.includes(userId)
  }

  function isExternalMemberAlreadyAssignedToApprovers(
    externalMemberId: string,
    reviewId: number,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    return project?.reviewRolesAssignment[
      reviewId
    ]?.externalApprovers?.includes(externalMemberId)
  }

  function addExternalApproverToReview(
    reviewId: number,
    externalMemberId: string,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (
        project.reviewRolesAssignment[reviewId]?.externalApprovers?.includes(
          externalMemberId
        )
      )
        return

      if (!project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId] = {
          authors: [],
          reviewers: [],
          approvers: [],
          externalApprovers: [externalMemberId],
        }
      } else {
        project.reviewRolesAssignment[reviewId].externalApprovers.push(
          externalMemberId
        )
      }
    }
  }

  function removeExternalApprover(
    reviewId: number,
    externalMemberId: string,
    projectId: Id
  ) {
    const project = memberProjectReviewRolesAssignment.value.find(
      (p) => p.projectId === projectId
    )
    if (project) {
      if (project.reviewRolesAssignment[reviewId]) {
        project.reviewRolesAssignment[reviewId].externalApprovers =
          project.reviewRolesAssignment[reviewId].externalApprovers.filter(
            (mId) => mId !== externalMemberId
          )
      }
    }
  }

  return {
    memberProjectReviewRolesAssignment: readonly(
      memberProjectReviewRolesAssignment
    ),
    addUserToProject,
    addExternalMember,
    promoteToManager,
    demoteFromManager,
    removeMember,
    removeExternalMember,
    addAuthorToReview,
    addReviewerToReview,
    addApproverToReview,
    removeAuthor,
    removeReviewer,
    removeApprover,
    isMemberAlreadyAssignedToAuthors,
    isMemberAlreadyAssignedToReviewers,
    isMemberAlreadyAssignedToApprovers,
    isExternalMemberAlreadyAssignedToApprovers,
    addExternalApproverToReview,
    removeExternalApprover,
  }
}
