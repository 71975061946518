<template>
  <div @dragover="onDragOver" @drop="onDrop" @dragleave="onDragLeave">
    <slot :isDraggedOver="isDraggedOver" />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'

function onDragOver(ev: Event) {
  ev.preventDefault()
  isDraggedOver.value = true
}

function onDragLeave(ev: Event) {
  ev.preventDefault()
  isDraggedOver.value = false
}

const emit = defineEmits<(e: 'dropped', v: string) => void>()

const isDraggedOver = ref(false)

function onDrop(ev: DragEvent) {
  ev.preventDefault()
  isDraggedOver.value = false
  const data = ev.dataTransfer!.getData('text')
  emit('dropped', data)
}
</script>
