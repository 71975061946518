<template>
  <div class="flex">
    <select class="evidence-input rounded-l" @mousedown.stop>
      <option>field 1</option>
    </select>
    <select class="evidence-input" @mousedown.stop>
      <option>does not contain</option>
    </select>
    <input
      placeholder="Enter value"
      class="evidence-input rounded-r"
      @mousedown.stop
    />
  </div>
</template>
