<template>
  <FloatingMenu placement="bottom-start" :arrow="false" ref="floatingMenu">
    <template #activator>
      <button
        @click.stop="openMenu"
        class="bg-white dark:bg-[#275374] dark:border-white inline-flex items-center gap-1 py-1.5 border text-sm border-primary rounded-md px-2 font-normal hover:brightness-110"
      >
        <MagnifyingGlassPlusIcon class="w-4 h-4" />
        <span>Add review</span>
      </button>
    </template>
    <template #content>
      <div class="px-2 py-2 dark:bg-[#4E6875] dark:text-white">
        <TextInput
          type="text"
          v-model="review.name"
          ref="reviewNameInput"
          placeholder="review name"
          @keyup.enter="createReview"
          :rules="[(v) => !!v || 'please enter a review name']"
        />
      </div>
    </template>
  </FloatingMenu>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import useProjects from '@app/views/Projects/use-projects'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import MagnifyingGlassPlusIcon from '@app/components/Icons/MagnifyingGlassPlusIcon.vue'

const reviewNameInput = ref<InstanceType<typeof TextInput>>()

const props = defineProps<{
  projectId: Id
}>()
const floatingMenu = ref()

const review = ref<Review>(new Review({ name: '' }))
const projects = useProjects()

async function openMenu() {
  floatingMenu?.value?.show()
  reviewNameInput?.value?.focus()
}

async function createReview() {
  const isProjectValid = reviewNameInput.value?.validate()
  if (!isProjectValid) return
  await projects.createReview(props.projectId, review.value.name)
  hideAll()
  review.value = new Review({ name: '' })
}
</script>
