<template>
  <teleport to="#toolbar">
    <div class="w-full px-12 py-5">
      <div class="flex gap-5 items-center">
        <div class="inline-flex text-xl font-medium leading-5">Projects</div>
        <CreateProject />
      </div>
    </div>
  </teleport>
  <div class="container px-10 my-8">
    <div class="flex flex-col gap-6">
      <template
        v-for="project in projects.projects.value.sort(
          (y, x) => Number(x.isFavorite) - Number(y.isFavorite)
        )"
        :key="project.id"
      >
        <ProjectTable :project="project" :users="users.users.value" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useProjects from '@app/views/Projects/use-projects'
import useLoading from '@app/composables/use-loading'
import ProjectTable from './Project/ProjectTable.vue'
import useUsers from '@app/composables/use-users'
import CreateProject from './CreateProject.vue'

const loading = useLoading()
const projects = useProjects()

const users = useUsers()
users.refresh()

loading.start()
projects.refresh().then(() => loading.stop())
</script>
