<template>
  <SimpleBar :id="`bar-${id}`" data-simplebar-auto-hide="false">
    <slot />
  </SimpleBar>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import SimpleBar from 'simplebar-vue'
import 'simplebar-vue/dist/simplebar.min.css'
import { v4 as uuid } from 'uuid'
const id = uuid()

const props = withDefaults(
  defineProps<{
    wheelScrollDirection?: 'vertical' | 'horizontal'
  }>(),
  {
    wheelScrollDirection: 'vertical',
  }
)

onMounted(() => {
  if (props.wheelScrollDirection === 'vertical') return
  const simpleBarElement = document.querySelector(
    `#bar-${id} .simplebar-content-wrapper`
  )!
  simpleBarElement.addEventListener('wheel', (event: Event) => {
    event.preventDefault()
    simpleBarElement.scrollLeft += (event as WheelEvent).deltaY
  })
})
</script>

<style>
.simplebar-scrollbar:before {
  background-color: #93b6c5;
}
.simplebar-scrollbar.simplebar-hover:before,
.simplebar-dragging .simplebar-scrollbar:before {
  background-color: #24556a;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}
</style>
