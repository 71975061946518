import { StudyState } from '@app/types'
import { Id } from '../types/id.type'
import { Study } from './study.model'
import { Search } from './search.model'
import { LiteratureReviewPlan } from './literature-review-plan.model'
import { Project } from './project.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'

export type StatsEntry = {
  total: number
  included: number
  duplicate: number
  excludedScreeningStep1Total: number
  excludedScreeningStep2Total: number
  excludedScreeningStep1: { [criterion: string]: number }
  excludedScreeningStep2: { [criterion: string]: number }
  soughtForRetrieval: number
  assessedForEligibility: number
  reportsNotRetrieved: number
  unscreened: number
  quarantined: number
}

export type Stats = {
  overall: StatsEntry
  sources: { [key: string]: StatsEntry }
  searches: { [key: string]: StatsEntry }
  sourceTypes: {
    [key in ImportSourceType]?: StatsEntry
  }
}

export class Review {
  id: Id
  name: string
  projectId: Id
  plan?: LiteratureReviewPlan
  searches?: Search[]
  project?: Project
  constructor(data: Partial<Review>) {
    this.id = data.id ?? 0
    this.name = data.name ?? ''
    this.projectId = data.projectId ?? 0
    this.plan = data.plan
    this.searches = data.searches ?? []
    this.project = data.project
  }

  static create(data: Partial<Review>) {
    const review = new Review({
      ...data,
      searches:
        data.searches?.map(
          (f) =>
            new Search({
              ...f,
              studies: f.studies.map((a) => new Study(a)),
            })
        ) ?? [],
    })
    return review
  }

  get studies(): Study[] {
    return this?.searches?.map((f) => f.studies)?.flat() ?? []
  }

  getStudyState(study: Study): StudyState {
    if (!study) throw new Error('Study not found')
    if (study.isDuplicate) return StudyState.DUPLICATE

    if (study.fullTextScreening === 'Included') return StudyState.INCLUDED
    if (
      !study.reference ||
      !study.abstract ||
      !study.title ||
      (study.abstract[0] === '.' &&
        study.abstract[1] === '.' &&
        study.abstract[2] === '.') ||
      (study.abstract[study.abstract.length - 1] === '.' &&
        study.abstract[study.abstract.length - 2] === '.' &&
        study.abstract[study.abstract.length - 3] === '.')
    )
      return StudyState.QUARANTINED
    if (
      (study.titleAndAbstractScreening &&
        study.titleAndAbstractScreening != 'Included') ||
      (study.fullTextScreening && study.fullTextScreening !== 'Included')
    )
      return StudyState.EXCLUDED
    return StudyState.UNSCREENED
  }

  updateStudy(study: Study) {
    const searchIndex = this.getSearchIndex(study)
    if (searchIndex === -1) throw new Error('Study not found')
    this.searches![searchIndex] = new Search({
      ...this.searches![searchIndex],
      studies: this.searches![searchIndex].studies.map((a) =>
        a.id === study.id ? study : a
      ),
    })
  }

  getSearchIndex(study: Study): number {
    return (
      this.searches?.findIndex((f) =>
        f.studies?.find((a, i) => a.id === study.id)
      ) ?? -1
    )
  }

  get stats(): Stats {
    //#region setup
    const initStatsEntry: StatsEntry = {
      total: 0,
      included: 0,
      duplicate: 0,
      excludedScreeningStep1Total: 0,
      excludedScreeningStep2Total: 0,
      excludedScreeningStep1: {},
      excludedScreeningStep2: {},
      soughtForRetrieval: 0,
      assessedForEligibility: 0,
      reportsNotRetrieved: 0,
      unscreened: 0,
      quarantined: 0,
    }
    this.plan?.screeningPlan?.titleAndAbstractCriteria?.forEach(
      (s1c) => (initStatsEntry.excludedScreeningStep1[s1c] = 0)
    )
    this.plan?.screeningPlan?.fullTextCriteria.forEach(
      (s2c) => (initStatsEntry.excludedScreeningStep2[s2c] = 0)
    )
    const stats: Stats = {
      overall: JSON.parse(JSON.stringify(initStatsEntry)),
      sources: {},
      searches: {},
      sourceTypes: {},
    }

    for (const importSource of this.plan?.importPlan?.importSources ?? []) {
      stats.sources[importSource.id] = JSON.parse(
        JSON.stringify(initStatsEntry)
      )
      stats.sourceTypes[importSource.type] = JSON.parse(
        JSON.stringify(initStatsEntry)
      )
    }

    ;(this.searches ?? []).forEach((search, index) => {
      stats.searches[`search ${index + 1}`] = JSON.parse(
        JSON.stringify(initStatsEntry)
      )
    })

    //#endregion

    this.searches
      ?.map((search) => search.studies)
      .flat()
      .forEach((s) => {
        const index = this.getSearchIndex(s)
        const studyState = this.getStudyState(s)
        let sourceType = s.search!.source?.type

        stats.overall.total += 1
        stats.searches[`search ${index + 1}`].total += 1
        stats.sources[s.search!.source?.id].total += 1
        stats.sourceTypes[sourceType]!.total += 1

        if (s.titleAndAbstractScreening === 'Included') {
          stats.overall.soughtForRetrieval += 1
          stats.searches[`search ${index + 1}`].soughtForRetrieval += 1
          stats.sources[s.search!.source.id].soughtForRetrieval += 1
          stats.sourceTypes[sourceType]!.soughtForRetrieval += 1
        }

        if (s.titleAndAbstractScreening === 'Included' && s.pdfFile) {
          stats.overall.assessedForEligibility += 1
          stats.searches[`search ${index + 1}`].assessedForEligibility += 1
          stats.sources[s.search!.source.id].assessedForEligibility += 1
          stats.sourceTypes[sourceType]!.assessedForEligibility += 1
        }
        if (s.titleAndAbstractScreening === 'Included' && !s.pdfFile) {
          stats.overall.reportsNotRetrieved += 1
          stats.searches[`search ${index + 1}`].reportsNotRetrieved += 1
          stats.sources[s.search!.source.id].reportsNotRetrieved += 1
          stats.sourceTypes[sourceType]!.reportsNotRetrieved += 1
        }
        if (studyState === StudyState.DUPLICATE) {
          stats.overall.duplicate += 1
          stats.searches[`search ${index + 1}`].duplicate += 1
          stats.sources[s.search!.source.id].duplicate += 1
          stats.sourceTypes[sourceType]!.duplicate += 1
        } else if (studyState === StudyState.QUARANTINED) {
          stats.overall.quarantined += 1
          stats.searches[`search ${index + 1}`].quarantined += 1
          stats.sources[s.search!.source.id].quarantined += 1
          stats.sourceTypes[sourceType]!.quarantined += 1
        } else if (studyState === StudyState.INCLUDED) {
          stats.overall.included += 1
          stats.searches[`search ${index + 1}`].included += 1
          stats.sources[s.search!.source.id].included += 1
          stats.sourceTypes[sourceType]!.included += 1
        } else if (
          studyState === StudyState.EXCLUDED &&
          s.titleAndAbstractScreening !== 'Included' &&
          this.plan?.screeningPlan.stepsCount == 2
        ) {
          stats.overall.excludedScreeningStep1Total += 1
          stats.searches[`search ${index + 1}`].excludedScreeningStep1Total += 1
          stats.sources[s.search!.source.id].excludedScreeningStep1Total += 1
          stats.sourceTypes[sourceType]!.excludedScreeningStep1Total += 1

          stats.overall.excludedScreeningStep1[
            s.titleAndAbstractScreening!
          ] += 1
          stats.searches[`search ${index + 1}`].excludedScreeningStep1[
            s.titleAndAbstractScreening!
          ] += 1
          stats.sources[s.search!.source.id].excludedScreeningStep1[
            s.titleAndAbstractScreening!
          ] += 1
          stats.sourceTypes[sourceType]!.excludedScreeningStep1[
            s.titleAndAbstractScreening!
          ] += 1
        } else if (
          studyState === StudyState.EXCLUDED &&
          s.fullTextScreening !== 'Included'
        ) {
          stats.overall.excludedScreeningStep2Total += 1
          stats.searches[`search ${index + 1}`].excludedScreeningStep2Total += 1
          stats.sources[s.search!.source.id].excludedScreeningStep2Total += 1
          stats.sourceTypes[sourceType]!.excludedScreeningStep2Total += 1

          stats.overall.excludedScreeningStep2[s.fullTextScreening] += 1
          stats.searches[`search ${index + 1}`].excludedScreeningStep2[
            s.fullTextScreening
          ] += 1
          stats.sources[s.search!.source.id].excludedScreeningStep2[
            s.fullTextScreening
          ] += 1
          stats.sourceTypes[sourceType]!.excludedScreeningStep2[
            s.fullTextScreening
          ] += 1
        } else if (studyState === StudyState.UNSCREENED) {
          stats.overall.unscreened += 1
          stats.searches[`search ${index + 1}`].unscreened += 1
          stats.sources[s.search!.source.id].unscreened += 1
          stats.sourceTypes[sourceType]!.unscreened += 1
        }
      })
    return stats
  }
}
