import { AppraisalCriterion } from '@core/domain/models/appraisal-criterion.model'
import { Api } from './review-manager-api'

export class AppraisalCriteriaRepository {
  constructor(private _reviewManagerService: Api<any>) {}

  async find(): Promise<AppraisalCriterion[]> {
    const { data } =
      await this._reviewManagerService.api.appraisalCriteriaControllerFind()
    return data.map((d: any) => new AppraisalCriterion(d))
  }
}
