import { Id } from '../types/id.type'
import { FileMeta } from './file-meta.model'
import { Search } from './search.model'

export class Study {
  id: Id
  parentStudyId?: Id
  reviewId: Id
  title?: string
  abstract?: string
  authors: string[]
  doi?: string
  url?: string
  journal?: string
  volumeNumber?: string
  issueNumber?: string
  pagesNumber?: string
  rawData: string
  isDuplicate?: boolean
  isFavorite?: boolean
  complaintDate?: Date
  titleAndAbstractScreening?: string
  fullTextScreening: string
  search?: Search
  publishYear?: string
  pdfFile?: FileMeta
  pdfContent?: string
  reference?: string
  appraisal?: { [key: string]: string }
  oxfordLevelOfEvidence?: string
  studyDataExtraction: { [key: string]: string }
  groupDataExtraction: { [key: string]: string; id: string }[]

  constructor(data: Partial<Study>) {
    this.id = data.id ?? 0
    this.title = data.title
    this.abstract = data.abstract
    this.authors = data.authors ?? []
    this.doi = data.doi
    this.url = data.url
    this.isDuplicate = data.isDuplicate ?? false
    this.isFavorite = data.isFavorite ?? false
    this.complaintDate = data.complaintDate
    this.search = data.search
    this.titleAndAbstractScreening = data.titleAndAbstractScreening ?? ''
    this.fullTextScreening = data.fullTextScreening ?? ''
    this.reviewId = data.reviewId ?? 0
    this.parentStudyId = data.parentStudyId
    this.publishYear = data.publishYear
    this.pdfFile = data.pdfFile
    this.rawData = data.rawData ?? ''
    this.journal = data.journal
    this.reference = data.reference ?? ''
    this.appraisal = data.appraisal ?? {}
    this.oxfordLevelOfEvidence = data.oxfordLevelOfEvidence ?? ''
    this.studyDataExtraction = data.studyDataExtraction ?? {}
    this.groupDataExtraction = data.groupDataExtraction ?? []
    this.pagesNumber = data.pagesNumber ?? ''
    this.issueNumber = data.issueNumber ?? ''
    this.volumeNumber = data.volumeNumber ?? ''
    this.pdfContent = data.pdfContent
  }

  public static create(data: {
    reviewId: Id
    rawData: string
    title?: string
    abstract?: string
    authors?: string[]
    doi?: string
    url?: string
    search?: Search
    publishYear?: string
    journal?: string
    volumeNumber?: string
    issueNumber?: string
    pagesNumber?: string
  }) {
    const study = new Study(data)
    if (!data.reviewId) throw new Error('review is required')
    return study
  }

  get DoiLink() {
    return this.doi ? `https://doi.org/${this.doi}` : ''
  }

  markAsDuplicate() {
    if (this.titleAndAbstractScreening || this.fullTextScreening)
      throw new Error('cannot mark as duplicate, screening applied')
    this.isDuplicate = true
  }

  markAsNotDuplicate() {
    this.isDuplicate = false
  }

  setTitleAndAbstractScreening(key: string) {
    this.titleAndAbstractScreening = key
  }

  clearTitleAndAbstractScreening() {
    this.titleAndAbstractScreening = ''
    this.fullTextScreening = ''
  }

  setFullStudyScreening(key: string) {
    if (!this.titleAndAbstractScreening)
      throw new Error('title and abstract screening missing')
    this.fullTextScreening = key
  }

  clearFullStudyScreening() {
    this.fullTextScreening = ''
  }
}
