<template>
  <FloatingMenu
    placement="bottom-start"
    ref="floatingMenu"
    :offset="[0, 0]"
    @hide="reset"
  >
    <template #activator>
      <slot name="activator" :show="show" />
    </template>

    <template #content>
      <div
        class="w-[350px] p-2 shadow-2xl absolute bg-white space-y-2 border bored border-[#D9E4EA] dark:bg-[#103047]"
      >
        <GenericAutoComplete
          :items="remainingUsers"
          :item-options="{
            displayProperty: 'email',
            filterProperties: ['email', 'firstName', 'lastName'],
            valueProperty: 'id',
          }"
          v-model="selected"
          :placeholder="`add member / assign as ${props.role.toLocaleLowerCase()}`"
          @update:model-value="addAndAssignUser"
        >
          <template #item="{ firstName, lastName, email }">
            {{ email }}
            {{ firstName && lastName ? `(${firstName} ${lastName})` : '' }}
          </template>
        </GenericAutoComplete>
      </div>
    </template>
  </FloatingMenu>
</template>

<script setup lang="ts">
import useUsers from '@app/composables/use-users'
import projectPlan, { ReviewRoles } from '../projectPlan'

import { computed, ref } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { Id } from '@core/domain/types/id.type'

const props = defineProps<{
  projectId: Id
  reviewId: Id
  role: ReviewRoles
}>()

const users = useUsers()
const floatingMenu = ref()
const isShown = ref(false)

async function show() {
  isShown.value = true

  floatingMenu?.value?.show()
}
const {
  memberProjectReviewRolesAssignment,
  addUserToProject,
  addReviewerToReview,
  addApproverToReview,
  addAuthorToReview,
  isMemberAlreadyAssignedToReviewers,
  isMemberAlreadyAssignedToApprovers,
  isMemberAlreadyAssignedToAuthors,
} = projectPlan()

const projectMembers = computed(() => {
  return (
    memberProjectReviewRolesAssignment.value?.find(
      (p) => p.projectId === props.projectId
    )?.memberAssignment.members ?? []
  )
})

const selected = ref(0)

function reset() {
  isShown.value = false
}

function addAndAssignUser(userId: number) {
  selected.value = 0
  const user = users.users.value.find((u) => u.id === userId)
  if (user) {
    const isUserAlreadyInProject = projectMembers?.value?.some(
      (u) => u.id === user.id
    )
    if (!isUserAlreadyInProject) {
      addUserToProject(user, props.projectId)
    }
    if (props.role === ReviewRoles.AUTHOR)
      addAuthorToReview(props.reviewId, userId, props.projectId)
    else if (props.role === ReviewRoles.APPROVER)
      addApproverToReview(props.reviewId, userId, props.projectId)
    else if (props.role === ReviewRoles.REVIEWER)
      addReviewerToReview(props.reviewId, userId, props.projectId)
  }
  floatingMenu?.value?.hide()
}

const remainingUsers = computed(() =>
  users.users.value.filter(
    (user) =>
      !projectMembers?.value?.some((member) => member.id === user.id) ||
      !isUserAlreadyAssignedToRole(user.id!)
  )
)

function isUserAlreadyAssignedToRole(userId: Id) {
  if (props.role === ReviewRoles.AUTHOR) {
    return isMemberAlreadyAssignedToAuthors(
      userId,
      props.reviewId,
      props.projectId
    )
  } else if (props.role === ReviewRoles.APPROVER) {
    return isMemberAlreadyAssignedToApprovers(
      userId,
      props.reviewId,
      props.projectId
    )
  } else if (props.role === ReviewRoles.REVIEWER) {
    return isMemberAlreadyAssignedToReviewers(
      userId,
      props.reviewId,
      props.projectId
    )
  }
}
</script>
