import { Answer } from './answer.model'

export class AppraisalCriterion {
  id: string
  title?: string
  question?: string
  answers?: Answer[]

  constructor(data: Partial<AppraisalCriterion>) {
    this.id = data.id ?? ''
    this.title = data.title
    this.question = data.question
    this.answers = data.answers
  }
}
