<template>
  <!-- <div class="p-20 h-full w-full">
    <div class="flex gap-4 mb-12">
      <button
        @click="activeTab = Tab.attributesBuilder"
        class="px-4 py-2 hover:bg-green-700"
        :class="[
          activeTab === Tab.attributesBuilder
            ? 'bg-green-700'
            : 'bg-green-700/40',
        ]"
      >
        attributesBuilder
      </button>
      <button
        @click="activeTab = Tab.attributes"
        class="px-4 py-2 hover:bg-green-700"
        :class="[
          activeTab === Tab.attributes ? 'bg-green-700' : 'bg-green-700/40',
        ]"
      >
        attributes
      </button>
    </div>
    <AttributesBuilder v-show="activeTab === Tab.attributesBuilder" />
    <Attributes v-show="activeTab === Tab.attributes" />
  </div> -->
  <PubmedSearch />
</template>
<script setup lang="ts">
import AttributesBuilder from './AttributesBuilder.vue'
import Attributes from './Attributes.vue'
import { ref } from 'vue'
import PubmedSearch from './PubmedSearch.vue'

enum Tab {
  attributesBuilder,
  attributes,
}

const activeTab = ref<Tab>(Tab.attributesBuilder)
</script>
