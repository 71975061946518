<template>
  <div
    class="flex flex-col gap-4 rounded border border-white/30 bg-white/10 p-4"
  >
    <Condition v-for="condition in conditions" />
  </div>
</template>
<script setup lang="ts">
import Condition from './Condition.vue'
import { ref } from 'vue'

const conditions = ref([{}, {}])
</script>
