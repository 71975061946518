import { AppraisalCriterion } from "@core/domain/models/appraisal-criterion.model";
import { AppraisalCriteriaRepository } from "@infrastructure/datasource/appraisal-criteria.repository";

export class AppraisalCriteriaService {
    constructor(private appraisalCriteriaRepository: AppraisalCriteriaRepository){}

    async find(): Promise<AppraisalCriterion[]> {
        return this.appraisalCriteriaRepository.find()
      }
}
