<template>
  <Modal ref="modal">
    <template #activator>
      <slot name="activator" :show="show" :studiesCount="studiesCount" />
    </template>
    <template #content>
      <div class="w-screen h-screen bg-white flex flex-col">
        <div class="bg-faded flex items-center justify-between pt-2 pr-2 pl-4">
          <p class="text-primary truncate">
            {{ studiesPdfFiles[activePdfIndex].title }}
          </p>
          <button @click="hide">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="flex gap-2 px-4 py-4 bg-faded overflow-x-auto w-full">
          <template v-for="(studyFile, index) in studiesPdfFiles">
            <button
              @click="openPdf(studyFile)"
              class="border px-2 py-1 shrink-0 relative rounded-md"
              :class="[
                activePdfIndex === index
                  ? 'bg-primary text-white'
                  : 'bg-white text-primary',
              ]"
            >
              {{ studyFile.studyReference }}
              <button
                @click.stop="removeStudyPdfFile(studyFile.studyId)"
                class="absolute -top-1.5 -right-1 leading-none rounded-lg bg-blue-600 text-white px-0.5 py-0.5"
              >
                <XIcon class="w-3 h-3" />
              </button>
            </button>
          </template>
        </div>
        <div class="h-full w-full">
          <template
            v-for="(studyFile, index) in studiesPdfFiles"
            :key="studyFile?.studyId"
          >
            <KeepAlive>
              <PdfViewer
                :file="openedPdfFiles[studyFile?.studyId]"
                v-if="activePdfIndex === index"
                :fileName="studyFile?.fileName ?? ''"
              />
            </KeepAlive>
          </template>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@app/components/Global/Modal/Modal.vue'
import PdfViewer from '@app/components/Global/PdfViewer.vue'
import { ref, watchEffect } from 'vue'
import useGlobalPdfViewer from '@app/composables/use-global-pdf-viewer'
import XIcon from '@app/components/Icons/XIcon.vue'

const modal = ref()
const {
  studiesCount,
  show,
  hide,
  isVisible,
  openedPdfFiles,
  activePdfIndex,
  removeStudyPdfFile,
  studiesPdfFiles,
  openPdf,
} = useGlobalPdfViewer()

watchEffect(() => {
  if (isVisible.value) modal.value?.show()
  else modal.value?.hide()
})
</script>
