<template>
  <div class="px-8 py-10 space-y-4">
    <GenericAutoComplete
      :items="filteredUsers"
      :item-options="{
        displayProperty: 'email',
        filterProperties: ['email', 'firstName', 'lastName'],
        valueProperty: 'id',
      }"
      placeholder="Select a user"
      @update:model-value="(userId:number ) => projects.addUserToProject(project.id!, findUserById(userId))"
    />
    <div>
      <div v-for="user in project.users">
        {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useUsers from '@app/composables/use-users'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { computed } from 'vue'

const props = defineProps<{
  project: Project
}>()

const projects = useProjects()
const users = useUsers()

function findUserById(userId: number): User {
  return users.users.value.find((u) => u.id === userId) as User
}

const filteredUsers = computed(() =>
  users.users.value.filter((u) =>
    props.project.users?.every((pu) => pu.id !== u.id)
  )
)
</script>
