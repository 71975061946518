<template>
  <div>
    <slot name="activator" :isShown="isShown" :toggle="togglePanel" />
    <div
      class="scale-wrapper m-0"
      :style="{ maxHeight: maxHeight }"
      :class="[overflow]"
    >
      <div ref="content" class="scale-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
const { disabeOpenAnimation, disabeCloseAnimation } = withDefaults(
  defineProps<{
    disabeOpenAnimation?: boolean
    disabeCloseAnimation?: boolean
  }>(),
  {
    disabeCloseAnimation: false,
    disabeOpenAnimation: false,
  }
)
const modelValue = defineModel<boolean | undefined>({ default: true })
const isShown = ref(modelValue.value)
const overflow = ref<'overflow-visible' | 'overflow-hidden'>(
  isShown.value ? 'overflow-visible' : 'overflow-hidden'
)
watch(isShown, () => {
  modelValue.value = isShown.value
})

watch(modelValue, () => {
  if (isShown.value !== modelValue.value) togglePanel()
})
const content = ref<HTMLDivElement>()
const maxHeight = ref(isShown.value ? 'none' : '0')

function togglePanel() {
  const height = content.value ? content.value.offsetHeight : 0
  if (isShown.value) {
    if (!disabeCloseAnimation) {
      maxHeight.value = `${height}px`
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          maxHeight.value = '0'
          overflow.value = 'overflow-hidden'
        })
      })
    } else {
      maxHeight.value = '0'
      overflow.value = 'overflow-hidden'
    }
  } else {
    if (!disabeOpenAnimation) {
      maxHeight.value = `${height}px`
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          maxHeight.value = `${height}px`
        })
      })
    } else {
      maxHeight.value = 'none'
    }
    setTimeout(() => {
      maxHeight.value = 'none'
      overflow.value = 'overflow-visible'
    }, 200)
  }
  isShown.value = !isShown.value
}
</script>

<style scoped>
.scale-wrapper {
  transition: max-height 0.2s ease-in-out;
}
</style>
