import { Study } from '@core/domain/models/study.model'
import { DataExtractionFieldType } from '@core/domain/models/data-extraction-field'
import { Search } from '@core/domain/models/search.model'
import { Project } from '@core/domain/models/project.model'
import { ScreeningPlan } from '@core/domain/models/screening-plan.model'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { Api } from './review-manager-api'
import { MetaData } from '@core/domain/types/meta-data.type'

export class ReviewsRepository {
  constructor(private _reviewManagerService: Api<any>) {}
  async findById(reviewId: Id): Promise<Review> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindById(reviewId)
    return Review.create(data as Partial<Review>)
  }

  async delete(selectedReviewsIds: Id[]) {
    await this._reviewManagerService.instance.delete(
      `/api/reviews/${selectedReviewsIds}`
    )
  }

  async importCitationFile(
    reviewId: Id,
    search: Search,
    citationFiles?: File[],
    queryFile?: File
  ): Promise<void> {
    const formData = new FormData()
    formData.append(
      'search',
      JSON.stringify({
        personUndertakingSearch: search.personUndertakingSearch,
        searchDate: search.searchDate,
        source: search.source.id,
        searchQuery: search.searchQuery,
        url: search.url,
        parentStudyId: search.parentStudyId,
        filter: '',
      })
    )
    formData.append('queryFile', queryFile!)
    citationFiles?.forEach((cf) => {
      formData.append('citationFiles', cf)
    })

    await this._reviewManagerService.instance.post(
      `/api/Reviews/${reviewId}/studies/import`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  async findStudies(reviewId: Id) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindStudies(
        reviewId
      )
    return data.map((a: any) => new Study(a))
  }

  async markStudyAsDuplicate(
    reviewId: Id,
    studyId: Id,
    parentStudyId: Id
  ): Promise<Study> {
    const { data } = await this._reviewManagerService.instance.patch(
      `/api/reviews/${reviewId}/studies/${studyId}/mark-as-duplicate`,
      { parentStudyId }
    )
    return new Study(data)
  }

  async markStudyAsNotDuplicate(reviewId: Id, studyId: Id): Promise<Study> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerMarkStudyAsNotDuplicate(
        reviewId,
        studyId
      )
    return new Study(data)
  }

  async setStudyTitleAndAbstractScreening(
    reviewId: Id,
    studyId: Id,
    titleAndAbstractScreening: string
  ) {
    const { data } = await this._reviewManagerService.instance.patch(
      `/api/reviews/${reviewId}/studies/${studyId}/title-and-abstract-screening`,
      { titleAndAbstractScreening }
    )
    return new Study(data)
  }

  async clearStudyTitleAndAbstractScreening(reviewId: Id, studyId: Id) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerClearStudyTitleAndAbstractScreening(
        reviewId,
        studyId
      )
    return new Study(data)
  }

  async setStudyFullTextScreening(
    reviewId: Id,
    studyId: Id,
    fullTextScreening: string
  ) {
    const { data } = await this._reviewManagerService.instance.patch(
      `/api/reviews/${reviewId}/studies/${studyId}/full-text-screening`,
      { fullTextScreening }
    )
    return new Study(data)
  }

  async clearStudyFullTextScreening(reviewId: Id, studyId: Id) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerClearStudyFullTextScreening(
        reviewId,
        studyId
      )
    return new Study(data)
  }

  async deleteSearchStudies(reviewId: Id, search: Search) {
    await this._reviewManagerService.api.reviewsControllerDeleteSearchStudies(
      reviewId,
      search.id!
    )
  }

  async planScreening(
    reviewId: number,
    data: {
      stepsCount: 1 | 2
      titleAndAbstractCriteria?: string[]
      fullTextCriteria: string[]
    }
  ) {
    const { data: responseData } =
      await this._reviewManagerService.api.reviewsControllerPlanScreening(
        reviewId,
        data
      )
    return new ScreeningPlan(responseData)
  }

  async lockScreeningPlan(reviewId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerLockScreeningPlan(
      reviewId
    )
  }

  async findSearches(reviewId: Id): Promise<Search[]> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindSearches(
        reviewId
      )
    return data.map((d: any) => new Search(d))
  }

  async findProject(reviewId: Id): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindProject(
        reviewId
      )
    return new Project(data as Partial<Project>)
  }

  async favoriteStudy(reviewId: Id, studyId: Id) {
    await this._reviewManagerService.api.reviewsControllerFavoriteStudy(
      reviewId,
      studyId
    )
  }

  async unfavoriteStudy(reviewId: Id, studyId: Id) {
    await this._reviewManagerService.api.reviewsControllerUnfavoriteStudy(
      reviewId,
      studyId
    )
  }

  async updateName(reviewId: Id, name: string) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerUpdateName(
        reviewId,
        { name }
      )
    return new Review(data as Partial<Review>)
  }

  async uploadStudyPdfFile(reviewId: Id, studyId: Id, file: File) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerUploadStudyPdfFile(
        reviewId,
        studyId,
        { file }
      )
    return new Study(data as Partial<Study>)
  }

  async getStudyPdfFile(reviewId: Id, studyId: Id): Promise<Blob> {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/reviews/${reviewId}/studies/${studyId}/pdf`,
      {
        responseType: 'blob',
      }
    )

    return data as unknown as Blob
  }

  async downloadSearchCitationFile(reviewId: Id, searchId: Id): Promise<Blob> {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/reviews/${reviewId}/searches/${searchId}/citation-file`,
      {
        responseType: 'blob',
      }
    )

    return data as unknown as Blob
  }

  async deleteStudyPdfFile(reviewId: Id, studyId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteStudiesPdfFile(
      reviewId,
      studyId
    )
  }

  async downloadPdfZip(reviewId: Id): Promise<Blob> {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/reviews/${reviewId}/studies/zip`,
      {
        responseType: 'blob',
      }
    )

    return data as unknown as Blob
  }

  async editStudyAbstract(reviewId: Id, studyId: Id, abstract: string) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerEditStudyAbstract(
        reviewId,
        studyId,
        { abstract }
      )
    return new Study(data as Partial<Study>)
  }

  async addImportSourceToPlan(reviewId: Id, importSourceId: string) {
    await this._reviewManagerService.api.reviewsControllerAddImportSourceToPlan(
      reviewId,
      {
        importSourceId,
      }
    )
  }

  async removeImportSourceFromPlan(reviewId: Id, importSourceId: string) {
    await this._reviewManagerService.api.reviewsControllerRemoveImportSourceFromPlan(
      reviewId,
      {
        importSourceId,
      }
    )
  }

  async enableStudiesAppraisalImdrfMdce2019(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerEnableStudiesAppraisalImdrfMdce2019(
      reviewId
    )
  }

  async enableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerEnableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId
    )
  }

  async disableStudiesAppraisal(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerDisableStudiesAppraisal(
      reviewId
    )
  }
  async disableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerDisableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId
    )
  }

  async submitComplaint(
    reviewId: Id,
    studyId: Id,
    complaintData: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    }
  ): Promise<Date> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerSubmitComplaint(
        reviewId,
        studyId,
        complaintData
      )
    return new Date(data as unknown as string)
  }

  async appraiseStudy(
    reviewId: Id,
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string }
  ) {
    return this._reviewManagerService.api.reviewsControllerAppraiseStudy(
      reviewId,
      studyId,
      { criterionId: appraisal.criterionId, gradeId: appraisal.gradeId }
    )
  }

  async setOxfordLevelOfEvidenceStudy(
    reviewId: Id,
    studyId: Id,
    oxfordLevelOfEvidence: OxfordLevelOfEvidenceType
  ) {
    return this._reviewManagerService.api.reviewsControllerSetOxfordLevelOfEvidenceStudy(
      reviewId,
      studyId,
      { oxfordLevelOfEvidence: oxfordLevelOfEvidence }
    )
  }

  async cleaStudyOxfordLevelOfEvidence(reviewId: Id, studyId: Id) {
    return this._reviewManagerService.api.reviewsControllerClearStudyOxfordLevelOfEvidence(
      reviewId,
      studyId
    )
  }

  async duplicateReview(reviewId: Id, projectId: Id) {
    return this._reviewManagerService.api.reviewsControllerDuplicateReview(
      reviewId,
      { projectId: projectId }
    )
  }

  async clearStudiesAppraisal(reviewId: Id, studyId: Id, criterionId: string) {
    return this._reviewManagerService.api.reviewsControllerClearStudyAppraisal(
      reviewId,
      studyId,
      criterionId
    )
  }

  async addStudyDataExtractionField(
    reviewId: Id,
    command: { name: string; type: DataExtractionFieldType }
  ) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerAddStudyDataExtractionField(
        reviewId,
        { field: command }
      )
    return data
  }

  async updateStudyDataExtraction(
    reviewId: Id,
    studyId: Id,
    fieldId: string,
    value: string
  ) {
    return this._reviewManagerService.api.reviewsControllerUpdateStudyDataExtraction(
      reviewId,
      studyId,
      { data: { [fieldId]: value } }
    )
  }

  async updateStudy(reviewId: Id, studyId: Id, metaData: MetaData) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerUpdateStudy(
        reviewId,
        studyId,
        metaData
      )
    return new Study(data as Partial<Study>)
  }

  async isCitationFileAlreadyImported(
    reviewId: Id,
    citationFileChecksum: string
  ) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerIsCitationFileAlreadyImported(
        reviewId,
        citationFileChecksum
      )

    return data
  }
}
