<template>
  <div :class="class">
    <label v-if="label" class="block text-sm font-bold" :for="id">
      {{ label }}
    </label>
    <select
      ref="select"
      class="w-full p-2 text-grey-darker border-gray-400 border-0 rounded-sm bg-zinc-50 text-primary dark:bg-[#4E6875] dark:text-white"
      :value="value"
      @change="onChange(($event?.target as HTMLSelectElement)?.value)"
    >
      <option disabled class="dark:text-[#3898e1]" value="">
        {{ placeholder }}
      </option>
      <option
        v-for="item in items"
        :value="item.value"
        :title="item.title"
        :selected="modelValue === item.value"
      >
        {{ item.text }}
      </option>
    </select>
    <p v-if="error" class="text-red-600 text-xs italic mt-2">{{ error }}</p>
  </div>
</template>

<script lang="ts" setup>
import { SelectListItem } from '@app/types'
import { computed, nextTick, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const select = ref<HTMLSelectElement>()
const id = uuidv4()

const props = withDefaults(
  defineProps<{
    items: SelectListItem[]
    modelValue?: string
    placeholder?: string
    rules?: ((v: string) => boolean | string)[]
    label?: string
    class?: string
  }>(),
  {
    modelValue: '',
    class: '',
    rules: () => [],
    placeholder: '',
    label: '',
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const value = computed<string>({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  },
})

function onChange(selectedValue: string) {
  value.value = selectedValue
  // https://github.com/vuejs/vue/issues/293
  nextTick(() => validate())
}

const error = ref<string>()

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  error.value = errors.join(' ')
  return errors.length <= 0
}
function reset() {
  value.value = ''
  error.value = ''
}

defineExpose({
  validate,
  reset,
})
</script>
