<template>
  <NodeToolbar
    :is-visible="true"
    :position="Position.Left"
    class="flex gap-4 rounded-full"
  >
    <button
      class="p-2 rounded-full bg-[#284559] flex gap-2 items-center"
      @mouseenter="isLeftButtonVisible = true"
      @mouseleave="isLeftButtonVisible = false"
      @click="emit('addBranch', 'left')"
    >
      <div class="bg-white rounded-full p-2">
        <PlusIcon class="w-8 h-8 text-blue-cyan stroke-[4]" />
      </div>
      <div class="text-xl" :class="{ hidden: !isLeftButtonVisible }">
        Add condition group
      </div>
    </button>
  </NodeToolbar>
  <NodeToolbar
    :is-visible="true"
    :position="Position.Right"
    class="flex gap-4 rounded-full"
  >
    <button
      class="p-2 rounded-full bg-[#284559] flex gap-2 items-center"
      @mouseenter="isRightButtonVisible = true"
      @mouseleave="isRightButtonVisible = false"
      @click="emit('addBranch', 'right')"
    >
      <div class="bg-white rounded-full p-2">
        <PlusIcon class="w-8 h-8 text-blue-cyan stroke-[4]" />
      </div>
      <div
        class="text-xl transition-all origin-left"
        :class="{ hidden: !isRightButtonVisible }"
      >
        Add condition group
      </div>
    </button>
  </NodeToolbar>
  <div>
    <div class="bg-white/20 rounded-xl">
      <button
        class="px-4 py-2 rounded-xl"
        :class="{ 'bg-green-400': value === 'AND' }"
        @click="value = 'AND'"
      >
        AND
      </button>
      <button
        class="px-4 py-2 rounded-xl"
        :class="{ 'bg-blue-500': value === 'OR' }"
        @click="value = 'OR'"
      >
        OR
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { NodeToolbar } from '@vue-flow/node-toolbar'
import { Position } from '@vue-flow/core'
import PlusIcon from '@app/components/Icons/PlusIcon.vue'
const value = ref('AND')
const isLeftButtonVisible = ref(false)
const isRightButtonVisible = ref(false)

const emit = defineEmits<{
  (e: 'addBranch', position: 'left' | 'right'): void
}>()
</script>
