export class ScreeningPlan {
  stepsCount: 1 | 2
  titleAndAbstractCriteria?: string[]
  fullTextCriteria: string[]
  isLocked?: boolean
  constructor(data: Partial<ScreeningPlan>) {
    this.stepsCount = data.stepsCount ?? 1
    this.titleAndAbstractCriteria = data.titleAndAbstractCriteria ?? []
    this.fullTextCriteria = data.fullTextCriteria ?? []
    this.isLocked = data.isLocked ?? false
  }

  static create(data: {
    stepsCount: 1 | 2
    titleAndAbstractCriteria?: string[]
    fullTextCriteria: string[]
  }): ScreeningPlan {
    const screeningPlan = new ScreeningPlan(data)
    if (
      (screeningPlan.titleAndAbstractCriteria?.length ?? 0) <= 0 &&
      screeningPlan.stepsCount === 2
    )
      throw new Error('title and abstract screening criteria cannot be empty')
    if ((screeningPlan.fullTextCriteria?.length ?? 0) <= 0)
      throw new Error('full text screening criteria cannot be empty')
    return screeningPlan
  }
}
