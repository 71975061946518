import { Api } from './review-manager-api'
import { SnackbarState } from '@app/types'
import { UsersService } from '@core/application/users.service'
import { HttpException } from '@core/exceptions/http.exception'
import { HttpClient } from '@infrastructure/datasource/http-client'
import useSnackbar from '@app/composables/use-snackbar'
import useAuth from '@app/composables/use-auth'
import { router } from '@app/router'

export class ApiInterceptors {
  constructor(
    private _httpClient: HttpClient,
    private _reviewManagerService: Api<any>,
    private usersService: UsersService
  ) {}

  registerInterceptors() {
    this._reviewManagerService.instance.interceptors.response.use(
      (response) => response,
      (e) => {
        const error = e as HttpException
        if (error.response.data.statusCode === 403) {
          return this.handleForbiddenError()
        } else {
          return Promise.reject(error)
        }
      }
    )
  }

  private async handleForbiddenError() {
    const _useAuth = useAuth()
    const snackbar = useSnackbar()
    await this.usersService.saveAuthToken('')
    this.usersService.setAuthHeader('')
    _useAuth.logout()
    this._httpClient.setToken('')
    router.push('/login')
    snackbar.show(SnackbarState.ERROR, 'User is disabled')
  }
}
