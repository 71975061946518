<template>
  <div
    @dragstart="drag"
    @dragend="onDragEnd"
    ref="draggableElement"
    draggable="true"
  >
    <slot :isDragged="isDragged" />
  </div>
</template>

<script setup lang="ts">
import { useMouse } from '@vueuse/core'
import { ref, watch } from 'vue'

const props = defineProps<{
  id: string
}>()
const emit = defineEmits<(e: 'update:isDragged', v: boolean) => void>()

const { x, y } = useMouse()

const isDragged = ref(false)

const draggableElement = ref<HTMLElement | null>(null)
const placeholderElement = ref<HTMLElement | null>(null)

function drag(event: DragEvent) {
  window.getSelection()?.removeAllRanges()
  isDragged.value = true
  const el = draggableElement.value?.cloneNode(true) as HTMLElement
  placeholderElement.value = el
  el.style.position = 'fixed'
  el.style.top = '-999px'
  el.style.left = '-999px'
  el.style.zIndex = '9999999999999'
  el.style.pointerEvents = 'none'
  document.body.appendChild(el)
  const img = new Image()
  event.dataTransfer?.setDragImage(img, 0, 0)
  event.dataTransfer?.setData('text', props.id)
  emit('update:isDragged', true)
}

watch([x, y], () => {
  if (placeholderElement.value) {
    placeholderElement.value.style.top =
      y.value - placeholderElement.value.clientHeight / 2 + 'px'
    placeholderElement.value.style.left =
      x.value - placeholderElement.value.clientWidth / 2 + 'px'
  }
})

function onDragEnd(event: DragEvent) {
  isDragged.value = false
  event.dataTransfer?.clearData('text')
  emit('update:isDragged', false)
  document.body.removeChild(placeholderElement.value!)
  placeholderElement.value = null
}
</script>
