<template>
  <div class="w-full" ref="wrapperElement">
    <div class="w-full relative">
      <div v-if="multiple" class="chip-container">
        <span
          v-for="val in (value as (string | number)[])"
          :key="val"
          class="chip"
        >
          {{ getDisplayValue(val) }}
        </span>
      </div>
      <input
        type="text"
        :value="searchQuery"
        @input="onSearchQueryChange(($event.target as HTMLInputElement).value)"
        class="evidence-input w-full dark:bg-[#103047] dark:text-white"
        :placeholder="placeholder"
        @focus="onFocus"
        @blur="isOpen = false"
        ref="textInput"
      />
      <div class="absolute right-3 top-3">
        <ChevronDownIcon
          class="w-4 h-4 text-primary stroke-[3] transition-all dark:text-white"
          :class="{
            'rotate-180': isOpen,
          }"
        />
      </div>
    </div>

    <div ref="content" class="flex flex-col items-start border divide-y z-50">
      <Scrollbar class="max-h-[200px] w-full dark:bg-[#103047] dark:text-white">
        <button
          v-for="item in filteredItems"
          :key="(item[itemOptions.valueProperty] as unknown as string)"
          @click="selectItem(item)"
          class="px-4 py-2 hover:bg-gray-200 dark:hover:bg-[#36576D] w-full text-left"
        >
          <slot name="item" v-bind="item">
            {{ item[itemOptions.displayProperty] }}
          </slot>
        </button>
        <div v-if="filteredItems.length === 0" class="px-4 py-2 text-gray-500">
          no items found.
        </div>
      </Scrollbar>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T">
import tippy, { Instance, Props } from 'tippy.js'
import { ref, computed, nextTick, onMounted, watchEffect } from 'vue'
import { createDebounce } from '@app/utils/debounce'
import { ItemOptions } from './types'
import { onClickOutside } from '@vueuse/core'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import Scrollbar from '../../Scrollbar.vue'

const props = withDefaults(
  defineProps<{
    items: T[]
    itemOptions: ItemOptions<T>
    placeholder?: string
    debounce?: number
    multiple?: boolean
  }>(),
  {
    debounce: 0,
  }
)

const isOpen = ref(false)
const wrapperElement = ref()
onClickOutside(wrapperElement, (event) => reset())
const value = defineModel<string | number | null | Array<string | number>>()
const textInput = ref<HTMLInputElement>()
const content = ref<Element>()
const searchQuery = ref('')

watchEffect(() => {
  if (value.value !== null) {
    const selectedItem = props.items.find(
      (item) =>
        (item[props.itemOptions.valueProperty] as unknown as
          | string
          | number) === value.value
    )
    if (selectedItem) {
      searchQuery.value = String(
        selectedItem[props.itemOptions.displayProperty]
      )
    } else {
      searchQuery.value = ''
    }
  }
})

const debounce = createDebounce()

let tippyInstance: Instance<Props> | null = null

onMounted(() => {
  tippyInstance = tippy(textInput.value as Element, {
    content: content.value,
    hideOnClick: false,
    duration: [300, 0],
    allowHTML: true,
    offset: [0, 0],
    trigger: 'manual',
    placement: 'bottom-start',
    interactive: true,
    animation: 'shift-away',
    arrow: false,
    theme: 'light-border',
    maxWidth: 'none',
    onShow(instance) {
      const inputWidth = textInput.value!.offsetWidth
      instance.popperInstance?.setOptions({
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              adaptive: false,
              gpuAcceleration: false,
            },
          },
        ],
      })
      instance.popper.style.width = `${inputWidth}px`
    },
  })
})

function onSearchQueryChange(v: string) {
  debounce(() => {
    searchQuery.value = v
  }, props.debounce)
}

const filteredItems = computed(() => {
  const query = searchQuery.value.toLowerCase()
  const { filterProperties } = props.itemOptions

  return props.items.filter((item) => {
    return filterProperties.some((prop) => {
      const value = String(item[prop] as unknown as string).toLowerCase()
      return value.includes(query)
    })
  })
})

function selectItem(item: T) {
  if (props.multiple) {
    if (Array.isArray(value.value)) {
      value.value.push(
        item[props.itemOptions.valueProperty] as unknown as string | number
      )
    } else {
      value.value = [
        item[props.itemOptions.valueProperty] as unknown as string | number,
      ]
    }
  } else {
    value.value = item[props.itemOptions.valueProperty] as unknown as
      | string
      | number
  }
  nextTick(() => tippyInstance?.hide())
}

function onFocus() {
  isOpen.value = true
  tippyInstance?.show()
  textInput.value?.select()
  searchQuery.value = ''
}

function reset() {
  const resetValue = props.items.find(
    (item) =>
      (item[props.itemOptions.valueProperty] as unknown as string | number) ===
      value.value
  )
  searchQuery.value = resetValue
    ? String(resetValue[props.itemOptions.displayProperty])
    : ''
  nextTick(() => tippyInstance?.hide())
}

function getDisplayValue(val: string | number) {
  const item = props.items.find(
    (item) =>
      (item[props.itemOptions.valueProperty] as unknown as string | number) ===
      val
  )
  return item ? item[props.itemOptions.displayProperty] : ''
}
</script>

<style>
.chip-container {
  display: flex;
  flex-wrap: wrap;
}
.chip {
  margin: 4px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 12px;
}
</style>
