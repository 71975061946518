import { Id } from '../types/id.type'
import { Role } from '../types/role.type'

export class User {
  id?: Id
  email: string
  role: Role
  firstName: string
  lastName: string
  isDisabled: boolean
  constructor(data: Partial<User>) {
    this.firstName = data.firstName ?? ''
    this.lastName = data.lastName ?? ''
    this.email = data.email ?? ''
    this.id = data.id
    this.role = data.role ?? Role.USER
    this.isDisabled = data.isDisabled ?? false
  }

  static create(data: { email: string }) {
    const user = new User(data)
    if (!user.email) throw Error('email is required')
    return user
  }
}
