import useAuth from '@app/composables/use-auth'
import { User } from '@core/domain/models/user.model'
import { Id } from '@core/domain/types/id.type'
import { HttpClient } from '@infrastructure/datasource/http-client'
import { useRouter } from 'vue-router'
import { Api } from './review-manager-api'

export class UsersRepository {
  constructor(
    private _httpClient: HttpClient,
    private _reviewManagerService: Api<any>,
    private _useAuth = useAuth(),
    private router = useRouter()
  ) {}
  async create(user: User, password?: string): Promise<User> {
    const { data } = await this._reviewManagerService.api.usersControllerCreate(
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        password: password!,
      }
    )
    return new User(data)
  }

  async find(): Promise<User[]> {
    const { data } = await this._reviewManagerService.api.usersControllerFind()
    return data.map((d: any) => new User(d))
  }

  async login(email: string, password: string): Promise<string> {
    const { data } = await this._reviewManagerService.api.usersControllerLogin({
      email,
      password,
    })

    return data
  }

  async findCurrentUser(): Promise<User> {
    const { data } =
      await this._reviewManagerService.api.usersControllerFindCurrentUser()
    return new User(data)
  }

  async updateCurrentUserFullName(fullName: {
    firstName: string
    lastName: string
  }): Promise<void> {
    try {
      await this._reviewManagerService.api.usersControllerUpdateCurrentUserFullName(
        {
          firstName: fullName.firstName,
          lastName: fullName.lastName,
        }
      )
    } catch (e) {
      await this.logout()
    }
  }

  async enableUser(userId: Id): Promise<User> {
    const { data } =
      await this._reviewManagerService.api.usersControllerEnableUser({
        userId: userId,
      })
    return new User(data as Partial<User>)
  }

  async disableUser(userId: Id): Promise<User> {
    const { data } =
      await this._reviewManagerService.api.usersControllerDisableUser({
        userId: userId,
      })
    return new User(data as Partial<User>)
  }

  setAuthHeader(token: string): void {
    this._httpClient.setToken(token)
  }

  async logout() {
    this._useAuth.logout()
    this.setAuthHeader('')
    this.router.push('/login')
  }
}
