<template>
  <div class="dark:text-white h-full flex flex-col">
    <Tabs
      :steps="[
        { number: 1, title: 'Members' },
        { number: 2, title: 'Settings' },
      ]"
      :model-value="1"
      :show-step-number="false"
    >
      <template #step1>
        <ReviewsRoleAssignment
          :project="project"
          v-if="devToolbox.isCuttingEdge"
        />
        <LegacyMembersAssignment :project="project" v-else />
      </template>
      <template #step2>
        <ProjectSettings :project="project" />
      </template>
    </Tabs>
  </div>
</template>
<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs.vue'
import { Project } from '@core/domain/models/project.model'
import ReviewsRoleAssignment from './ReviewsRoleAssignment.vue'

import ProjectSettings from './ProjectSettings/ProjectSettings.vue'
import useDevToolbox from '@app/composables/use-dev-toolbox'
import LegacyMembersAssignment from './ProjectMembers/LegacyMembersAssignment.vue'

const props = defineProps<{
  project: Project
}>()
const devToolbox = useDevToolbox()
</script>
