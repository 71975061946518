import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/index.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/animations/shift-away.css'
import 'prosemirror-view/style/prosemirror.css'
import { ReviewsService } from '@core/application/reviews.service'
import { router } from './router'
import { HttpClient } from '@infrastructure/datasource/http-client'
import { ProjectsRepository } from '@infrastructure/datasource/projects.repository'
import { ProjectsService } from '@core/application/projects.service'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { UsersService } from '@core/application/users.service'
import { UsersRepository } from '@infrastructure/datasource/users.repository'
import useAuth from './composables/use-auth'
import 'v-calendar/dist/style.css'
import * as Sentry from '@sentry/vue'
import { Api } from '@infrastructure/datasource/review-manager-api'
import { useSettings } from './composables/use-settings'
import { CslStylesService } from '@core/application/csl-styles.service'
import { CslStyleApi } from '@infrastructure/csl-styles.api'
import useCslStyles from './composables/use-cslStyles'
import { ImportSourcesService } from '@core/application/import-sources.service'
import { ImportSourcesRepository } from '@infrastructure/datasource/import-sources.repository'
import { AppraisalCriteriaRepository } from '@infrastructure/datasource/appraisal-criteria.repository'
import { AppraisalCriteriaService } from '@core/application/appraisal-criteria.service'
import 'virtual:vite-plugin-sentry/sentry-config'
import tippy, { Instance } from 'tippy.js'
import { ApiInterceptors } from '@infrastructure/datasource/api-interceptors'

async function bootstrap() {
  const httpClient = new HttpClient()
  const reviewManagerService = new Api({
    baseURL: (import.meta.env.VITE_API_URL as string) ?? '',
  })
  const projectsRepository = new ProjectsRepository(
    httpClient,
    reviewManagerService
  )

  const usersRepository = new UsersRepository(httpClient, reviewManagerService)
  const reviewsRepository = new ReviewsRepository(reviewManagerService)
  const importSourcesRepository = new ImportSourcesRepository(
    reviewManagerService
  )
  const appraisalCriteriaRepository = new AppraisalCriteriaRepository(
    reviewManagerService
  )
  const projectsService = new ProjectsService(projectsRepository)
  const cslStyleApi = new CslStyleApi(reviewManagerService)
  const cslStylesService = new CslStylesService(cslStyleApi)
  const reviewsService = new ReviewsService(reviewsRepository)
  const usersService = new UsersService(usersRepository, reviewManagerService)
  const importSourcesService = new ImportSourcesService(importSourcesRepository)
  const appraisalCriteriaService = new AppraisalCriteriaService(
    appraisalCriteriaRepository
  )
  const apiInetrceptors = new ApiInterceptors(
    httpClient,
    reviewManagerService,
    usersService
  )
  apiInetrceptors.registerInterceptors()
  const cslStyles = useCslStyles()
  const settings = useSettings()

  const token = await usersService.getAuthToken()
  if (token) {
    const auth = useAuth()
    usersService.setAuthHeader(token)
    reviewManagerService.instance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`
    const user = await usersService.findCurrentUser().catch(() => null)
    if (user) {
      auth.setToken(token)
      auth.setUser(user)
    }
  }
  await settings.init()
  const cslStylesList = await cslStylesService.find().catch(() => [])
  cslStyles.set(cslStylesList)
  const app = createApp(App)
  app.provide('reviewsService', reviewsService)
  app.provide('projectsService', projectsService)
  app.provide('usersService', usersService)
  app.provide('cslStylesService', cslStylesService)
  app.provide('importSourcesService', importSourcesService)
  app.provide('appraisalCriteriaService', appraisalCriteriaService)

  app.directive('tooltip', {
    created: (el, { value, modifiers }) => {
      let placement: 'right' | 'left' | 'top' | 'bottom' | undefined
      if (modifiers.right) {
        placement = 'right'
      }
      if (modifiers.left) {
        placement = 'left'
      }
      if (modifiers.top) {
        placement = 'top'
      }
      if (modifiers.bottom) {
        placement = 'bottom'
      }
      el.$tippy = tippy(el, {
        content: value,
        placement,
        zIndex: 99999999999999,
      })
      if (!value) {
        ;(el.$tippy as Instance).disable()
      }
    },
    updated: (el, { value }) => {
      ;(el.$tippy as Instance).setContent(value)
      if (!value) {
        ;(el.$tippy as Instance).disable()
      } else {
        ;(el.$tippy as Instance).enable()
      }
    },
    beforeUnmount(el) {
      ;(el.$tippy as Instance).destroy()
    },
  })

  const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist
  const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release

  Sentry.init({
    app,
    dsn: (import.meta.env.VITE_SENTRY_DSN as string) ?? '',
    environment:
      (import.meta.env.VITE_SENTRY_ENVIRONMENT as string) ?? 'develop',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          (import.meta.env.VITE_SENTRY_TRACING_ORIGIN as string) ?? '',
          /^\//,
        ],
      }),
    ],
    logErrors: true,
    tracesSampleRate: 1.0,
    dist,
    release,
  })

  app.use(router)

  app.mount('#app')
}

bootstrap()
