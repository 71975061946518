import { compareAsc, format, isValid, parse } from 'date-fns'
import { ref } from 'vue'

export default () => {
  const log = [
    { date: new Date('2023-04-12'), entries: ['added dark mode'] },
    {
      date: new Date('2023-04-13'),
      entries: [
        'pdf viewer: added wheel zoom support using ctrl + wheel',
        'pdf viewer: added panning by holding the middle mouse button',
      ],
    },
    {
      date: new Date('2023-04-14'),
      entries: ['new feature: global pdf viewer'],
    },
    {
      date: new Date('2023-07-12'),
      entries: [
        'Updated citation file import: the same citation file can be imported again after user acknowledgment',
      ],
    },
  ].sort((a, b) => Number(b.date) - Number(a.date))

  const shouldNotifyAboutUpdate =
    localStorage.getItem('shouldNotifyAboutUpdate') === 'false' ? false : true

  let lastestKnownUpdateDateString =
    localStorage.getItem('lastestKnownUpdate') ?? ''

  let isValidLatestKnowUpdate = isValid(
    parse(lastestKnownUpdateDateString, 'yyyy-MM-dd', new Date())
  )
  let latestKnownUpdate: Date

  const latestUpdate = log[0].date
  if (isValidLatestKnowUpdate) {
    latestKnownUpdate = new Date(lastestKnownUpdateDateString)
  } else {
    localStorage.setItem(
      'lastestKnownUpdate',
      format(latestUpdate, 'yyyy-MM-dd')
    )
    latestKnownUpdate = latestUpdate
  }

  let isNewVersionAvailable = ref(
    compareAsc(latestUpdate, latestKnownUpdate) === 1 && shouldNotifyAboutUpdate
  )

  function acknowledgeUpdate() {
    localStorage.setItem(
      'lastestKnownUpdate',
      format(latestUpdate, 'yyyy-MM-dd')
    )
    isNewVersionAvailable.value = false
  }

  function disableUpdatesNotification() {
    localStorage.setItem('shouldNotifyAboutUpdate', 'false')
    acknowledgeUpdate()
  }
  return {
    log,
    isNewVersionAvailable,
    acknowledgeUpdate,
    disableUpdatesNotification,
  }
}
