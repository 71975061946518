import { ImportSource } from '@core/domain/models/import-source.model'
import { CitationFileFormat } from '@core/domain/types/import-file-format.type'
import { ImportSourceId } from '@core/domain/types/import-source-id.type'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ImportSourcesRepository } from '@infrastructure/datasource/import-sources.repository'

export class ImportSourcesService {
  constructor(private importSourcesRepository: ImportSourcesRepository) {}
  async create(data: {
    type: ImportSourceType
    shortName: string
    fullName: string
    description: string
    url: string
    format: CitationFileFormat
  }): Promise<ImportSource> {
    return this.importSourcesRepository.create(data)
  }

  async find(): Promise<ImportSource[]> {
    return this.importSourcesRepository.find()
  }

  async update(
    importSourceId: ImportSourceId,
    data: {
      shortName?: string
      fullName?: string
      description?: string
      url?: string
    }
  ): Promise<void> {
    return this.importSourcesRepository.update(importSourceId, data)
  }
}
