type responseData = {
  error: string
  message: string
  statusCode: number
  alreadyImportedFiles?: any
}

export class HttpException {
  statusCode: number
  message: string
  response: { data: responseData }
  constructor(
    statusCode: number,
    message: string,
    response: { data: responseData }
  ) {
    this.response = response
    this.statusCode = statusCode
    this.message = message
  }
}
